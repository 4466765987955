import React, { useEffect, useState } from 'react';
import { Icons } from '../..';
import { generalOptions, listingTypes, pitchCategoryOptions } from '../../../util/fieldsOptions';
import { useMyContext } from '../../../context/StateHolder';
import { useMyContextFunctions } from '../../../context/ContextFunctions';
import classNames from 'classnames';
import { useIntl } from '../../../util/reactIntl';

import css from '../Topbar.module.css';

function RenderExtraFilters(props) {
  const { isMobileLayout } = props;
  const intl = useIntl();

  const priceRanges = [
    {
      key: '0,10',
      value: intl.formatMessage({ id: 'RenderExtraFilters.upto10' }),
    },
    {
      key: '0,20',
      value: intl.formatMessage({ id: 'RenderExtraFilters.upto20' }),
    },
    {
      key: '0,30',
      value: intl.formatMessage({ id: 'RenderExtraFilters.upto30' }),
    },
    {
      key: '0,40',
      value: intl.formatMessage({ id: 'RenderExtraFilters.upto40' }),
    },
    {
      key: '0,50',
      value: intl.formatMessage({ id: 'RenderExtraFilters.upto50' }),
    },
    {
      key: '50,100000',
      value: intl.formatMessage({ id: 'RenderExtraFilters.50andOver' }),
    },
  ];
  const isWindowDefined = typeof window !== 'undefined';

  const [showPitchCategory, setPitchCategory] = useState(!isMobileLayout);
  const [showExtras, setExtras] = useState(!isMobileLayout);
  const [showGeneral, setGeneral] = useState(!isMobileLayout);
  const [selectedPriceRange, setSelectedPriceRange] = useState(!isMobileLayout);

  const { globalFilterParams } = useMyContext();
  const { onSetGlobalFilterParams } = useMyContextFunctions();

  const pitchCategory = globalFilterParams?.pitchCategory || [];
  const general = globalFilterParams?.general || [];
  const extras = globalFilterParams?.extras || [];
  const prices = globalFilterParams?.prices || [];

  const [filterOptions, setFilterOptions] = useState({ pitchCategory, general, extras });

  useEffect(() => {
    onSetGlobalFilterParams(filterOptions);
  }, [filterOptions]);

  const handleCheckboxChange = (event, optionKey) => {
    const { checked, name } = event.target;

    setFilterOptions(prevState => {
      const updatedOptions = prevState[optionKey] ? [...prevState[optionKey]] : [];
      if (checked) {
        updatedOptions.push(name);
      } else {
        const index = updatedOptions.indexOf(name);
        if (index > -1) {
          updatedOptions.splice(index, 1);
        }
      }
      return { ...prevState, [optionKey]: updatedOptions };
    });
  };

  const handlePriceRangeSelect = index => {
    onSetGlobalFilterParams({ prices: priceRanges[index] });
  };

  return (
    <div className={css.customFilters}>
      {/* Price Range Block */}
      <div className={css.filterBlock}>
        <h4>
          {isMobileLayout && (
            <span
              className={css.toggleIcon}
              onClick={() => setSelectedPriceRange(!selectedPriceRange)}
            >
              <Icons name="plus" />
            </span>
          )}
          {intl.formatMessage({ id: 'RenderExtraFilters.price' })}
        </h4>
        {selectedPriceRange ? (
          <div className={css.priceRange}>
            {priceRanges.map((range, i) => {
              const selectedIndex = priceRanges.findIndex(p => p.key === prices?.key);
              const isLastIndex = i === priceRanges.length - 1;
              const isLastIndexSelected = selectedIndex === priceRanges.length - 1;

              return (
                <span
                  key={i}
                  className={classNames(css.pills, {
                    [css.pillSelected]: isLastIndex
                      ? isLastIndexSelected
                      : selectedIndex >= i && !isLastIndexSelected,
                  })}
                  onClick={() => handlePriceRangeSelect(i)}
                >
                  {range?.value}
                </span>
              );
            })}
          </div>
        ) : null}
      </div>

      {/* Listing Types Block */}
      <div className={css.filterBlock}>
        <h4>
          {isMobileLayout && (
            <span className={css.toggleIcon} onClick={() => setPitchCategory(!showPitchCategory)}>
              <Icons name="plus" />
            </span>
          )}
          {intl.formatMessage({ id: 'RenderExtraFilters.listingTypes' })}
        </h4>
        {showPitchCategory && (
          <div className={css.options}>
            {listingTypes.map(v => (
              <div className={css.filterOption} key={v.key}>
                <input
                  type="checkbox"
                  name={v.key}
                  checked={filterOptions.pitchCategory?.includes(v.key)}
                  onChange={e => handleCheckboxChange(e, 'pitchCategory')}
                />
                <label htmlFor={v.key}>{v.label}</label>
              </div>
            ))}
          </div>
        )}
      </div>
      {/* General Block */}
      <div className={css.filterBlock}>
        <h4>
          {isMobileLayout && (
            <span className={css.toggleIcon} onClick={() => setGeneral(!showGeneral)}>
              <Icons name="plus" />
            </span>
          )}
          {intl.formatMessage({ id: 'RenderExtraFilters.general' })}
        </h4>
        {showGeneral && (
          <div className={css.options}>
            {generalOptions.slice(1).map(v => (
              <div className={css.filterOption} key={v.key}>
                <input
                  type="checkbox"
                  name={v.key}
                  checked={filterOptions.general?.includes(v.key)}
                  onChange={e => handleCheckboxChange(e, 'general')}
                />
                <label htmlFor={v.key}>{v.label}</label>
              </div>
            ))}
          </div>
        )}
      </div>

      {/* Extras Block */}
      <div className={css.filterBlock}>
        <h4>
          {isMobileLayout && (
            <span className={css.toggleIcon} onClick={() => setExtras(!showExtras)}>
              <Icons name="plus" />
            </span>
          )}
          {intl.formatMessage({ id: 'RenderExtraFilters.extras' })}
        </h4>
        {showExtras && (
          <div className={css.options}>
            <div className={css.filterOption} key="infoOnly">
              <input
                type="checkbox"
                name="infoOnly"
                checked={filterOptions.extras?.includes('infoOnly')}
                onChange={e => handleCheckboxChange(e, 'extras')}
              />
              <label htmlFor="infoOnly">
                {intl.formatMessage({ id: 'RenderExtraFilters.infoOnly' })}
              </label>
            </div>
          </div>
        )}
      </div>
    </div>
  );
}

export default RenderExtraFilters;
