import React, { useEffect, useState } from 'react';
import pickBy from 'lodash/pickBy';
import classNames from 'classnames';
import appSettings from '../../config/settings';
import { useConfiguration } from '../../context/configurationContext';
import { useRouteConfiguration } from '../../context/routeConfigurationContext';
import { FormattedMessage, useIntl } from '../../util/reactIntl';
import { isMainSearchTypeKeywords, isOriginInUse } from '../../util/search';
import { parse, stringify } from '../../util/urlHelpers';
import { createResourceLocatorString, pathByRouteName } from '../../util/routes';
import {
  Button,
  LimitedAccessBanner,
  LinkedLogo,
  Modal,
  ModalMissingInformation,
} from '../../components';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';

import MenuIcon from './MenuIcon';
import TopbarSearchForm from './TopbarSearchForm/TopbarSearchForm';
import TopbarMobileMenu from './TopbarMobileMenu/TopbarMobileMenu';
import TopbarDesktop from './TopbarDesktop/TopbarDesktop';
import TopbarFilters from './TopbarFilters/TopbarFilters';

import css from './Topbar.module.css';
import { useMyContext } from '../../context/StateHolder';
import { useMyContextFunctions } from '../../context/ContextFunctions';
import { isGuestUser } from '../../util/genericHelpers';

const MAX_MOBILE_SCREEN_WIDTH = 1024;

const redirectToURLWithModalState = (props, modalStateParam) => {
  const { history, location } = props;
  const { pathname, search, state } = location;
  const searchString = `?${stringify({ [modalStateParam]: 'open', ...parse(search) })}`;
  history.push(`${pathname}${searchString}`, state);
};

const redirectToURLWithoutModalState = (props, modalStateParam) => {
  const { history, location } = props;
  const { pathname, search, state } = location;
  const queryParams = pickBy(parse(search), (v, k) => {
    return k !== modalStateParam;
  });
  const stringified = stringify(queryParams);
  const searchString = stringified ? `?${stringified}` : '';
  history.push(`${pathname}${searchString}`, state);
};

const GenericError = props => {
  const { show } = props;
  const classes = classNames(css.genericError, {
    [css.genericErrorVisible]: show,
  });
  return (
    <div className={classes}>
      <div className={css.genericErrorContent}>
        <p className={css.genericErrorText}>
          <FormattedMessage id="Topbar.genericError" />
        </p>
      </div>
    </div>
  );
};

function Topbar(props) {
  const { showTopbarFilter } = useMyContext();
  const {
    className,
    rootClassName,
    desktopClassName,
    mobileRootClassName,
    mobileClassName,
    isAuthenticated,
    authScopes,
    authInProgress,
    currentUser,
    currentUserHasListings,
    currentUserHasOrders,
    currentPage,
    notificationCount,
    viewport,
    location,
    onManageDisableScrolling,
    onResendVerificationEmail,
    sendVerificationEmailInProgress,
    sendVerificationEmailError,
    showGenericError,
    seoPages,
    setFilterModalOpen,
    currentSearchParams,
    onLogout,
    hideSearchBar,
  } = props;

  const history = useHistory();
  const config = useConfiguration();
  const intl = useIntl();
  const routeConfiguration = useRouteConfiguration();
  const { onSetGlobalFilterModal } = useMyContextFunctions();
  const search = parse(location?.search);

  useEffect(() => {
    if (search?.mobilemenu === 'open') {
      onSetGlobalFilterModal(false, null);
    }
  }, [search]);

  const isGuest = isGuestUser(currentUser);

  const handleFilterModal = bool => {
    setFilterModalOpen(bool);
  };

  const handleMobileMenuOpen = () => {
    redirectToURLWithModalState(props, 'mobilemenu');
    onSetGlobalFilterModal(false, null);
  };

  const handleMobileMenuClose = () => {
    redirectToURLWithoutModalState(props, 'mobilemenu');
    onSetGlobalFilterModal(false, null);
  };

  const handleMobileSearchOpen = () => {
    redirectToURLWithModalState(props, 'mobilesearch');
  };

  const handleMobileSearchClose = () => {
    redirectToURLWithoutModalState(props, 'mobilesearch');
  };

  const handleSubmit = values => {
    const topbarSearchParams = () => {
      if (isMainSearchTypeKeywords(config)) {
        return { keywords: values?.keywords };
      }
      // topbar search defaults to 'location' search
      const { search, selectedPlace } = values?.location;
      const { origin, bounds } = selectedPlace;
      const originMaybe = isOriginInUse(config) ? { origin } : {};

      return {
        ...originMaybe,
        address: search,
        bounds,
      };
    };
    const searchParams = {
      ...currentSearchParams,
      ...topbarSearchParams(),
    };
    history.push(createResourceLocatorString('SearchPage', routeConfiguration, {}, searchParams));
  };

  const handleLogout = () => {
    onLogout().then(() => {
      const path = pathByRouteName('LandingPage', routeConfiguration);

      // In production we ensure that data is really lost,
      // but in development mode we use stored values for debugging
      if (appSettings.dev) {
        history.push(path);
      } else if (typeof window !== 'undefined') {
        window.location = path;
      }

      console.log('logged out'); // eslint-disable-line
    });
  };

  const { mobilemenu, mobilesearch, keywords, address, origin, bounds } = parse(location.search, {
    latlng: ['origin'],
    latlngBounds: ['bounds'],
  });

  const notificationDot = notificationCount > 0 ? <div className={css.notificationDot} /> : null;

  const isMobileLayout = viewport.width < MAX_MOBILE_SCREEN_WIDTH;
  const isMobileMenuOpen = isMobileLayout && mobilemenu === 'open';
  const isMobileSearchOpen = isMobileLayout && mobilesearch === 'open';

  const mobileMenu = (
    <TopbarMobileMenu
      isAuthenticated={isAuthenticated}
      currentUserHasListings={currentUserHasListings}
      currentUser={currentUser}
      seoPages={seoPages}
      onLogout={handleLogout}
      notificationCount={notificationCount}
      currentPage={currentPage}
      history={history}
      isGuest={isGuest}
    />
  );

  const topbarSearcInitialValues = () => {
    if (isMainSearchTypeKeywords(config)) {
      return { keywords };
    }

    // Only render current search if full place object is available in the URL params
    const locationFieldsPresent = isOriginInUse(config)
      ? address && origin && bounds
      : address && bounds;
    return {
      location: locationFieldsPresent
        ? {
            search: address,
            selectedPlace: { address, origin, bounds },
          }
        : null,
    };
  };

  const initialSearchFormValues = topbarSearcInitialValues();
  const classes = classNames(rootClassName || css.root, className);

  return (
    <div className={classes}>
      <LimitedAccessBanner
        isAuthenticated={isAuthenticated}
        authScopes={authScopes}
        currentUser={currentUser}
        onLogout={handleLogout}
        currentPage={currentPage}
      />
      {hideSearchBar ? null : (
        <div className={classNames(mobileRootClassName || css.container, mobileClassName)}>
          <div className={css.leftSec}>
            <Button
              rootClassName={css.menu}
              onClick={handleMobileMenuOpen}
              title={intl.formatMessage({ id: 'Topbar.menuIcon' })}
            >
              <MenuIcon className={css.menuIcon} />
              {notificationDot}
            </Button>
          </div>
          {showTopbarFilter ? (
            <div className={css.topbarSearch}>
              <TopbarSearchForm
                className={css.searchLink}
                desktopInputRoot={css.topbarSearchWithLeftPadding}
                onSubmit={handleSubmit}
                initialValues={initialSearchFormValues}
                appConfig={config}
              />
            </div>
          ) : null}
          {!showTopbarFilter ? (
            <LinkedLogo
              className={css.logo}
              layout={'mobile'}
              alt={intl.formatMessage({ id: 'Topbar.logoIcon' })}
            />
          ) : null}
        </div>
      )}
      <div className={css.desktop}>
        <TopbarDesktop
          className={desktopClassName}
          currentUserHasListings={currentUserHasListings}
          currentUser={currentUser}
          currentPage={currentPage}
          isGuest={isGuest}
          hideSearchBar={hideSearchBar}
          initialSearchFormValues={initialSearchFormValues}
          intl={intl}
          history={history}
          seoPages={seoPages}
          isAuthenticated={isAuthenticated}
          notificationCount={notificationCount}
          onLogout={handleLogout}
          onSearchSubmit={handleSubmit}
          onToggleFilterModal={handleFilterModal}
          appConfig={config}
        />
      </div>
      <Modal
        id="TopbarMobileMenu"
        containerClassName={css.modalContainer}
        isOpen={isMobileMenuOpen}
        onClose={handleMobileMenuClose}
        usePortal
        onManageDisableScrolling={onManageDisableScrolling}
      >
        {authInProgress ? null : mobileMenu}
      </Modal>
      <Modal
        id="TopbarMobileSearch"
        containerClassName={css.modalContainerSearchForm}
        isOpen={isMobileSearchOpen}
        onClose={handleMobileSearchClose}
        usePortal
        onManageDisableScrolling={onManageDisableScrolling}
      >
        <div className={css.searchContainer}>
          <TopbarSearchForm
            onSubmit={handleSubmit}
            initialValues={initialSearchFormValues}
            isMobile
            appConfig={config}
          />
          <p className={css.mobileHelp}>
            <FormattedMessage id="Topbar.mobileSearchHelp" />
          </p>
        </div>
      </Modal>
      <TopbarFilters
        isMobileLayout={isMobileLayout}
        intl={intl}
        onManageDisableScrolling={onManageDisableScrolling}
      />
      <ModalMissingInformation
        id="MissingInformationReminder"
        containerClassName={css.missingInformationModal}
        currentUser={currentUser}
        currentUserHasListings={currentUserHasListings}
        currentUserHasOrders={currentUserHasOrders}
        location={location}
        onManageDisableScrolling={onManageDisableScrolling}
        onResendVerificationEmail={onResendVerificationEmail}
        sendVerificationEmailInProgress={sendVerificationEmailInProgress}
        sendVerificationEmailError={sendVerificationEmailError}
      />

      <GenericError show={showGenericError} />
    </div>
  );
}

export default Topbar;
