import React, { useEffect, useState } from 'react';
import { arrayOf, bool, string } from 'prop-types';
import { compose } from 'redux';
import { connect, useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';

import { useConfiguration } from '../../context/configurationContext';
import { useRouteConfiguration } from '../../context/routeConfigurationContext';
import { useIntl, intlShape } from '../../util/reactIntl';
import { propTypes } from '../../util/types';
import { isMainSearchTypeKeywords, isOriginInUse } from '../../util/search';
import { isScrollingDisabled } from '../../ducks/ui.duck';

import { Page, LayoutSingleColumn, IconSpinner, IconRipple } from '../../components';

import TopbarContainer from '../TopbarContainer/TopbarContainer';
import FooterContainer from '../FooterContainer/FooterContainer';

import { useLocation, useParams } from 'react-router-dom/cjs/react-router-dom.min';

import css from './CategoryPage.module.css';
import { getListingsById } from '../../ducks/marketplaceData.duck';
import SectionHero from './SectionHero';
import RenderBreadcrumbs from './RenderBreadcrumbs';
import PopularCategory from './PopularCategory';
import PricingListing from './PricingListing';
import {
  getBoundsFromMapbox,
  getDefaultLocale,
  isArrayLength,
  parseFields,
} from '../../util/genericHelpers';

import seoJSON from '../../cache/seoData.json';
import RenderPopularSeoLinks from './RenderPopularSeoLinks';
import PetFriendly from './PetFriendly';
import WeekendAvailability from './WeekendAvailability';
import CategoryFaqSection from './CategoryFaqSection';
import OtherInformation from './OtherInformation';
import SearchMap from '../SearchPage/SearchMap/SearchMap';
import { parse, reverseSlug } from '../../util/urlHelpers';
import routeConfiguration from '../../routing/routeConfiguration';
import { createResourceLocatorString, pathByRouteName } from '../../util/routes';
import { validFilterParams } from '../SearchPage/SearchPage.shared';
import locationsData from './locationsDataDE.json';
import { withViewport } from '../../util/uiHelpers';
import { types as sdkTypes } from '../../util/sdkLoader';

const { LatLng, LatLngBounds } = sdkTypes;

const defaultLocale = getDefaultLocale();

export const locations = locationsData;

const findTitle = (location, params) => {
  const pathSegments = location?.pathname?.split('/');
  const length = pathSegments?.length;

  if (length === 2) {
    return { title: params?.country, isCountry: true };
  } else if (length === 3) {
    return { title: params?.state, isCountry: true, isState: true };
  }

  return { title: params?.city, isState: true, isCountry: true, isCity: true };
};

const CategoryPageComponent = ({ scrollingDisabled, listings, intl, config, viewport }) => {
  const params = useParams();
  const history = useHistory();
  const location = useLocation();

  const { title, isCountry, isState, isCity } = findTitle(location, params) || {};

  const currentLocation = locations.find(
    l => l.title.toLocaleLowerCase() === title.toLocaleLowerCase()
  );

  const [inProgress, setInProgress] = useState(true);
  const [locationBounds, setLocationBounds] = useState(null);

  useEffect(() => {
    const timer = setTimeout(() => {
      setInProgress(false);
    }, 600);

    return () => clearTimeout(timer);
  }, []);

  const onFetchBounds = async () => {
    const getBoundDetails = await getBoundsFromMapbox(
      currentLocation?.center?.lat,
      currentLocation?.center?.lon
    );

    setLocationBounds(getBoundDetails);
  };

  useEffect(() => {
    if (!currentLocation) {
      history.push(createResourceLocatorString('LandingPage', routeConfiguration(), {}, {}));
    }

    onFetchBounds();
  }, [currentLocation]);

  const seoPages = seoJSON[defaultLocale];

  const onMapMoveEnd = (viewportBoundsChanged, data) => {
    const { viewportBounds, viewportCenter } = data;

    const routes = routeConfiguration();
    const searchPagePath = pathByRouteName('SearchPage', routes);
    const currentPath =
      typeof window !== 'undefined' && window.location && window.location.pathname;

    // When using the ReusableMapContainer onMapMoveEnd can fire from other pages than SearchPage too
    const isSearchPage = currentPath === searchPagePath;

    // If mapSearch url param is given
    // or original location search is rendered once,
    // we start to react to "mapmoveend" events by generating new searches
    // (i.e. 'moveend' event in Mapbox and 'bounds_changed' in Google Maps)
    if (viewportBoundsChanged && isSearchPage) {
      const { listingFields: listingFieldsConfig } = config?.listing || {};
      const { defaultFilters: defaultFiltersConfig } = config?.search || {};

      // parse query parameters, including a custom attribute named category
      const { address, bounds, mapSearch, ...rest } = parse(location.search, {
        latlng: ['origin'],
        latlngBounds: ['bounds'],
      });

      const originMaybe = isOriginInUse(config) ? { origin: viewportCenter } : {};
      const dropNonFilterParams = false;

      const searchParams = {
        address,
        ...originMaybe,
        bounds: viewportBounds,
        mapSearch: true,
        ...validFilterParams(rest, listingFieldsConfig, defaultFiltersConfig, dropNonFilterParams),
      };

      history.push(createResourceLocatorString('CategoryPage', routes, {}, searchParams));
    }
  };

  const translations = currentLocation;

  const latlngBounds = locationBounds ? new LatLngBounds(
    new LatLng(locationBounds.sw.lat, locationBounds.sw.lng),
    new LatLng(locationBounds.ne.lat, locationBounds.ne.lng)
  ) : null;

  return (
    <Page scrollingDisabled={scrollingDisabled}>
      <LayoutSingleColumn
        className={css.root}
        topbar={<TopbarContainer isWhiteBgNav={false} isHeaderSticky={true} />}
        footer={<FooterContainer />}
      >
        {inProgress ? (
          <div className={css.spinner}>
            <IconRipple />
          </div>
        ) : (
          <div className={css.categoryPageWrapper}>
            <div className={css.contentWidth}>
              <RenderBreadcrumbs
                isCountry={isCountry}
                isState={isState}
                isCity={isCity}
                history={history}
                currentLocation={currentLocation}
              />
            </div>
            <SectionHero
              history={history}
              currentLocation={currentLocation}
              intl={intl}
              translations={translations}
            />
            <div className={css.contentWidth}>
              <PopularCategory
                listings={listings}
                history={history}
                currentLocation={currentLocation}
                intl={intl}
                viewport={viewport}
                locationBounds={locationBounds}
                renderMap={
                  <div className={css.categoryMapSection}>
                    {isArrayLength(listings) && latlngBounds ? (
                      <SearchMap
                        reusableContainerClassName={css.map}
                        activeListingId={null}
                        bounds={latlngBounds}
                        center={null}
                        isSearchMapOpenOnMobile={false}
                        location={location}
                        listings={listings || []}
                        onMapMoveEnd={onMapMoveEnd}
                        infoOnlyLabel={intl.formatMessage({
                          id: 'SearchPageWithMap.showBookableListings',
                        })}
                        onCloseAsModal={() => {}}
                        messages={intl.messages}
                        isSeoPage
                      />
                    ) : null}
                  </div>
                }
              />
              <PricingListing
                viewport={viewport}
                listings={listings}
                currentLocation={currentLocation}
              />
              <PetFriendly
                viewport={viewport}
                listings={listings}
                currentLocation={currentLocation}
              />
              <WeekendAvailability
                viewport={viewport}
                listings={listings}
                currentLocation={currentLocation}
              />
              <OtherInformation
                viewport={viewport}
                currentLocation={currentLocation}
                translations={translations}
              />
              <CategoryFaqSection
                viewport={viewport}
                currentLocation={currentLocation}
                translations={translations}
              />
            </div>
          </div>
        )}
      </LayoutSingleColumn>
    </Page>
  );
};

CategoryPageComponent.propTypes = {
  scrollingDisabled: bool.isRequired,
  marketplaceName: string.isRequired,
  isKeywordSearch: bool.isRequired,
  intl: intlShape.isRequired,
  routeConfiguration: arrayOf(propTypes.route).isRequired,
};

const EnhancedCategoryPage = props => {
  const routeConfiguration = useRouteConfiguration();
  const config = useConfiguration();
  const history = useHistory();
  const params = useParams();
  const intl = useIntl();

  return (
    <CategoryPageComponent
      routeConfiguration={routeConfiguration}
      marketplaceName={config.marketplaceName}
      isKeywordSearch={isMainSearchTypeKeywords(config)}
      history={history}
      params={params}
      config={config}
      intl={intl}
      {...props}
    />
  );
};

const mapStateToProps = state => {
  const { currentPageResultIds } = state.CategoryPage;
  const listings = getListingsById(state, currentPageResultIds);

  return {
    listings,
    scrollingDisabled: isScrollingDisabled(state),
  };
};

const CategoryPage = compose(connect(mapStateToProps), withViewport)(EnhancedCategoryPage);

export default CategoryPage;
