import React from 'react';
import { useMyContext } from '../../../context/StateHolder';
import Button from '../../Button/Button';
import { formatStrToQueryStr, isArrayLength, removeDuplicates } from '../../../util/genericHelpers';
import { stringifyDateToISO8601 } from '../../../util/dates';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import { searchTitle } from '../../../routing/routeConfiguration';
import { useMyContextFunctions } from '../../../context/ContextFunctions';
import { useIntl } from '../../../util/reactIntl';

import css from '../Topbar.module.css';

function MakeFilterQuery(props) {
  const { onCloseModal } = props;

  const history = useHistory();
  const intl = useIntl();
  const { globalFilterParams } = useMyContext();
  const { onResetGlobalFilter } = useMyContextFunctions();

  const locationPrediction = globalFilterParams?.locationPrediction;

  const placeName = locationPrediction?.place_name;
  const bbox = locationPrediction?.bbox;

  // Assuming the bbox array format is [minX, minY, maxX, maxY]
  const bounds = isArrayLength(bbox) && `${bbox[3]}%2C${bbox[2]}%2C${bbox[1]}%2C${bbox[0]}`;

  const urlString = placeName
    ? `${encodeURIComponent(placeName)}&bounds=${bounds}`
    : `Europe&bounds=62.33995248%2C19.17157943%2C25.98756225%2C-11.07927511&pub_isInfoOnly=false&`;
  const formatValue = dates => {
    const { startDate, endDate } = dates || {};
    const start = startDate && stringifyDateToISO8601(startDate);
    const end = endDate && stringifyDateToISO8601(endDate);
    return start && end ? `${start},${end}` : null;
  };

  // Function to construct the filter query
  const constructFilterQuery = () => {
    let query = '';

    if (isArrayLength(globalFilterParams?.travelTypes)) {
      query += `pub_vehicleTypes=${formatStrToQueryStr(globalFilterParams?.travelTypes, true)}&`;
    }
    if (isArrayLength(globalFilterParams?.vehicleHeight)) {
      query += `pub_vehicleHeight=${formatStrToQueryStr(globalFilterParams?.vehicleHeight)}&`;
    }
    if (isArrayLength(globalFilterParams?.vehicleLength)) {
      query += `pub_vehicleLength=${formatStrToQueryStr(globalFilterParams?.vehicleLength)}&`;
    }
    if (isArrayLength(globalFilterParams?.pitchCategory)) {
      query += `pub_category=${formatStrToQueryStr(globalFilterParams?.pitchCategory)}&`;
    }
    if (isArrayLength(globalFilterParams?.general)) {
      query += `pub_general=${formatStrToQueryStr(globalFilterParams?.general, true)}&`;
    }
    // if (isArrayLength(globalFilterParams?.extras)) {
    //   query += `pub_extras=${formatStrToQueryStr(globalFilterParams?.extras, true)}&`;
    // }
    if (globalFilterParams?.animalsOnSite) {
      query += `pub_animalsOnSite=${formatStrToQueryStr(globalFilterParams?.animalsOnSite, true)}&`;
    }
    if (globalFilterParams?.activities) {
      query += `pub_activities=${formatStrToQueryStr(globalFilterParams?.activities, true)}&`;
    }
    if (globalFilterParams?.dates) {
      query += `dates=${formatValue(globalFilterParams?.dates)}&`;
    }
    if (globalFilterParams?.prices?.key) {
      query += `price=${globalFilterParams?.prices?.key}&`;
    }
    if (globalFilterParams?.locationPrediction) {
      query += `address=${urlString}&`;
    }
    if (isArrayLength(globalFilterParams?.extras)) {
      query += `pub_isInfoOnly=false&`;
    }
    if (!globalFilterParams?.locationPrediction) {
      query += urlString;
    }

    if (typeof window !== 'undefined') {
      window.location.href = `/${searchTitle}?${query?.slice(0, -1)}`;
    }
  };

  // Call constructFilterQuery when the apply button is clicked
  const handleApplyClick = () => {
    constructFilterQuery();
    onCloseModal();
  };

  return (
    <div className={css.filterSubmit}>
      <Button type="button" className={css.applyBtn} onClick={handleApplyClick}>
        {intl.formatMessage({ id: 'MakeFilterQuery.applyFilter' })}
      </Button>
      <Button
        type="button"
        onClick={() => onResetGlobalFilter(`/${searchTitle}?address=${urlString}&`)}
        className={css.resetBtn}
      >
        {intl.formatMessage({ id: 'MakeFilterQuery.resetAllFilters' })}
      </Button>
    </div>
  );
}

export default MakeFilterQuery;
