import { getMarketplaceId, isArrayLength } from '../util/genericHelpers';
import {
  sendSendgridEmail,
  subscribeToSendgridNewsletter,
  claimListing,
  upsertContactToList,
} from '../util/api';
import { EmailTemplateId } from '../util/enums';
import { sendVerificationEmailError } from './user.duck';

export const QUERY_LISTINGS_BY_KEYWORD_IN_PROGRESS =
  'app/global/QUERY_LISTINGS_BY_KEYWORD_IN_PROGRESS';
export const QUERY_LISTINGS_BY_KEYWORD = 'app/global/QUERY_LISTINGS_BY_KEYWORD';

export const SEND_NEWSLETTER_REQUEST = 'app/global/SEND_NEWSLETTER_REQUEST';
export const SEND_NEWSLETTER_SUCCESS = 'app/global/SEND_NEWSLETTER_SUCCESS';
export const SEND_NEWSLETTER_ERROR = 'app/global/SEND_NEWSLETTER_ERROR';

export const SENDGRID_EMAIL_REQUEST = 'app/global/SENDGRID_EMAIL_REQUEST';
export const SENDGRID_EMAIL_SUCCESS = 'app/global/SENDGRID_EMAIL_SUCCESS';
export const SENDGRID_EMAIL_ERROR = 'app/global/SENDGRID_EMAIL_ERROR';

export const CLEAR_KEYWORD_SUCCESS = 'app/global/CLEAR_KEYWORD_SUCCESS';
export const CLAIM_LISTING_REQUEST = 'app/global/CLAIM_LISTING_REQUEST';
export const CLAIM_LISTING_SUCCESS = 'app/global/CLAIM_LISTING_SUCCESS';
export const CLAIM_LISTING_ERROR = 'app/global/CLAIM_LISTING_ERROR';

// ================ Reducer ================ //

const initialState = {
  sendNewsletterInProgress: false,
  sendNewsletterError: null,
  queryListingsInProgress: false,
  queryListingSuccess: false,
  listingFilterKeywords: [],
  claimListingInProgress: false,
  claimListingSuccess: null,
  claimListingError: null,
};

export default function reducer(state = initialState, action = {}) {
  const { type, payload } = action;
  switch (type) {
    case QUERY_LISTINGS_BY_KEYWORD_IN_PROGRESS:
      return {
        ...state,
        queryListingsInProgress: true,
        queryListingSuccess: false,
        listingFilterKeywords: [],
      };
    case QUERY_LISTINGS_BY_KEYWORD:
      return {
        ...state,
        listingFilterKeywords: payload,
        queryListingSuccess: true,
        queryListingsInProgress: false,
      };
    case CLEAR_KEYWORD_SUCCESS:
      return { ...state, queryListingSuccess: false };
    case SEND_NEWSLETTER_REQUEST:
      return {
        ...state,
        sendNewsletterInProgress: true,
        sendNewsletterError: null,
      };
    case SEND_NEWSLETTER_SUCCESS:
      return { ...state, sendNewsletterInProgress: false };
    case SEND_NEWSLETTER_ERROR:
      return { ...state, sendNewsletterInProgress: false, sendNewsletterError: payload };
    case SENDGRID_EMAIL_REQUEST:
      return {
        ...state,
        sendgridEmailInProgress: true,
        sendgridEmailError: null,
      };
    case SENDGRID_EMAIL_SUCCESS:
      return {
        ...state,
        sendgridEmailInProgress: false,
        sendgridEmailError: null,
        sendgridResponse: payload,
      };
    case SENDGRID_EMAIL_ERROR:
      return {
        ...state,
        sendgridEmailInProgress: true,
        sendgridEmailError: payload,
      };
    case CLAIM_LISTING_REQUEST:
      return {
        ...state,
        claimListingInProgress: true,
        claimListingError: null,
      };
    case CLAIM_LISTING_SUCCESS:
      return {
        ...state,
        claimListingInProgress: false,
        claimListingError: null,
        claimListingSuccess: true,
      };
    case CLAIM_LISTING_ERROR:
      return {
        ...state,
        claimListingInProgress: true,
        claimListingError: payload,
      };
    default:
      return state;
  }
}

// ================ Selectors ================ //

export const renderLocationFilterSelectors = state => {
  return {
    listingsData: state.global.listingFilterKeywords,
    fetchListingsInProgress: state.global.queryListingsInProgress,
    queryListingSuccess: state.global.queryListingSuccess,
  };
};

export const newsletterSelectors = state => {
  return {
    inProgress: state.global.sendNewsletterInProgress,
    error: state.global.sendNewsletterError,
  };
};

export const claimListingSelectors = state => {
  return {
    claimListingInProgress: state.global.claimListingInProgress,
    claimListingSuccess: state.global.claimListingSuccess,
    claimListingError: state.global.claimListingError,
  };
};

// ================ Action creators ================ //

export const clearKeywordSuccess = () => ({ type: CLEAR_KEYWORD_SUCCESS });
export const queryListingsInProgress = () => ({ type: QUERY_LISTINGS_BY_KEYWORD_IN_PROGRESS });

export const claimListingRequest = () => ({
  type: CLAIM_LISTING_REQUEST,
});

export const claimListingSuccess = () => ({
  type: CLAIM_LISTING_SUCCESS,
});

export const claimListingError = error => ({
  type: CLAIM_LISTING_ERROR,
  payload: error,
  error: true,
});

export const queryListingsByKeywordSuccess = payload => ({
  type: QUERY_LISTINGS_BY_KEYWORD,
  payload,
});

export const sendNewsletterRequest = () => ({ type: SEND_NEWSLETTER_REQUEST });
export const sendNewsletterSuccess = () => ({ type: SEND_NEWSLETTER_SUCCESS });
export const sendNewsletterError = error => ({
  type: SEND_NEWSLETTER_ERROR,
  payload: error,
  error: true,
});

export const sendgridEmailRequest = () => ({
  type: SENDGRID_EMAIL_REQUEST,
});

export const sendgridEmailSuccess = response => ({
  type: SENDGRID_EMAIL_SUCCESS,
  payload: response,
});

export const sendgridEmailError = e => ({
  type: SENDGRID_EMAIL_ERROR,
  error: true,
  payload: e,
});

// ================ Thunks ================ //

export const queryListingsByKeyword = keywords => async (dispatch, getState, sdk) => {
  dispatch(queryListingsInProgress());

  try {
    const response = keywords?.length && (await sdk.listings.query({ keywords }));
    const listings = response?.data?.data;
    const listingsData = isArrayLength(listings)
      ? listings.map(l => ({ id: l?.id?.uuid, title: l?.attributes?.title }))
      : [];

    dispatch(queryListingsByKeywordSuccess(listingsData));

    return response;
  } catch (error) {
    console.log(error, 'error');
    dispatch(queryListingsByKeywordSuccess([]));
  }
};

export const subscribeToNewsletter = params => async (dispatch, getState, sdk) => {
  const defaultLocale =
    (typeof window !== 'undefined' && localStorage.getItem('selectedLanguage')) || 'de';
  dispatch(sendNewsletterRequest());
  try {
    const subscription = await subscribeToSendgridNewsletter({ ...params, defaultLocale });
    dispatch(sendNewsletterSuccess());
    return subscription;
  } catch (error) {
    dispatch(sendNewsletterSuccess());
  }
};

export const sendEmail = params => {
  return async (dispatch, getState, sdk) => {
    const { addToFreshworks = false } = params;
    dispatch(sendgridEmailRequest());
    try {
      const response = await sendSendgridEmail({ ...params });
      if (addToFreshworks) {
        upsertContactToList({ ...params });
      }
      dispatch(sendgridEmailSuccess(response));
    } catch (error) {
      dispatch(sendVerificationEmailError(storableError(error)));
    }
  };
};

export const claimInfoListing = params => {
  return async (dispatch, getState, sdk) => {
    dispatch(claimListingRequest());

    try {
      const response = await claimListing({ ...params });
      const listingId = response?.listingId;
      await dispatch(
        sendEmail({
          templateId: EmailTemplateId.NEW_CLAIM_REQUEST_APPROVAL,
          recipients: ['vishal@yopmail.com'],
          dynamicData: {
            listingUrl: `https://console.sharetribe.com/o/youandaview/m/${getMarketplaceId()}/listings?id=${listingId}`,
          },
        })
      );
      dispatch(claimListingSuccess());
      return response;
    } catch (error) {
      dispatch(claimListingError(error));
    }
  };
};
