import React, { memo, useEffect, useRef, useState } from 'react';
import moment from 'moment';
import { useMyContext } from '../../context/StateHolder';
import { isArrayLength } from '../../util/genericHelpers';
import { vehicleTypesOptions } from '../../util/fieldsOptions';
import { useLocation } from 'react-router-dom/cjs/react-router-dom.min';
import classNames from 'classnames';
import { Form as FinalForm } from 'react-final-form';
import { Form } from '..';
import { compose } from 'redux';
import { injectIntl } from 'react-intl';
import { EventBus } from '../../util/eventHelpers';
import { useDispatch } from 'react-redux';
import { useIntl } from '../../util/reactIntl';
import { queryListingsByKeyword } from '../../ducks/global.duck';
import IconSearchDesktop from '../Topbar/TopbarSearchForm/IconSearchDesktop';

import css from './LocationAutocompleteInput.module.css';

const RenderLocationInputWithQueriesComponent = memo(
  props => {
    const { placeholder } = props;
    const { globalFilterParams, openGlobalFilterModal } = useMyContext();
    const [shouldUpdateInput, setShouldUpdateInput] = useState(true);
    const intl = useIntl();

    const inputRef = useRef(null);
    const dispatch = useDispatch(null);
    const isHandlingEventRef = false;

    useEffect(() => {
      function onFocused(data) {
        // Check if we are already handling the event
        if (isHandlingEventRef.current) {
          return; // If yes, exit early
        }
        isHandlingEventRef.current = true; // Mark as handling

        try {
          const showCursor = data?.showCursor;
          if (inputRef.current && showCursor) {
            const currentInput = inputRef.current;
            currentInput.setSelectionRange(0, 1);
            currentInput.focus();
          }
        } finally {
          isHandlingEventRef.current = false; // Reset the flag when done
        }
      }

      EventBus.subscribe('focusInput', onFocused);

      return () => {
        EventBus.unsubscribe('focusInput', onFocused);
      };
    }, []);

    const globalFilterParamsDates = globalFilterParams?.dates;
    const selectedLocation = globalFilterParams?.locationPrediction?.place_name;
    const showDateLabel =
      globalFilterParamsDates?.endDate &&
      `${moment(globalFilterParamsDates?.startDate).format('Do MMM')} - ${moment(
        globalFilterParamsDates?.endDate
      ).format('Do MMM')}`;

    const hasTravelTypes =
      isArrayLength(globalFilterParams?.travelTypes) && globalFilterParams?.travelTypes[0];
    const selectedTravelType =
      hasTravelTypes && vehicleTypesOptions.find(v => v.key === hasTravelTypes);

    const location = useLocation();
    const isLandingPage = location.pathname === '/';

    const handleFocus = () => {
      if (inputRef.current) {
        inputRef.current.focus(); // Try to focus directly
      }
    };

    useEffect(() => {
      const timeout = setTimeout(() => {
        if (inputRef?.current?.value) {
          dispatch(queryListingsByKeyword(inputRef?.current?.value));
        }
      }, 1500);

      return () => clearTimeout(timeout);
    }, [inputRef?.current?.value]);

    return (
      <FinalForm
        {...props}
        render={fieldRenderProps => {
          const {
            onFocus,
            onBlur,
            onChange,
            onKeyDown,
            form,
            values,
            rootClass,
          } = fieldRenderProps;

          useEffect(() => {
            if (selectedLocation && shouldUpdateInput) {
              inputRef.current.value = selectedLocation;
              setShouldUpdateInput(false); // Set the flag to false after updating
            }
          }, [selectedLocation, shouldUpdateInput]);

          // Whenever selectedLocation changes again, we'll set the flag to true to allow updating
          useEffect(() => {
            setShouldUpdateInput(true);
          }, [selectedLocation]);

          return (
            <Form className={css.locationForm} onSubmit={e => e.preventDefault()}>
              <span className={css.mobileIcon}>
                <IconSearchDesktop />
              </span>
              <div className={css.headerSearch}>
                <input
                  ref={inputRef}
                  className={rootClass}
                  type="text"
                  id="search"
                  name="location"
                  label="search"
                  autoComplete="off"
                  onBlur={onBlur}
                  placeholder={openGlobalFilterModal ? '' : placeholder}
                  onChange={e => {
                    onChange(e);
                    form.change('location', e.target.value);
                  }}
                  onKeyDown={onKeyDown}
                  onFocus={onFocus}
                  onTouchEnd={handleFocus}
                />
                <div className={css.searchType}>
                  <span>
                    {showDateLabel
                      ? showDateLabel
                      : intl.formatMessage({ id: 'RenderLocationInputWithQueries.date' })}
                  </span>
                  <span>
                    {selectedTravelType
                      ? selectedTravelType?.label
                      : intl.formatMessage({ id: 'RenderLocationInputWithQueries.vehicle' })}
                  </span>
                </div>
                <div
                  className={classNames(css.selectedValues, {
                    [css.hideDates]: isLandingPage,
                  })}
                >
                  {showDateLabel ? (
                    <div>
                      {showDateLabel} {selectedTravelType?.label ? '•' : ''}
                    </div>
                  ) : null}
                  {selectedTravelType?.label}
                </div>
              </div>
            </Form>
          );
        }}
      />
    );
  },
  (prevProps, nextProps) => {
    return (
      prevProps.searchKeyword === nextProps.searchKeyword &&
      JSON.stringify(prevProps.predictions) === JSON.stringify(nextProps.predictions)
    );
  }
);

const RenderLocationInputWithQueries = compose(injectIntl)(RenderLocationInputWithQueriesComponent);
RenderLocationInputWithQueries.displayName = 'RenderLocationInputWithQueries';

export default RenderLocationInputWithQueries;
