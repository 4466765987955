import React from 'react';
import { useMyContextFunctions } from '../../../context/ContextFunctions';
import { TopbarFilterTypes } from '../../../util/enums';
import MakeFilterQueryFromTopbar from '../TopbarFilters/MakeFilterQueryFromTopbar';

import IconSearchDesktop from './IconSearchDesktop';
import IconCalendar from '../../IconCalendar/IconCalendar';
import IconVehicle from '../../IconVehicle/IconVehicle';
import Icons from '../../Icons/Icons';

import css from './TopbarSearchForm.module.css';
import { useMyContext } from '../../../context/StateHolder';
import { isArrayLength } from '../../../util/genericHelpers';

function RenderSearchFilter() {
  const { onSetGlobalFilterModal } = useMyContextFunctions();
  const { globalFilterParams } = useMyContext();

  const getFilterCount = () => {
    let count = 0;

    if (isArrayLength(globalFilterParams?.travelTypes)) {
      count++;
    }
    if (isArrayLength(globalFilterParams?.vehicleHeight)) {
      count++;
    }
    if (isArrayLength(globalFilterParams?.vehicleLength)) {
      count++;
    }

    if (globalFilterParams?.prices?.key) {
      count++;
    }
    if (isArrayLength(globalFilterParams?.pitchCategory)) {
      count++;
    }
    if (isArrayLength(globalFilterParams?.general)) {
      count++;
    }

    return count;
  };

  const openFilterModal = filterType => {
    onSetGlobalFilterModal(true, filterType);
  };

  return (
    <div className={css.topbarSearchFilters}>
      <span className={css.calendarIcon} onClick={() => openFilterModal(TopbarFilterTypes.DATES)}>
        <IconCalendar />
      </span>
      <span className={css.vehicleIcon} onClick={() => openFilterModal(TopbarFilterTypes.FILTERS)}>
        <Icons name="camping" />
      </span>
      <span
        className={css.filterIcon}
        onClick={() => openFilterModal(TopbarFilterTypes.EXTRA_FILTERS)}
      >
        <Icons name="filter" />
        {getFilterCount() > 0 && <span className={css.filterCount}>{getFilterCount()}</span>}
      </span>
    </div>
  );
}

export default RenderSearchFilter;
