import React, { useEffect } from 'react';

import { LocationSearchField } from './TopbarSearchForm';
import { useMyContextFunctions } from '../../../context/ContextFunctions';
import RenderSearchFilter from './RenderSearchFilter';
import { TopbarFilterTypes } from '../../../util/enums';
import { useMyContext } from '../../../context/StateHolder';
import { useDispatch } from 'react-redux';
import { useConfiguration } from '../../../context/configurationContext';
import IconSearchDesktop from './IconSearchDesktop';
import MakeFilterQueryFromTopbar from '../TopbarFilters/MakeFilterQueryFromTopbar';
import css from './TopbarSearchForm.module.css';

function RenderSearchForm(props) {
  const {
    isKeywordsSearch,
    renderKeywordSearch,
    desktopInputRootClass,
    intl,
    isMobile,
    inputRef,
    onLocationChange,
    hideSearchIcon,
    form,
    values,
  } = props;

  const searchKey = values?.location?.search;
  const dispatch = useDispatch();
  const config = useConfiguration();
  const { globalFilterParams } = useMyContext();
  const { onSetGlobalFilterModal } = useMyContextFunctions();

  useEffect(() => {
    if (globalFilterParams?.locationPrediction?.place_name) {
      form.change('location', {
        ...values?.location,
        search: globalFilterParams?.locationPrediction?.place_name,
      });
    }
  }, [globalFilterParams?.locationPrediction?.place_name]);

  const renderKeywordOrLocation = isKeywordsSearch ? (
    renderKeywordSearch
  ) : (
    <LocationSearchField
      desktopInputRootClass={desktopInputRootClass}
      intl={intl}
      isMobile={isMobile}
      inputRef={inputRef}
      onLocationChange={onLocationChange}
      onOpenFilterModal={() => {
        onSetGlobalFilterModal(true, TopbarFilterTypes.LOCATION);
        if (typeof window !== 'undefined') {
          const inputElement = window.document.getElementById('topBarInput');
          if (inputElement) {
            inputElement.disabled = false;
            inputElement.focus();
          }
        }
      }}
      hideSearchIcon={hideSearchIcon}
    />
  );

  return (
    <>
      <MakeFilterQueryFromTopbar
        className={css.searchIcon}
        onClick={() => {}}
        renderIcon={<IconSearchDesktop />}
      />
      {renderKeywordOrLocation}
      <RenderSearchFilter />
    </>
  );
}

export default RenderSearchForm;
