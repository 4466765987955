if (typeof window !== 'undefined') {
  window.dataLayer = window.dataLayer || [];
}

export const pushEventToDataLayer = (userId, event) => {
  // Ensure the dataLayer is available
  if (window.dataLayer) {
    // Push event to the dataLayer
    window.dataLayer.push({
      event,
      user: userId,
      page: 'checkout',
    });
  }
};
