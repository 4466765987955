import importOnlyListingBanner from '../assets/bannerInfoOnly.jpg';

export const GENERAL_SEO =
  'You and a View, camping rentals, scenic campgrounds, luxury tents, nature retreats, outdoor getaways, camping equipment rentals, serene camp spots, mountain view campsites, lakefront camping, forest retreats, eco-friendly camps, sustainable camping, weekend camp rentals, adventure lodging, wilderness accommodations, nature lodging, campfire nights, family camping sites, romantic camping retreats, off-grid camping, glamping sites, tent rentals, outdoor adventures, best camping spots, premium campgrounds, rustic getaways, camp under the stars, natural escapes, tranquil camping, camp with a view.';

export const DEFAULT_BOOKING_NAME = 'default-booking/release-1';

export const TopbarFilterTypes = {
  DATES: 'dates',
  LOCATION: 'location',
  FILTERS: 'filters',
  EXTRA_FILTERS: 'extraFilters',
};

export const EXTERNAL_LINKS = {
  calendly: 'https://calendly.com/wilma-youandaview',
  INFO_ONLY_BANNER: importOnlyListingBanner,
};

export const MIXPANEL_ENUMS = {
  MIX_PANEL_TOKEN: 'e6d2b704b8a91be5f7f42e216a92f0e0',
  BEGIN_CHECKOUT: 'begin_checkout',
  COMPLETE_CHECKOUT: 'complete_checkout',
  GUEST_CHECKOUT: 'guest_checkout',
};

export const GOOGLE_TAGS = {
  BEGIN_CHECKOUT: 'begin_checkout',
  COMPLETE_COMPLETE: 'complete_checkout',
};

export const EmailTemplateId = {
  GUEST_CUSTOMER_BOOKING_CONFIRMATION: 'd-1ed4526bf4ab4230859a630fa489892c',
  NEW_CLAIM_REQUEST_APPROVAL: 'd-afc6d255aea4486b806f85b23fcfedb1',
  INFO_LISTING_REQUEST_TO_HOST: 'd-d7951fd27d0f4a438a8e623b505d955c',
  INFO_LISTING_CONFIRMATION_TO_GUEST: 'd-4957e14fc8ed4ec5826fc834b5a5ac79',
};

export const transactionTransitions = {
  TRANSITION_REQUEST_PAYMENT_KLARNA: 'transition/request-payment-klarna',
  TRANSITION_CONFIRM_PAYMENT_KLARNA: 'transition/confirm-payment-klarna',
  TRANSITION_CONFIRM_INSTANT_PAYMENT_KLARNA: 'transition/confirm-instant-payment-klarna',
};

export const infoOnlyIcon =
  'https://dih6yo2fd8n78.cloudfront.net/Info+Listing+Map-fotor-bg-remover-20240504215249.png';


