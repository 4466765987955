import React, { useState } from 'react';
import { useMyContext } from '../../../context/StateHolder';
import { isArrayLength, keepValidGermanChars } from '../../../util/genericHelpers';
import {
  LocationPredictionsList,
  getGeocoderVariant,
} from '../../LocationAutocompleteInput/LocationAutocompleteInputImpl';
import { GeocoderAttribution } from '../../LocationAutocompleteInput/GeocoderMapbox';
import { useIntl } from '../../../util/reactIntl';
import { useMyContextFunctions } from '../../../context/ContextFunctions';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import routeConfiguration from '../../../routing/routeConfiguration';
import { useSelector } from 'react-redux';
import { renderLocationFilterSelectors } from '../../../ducks/global.duck';
import { IconSpinner } from '../..';
import { createResourceLocatorString } from '../../../util/routes';
import { createSlug } from '../../../util/urlHelpers';
import { EventBus } from '../../../util/eventHelpers';

import css from '../Topbar.module.css';
import { TopbarFilterTypes } from '../../../util/enums';

//Dummy functions for the LocationPredictionsList
const handlePredictionsSelectStart = touchCoordinates => {};
const handlePredictionsSelectMove = touchCoordinates => {};

const getEmptyStatus = (items, searchKeyword, isFetching) => {
  return searchKeyword?.length > 0 && !isFetching && (!items || items.length === 0);
};

function RenderLocationFilter() {
  const intl = useIntl();
  const history = useHistory();

  const { globalFilterParams, locationPredictions } = useMyContext();
  const { onSetGlobalFilterModal, onSetGlobalFilterParams } = useMyContextFunctions();
  const geocoderVariant = getGeocoderVariant('mapbox');

  const predictions = isArrayLength(locationPredictions)
    ? locationPredictions.filter(l => l.id !== 'current-location')
    : [];

  const { listingsData, fetchListingsInProgress, queryListingSuccess } =
    useSelector(renderLocationFilterSelectors) || {};

  const [geocoder, setGeocoder] = useState(null);

  const {
    locationPrediction: { place_name: selectedLocation } = {},
    blogs = [],
    seo = [],
    fetchingPredictions = false,
    searchKeyword = null,
  } = globalFilterParams || {};

  const handlePredictionsSelectEnd = prediction => {
    onSetGlobalFilterModal(true, TopbarFilterTypes.DATES);
    onSetGlobalFilterParams({ ...globalFilterParams, locationPrediction: prediction });
  };

  const emptyPredictions = getEmptyStatus(predictions, searchKeyword, fetchingPredictions);
  const emptyBlogs = getEmptyStatus(blogs, searchKeyword, fetchingPredictions);
  const emptySeo = getEmptyStatus(seo, searchKeyword, fetchingPredictions);

  const getGeocoder = () => {
    const geocoderVariant = getGeocoderVariant('mapbox');
    const Geocoder = geocoderVariant.default;

    // Create the Geocoder as late as possible only when it is needed.
    if (!geocoder) {
      setGeocoder(new Geocoder());
    }
    return geocoder;
  };

  const onFocusTopbarSearchInput = () => {
    EventBus.dispatch('focusInput', { showCursor: true });
  };

  return (
    <div className={css.locationFilterWrapper}>
      <div className={css.locationPredictions}>
        <h4>
          {intl.formatMessage({ id: 'RenderLocationFilter.campingIn' })} {selectedLocation}
        </h4>
        {fetchingPredictions ? (
          <IconSpinner />
        ) : isArrayLength(predictions) ? (
          <LocationPredictionsList
            rootClassName={css.predictions}
            predictions={predictions.slice(0, 3)}
            currentLocationId={geocoderVariant.CURRENT_LOCATION_ID}
            isGoogleMapsInUse={false}
            geocoder={getGeocoder()}
            highlightedIndex={false}
            onSelectStart={handlePredictionsSelectStart}
            onSelectMove={handlePredictionsSelectMove}
            onSelectEnd={handlePredictionsSelectEnd}
          >
            <GeocoderAttribution className={css.geocoderAttribution} />
          </LocationPredictionsList>
        ) : (
          <span htmlFor="location" className={css.focusInput} onClick={onFocusTopbarSearchInput}>
            {intl.formatMessage({
              id: !emptyPredictions
                ? 'RenderLocationFilter.focusInput'
                : 'RenderLocationFilter.noSearchResults',
            })}
          </span>
        )}
      </div>
      <div className={css.otherLinks}>
        <h4>{intl.formatMessage({ id: 'RenderLocationFilter.listings' })}</h4>
        <div className={css.filterBlock}>
          {fetchListingsInProgress ? <IconSpinner /> : null}
          {isArrayLength(listingsData) ? (
            listingsData.map((option, index) => {
              return (
                <span
                  key={index}
                  className={css.otherLink}
                  onClick={() => {
                    onSetGlobalFilterModal(false);
                    history.push(
                      createResourceLocatorString(
                        'ListingPage',
                        routeConfiguration(),
                        {
                          id: option?.id,
                          slug: createSlug(option?.title),
                        },
                        {}
                      )
                    );
                  }}
                >
                  {option?.title}
                </span>
              );
            })
          ) : (
            <span onClick={onFocusTopbarSearchInput}>
              {intl.formatMessage({
                id: queryListingSuccess
                  ? 'RenderLocationFilter.noSearchResults'
                  : 'RenderLocationFilter.focusListingsInput',
              })}
            </span>
          )}
        </div>
      </div>
      <div className={css.otherLinks}>
        <h4>{intl.formatMessage({ id: 'TopbarDesktop.selectSeoPage' })}</h4>
        <div className={css.filterBlock}>
          {isArrayLength(seo) ? (
            seo.slice(0, 3).map((option, index) => {
              return (
                <span
                  key={index}
                  className={css.otherLink}
                  onClick={() => {
                    onSetGlobalFilterModal(false);
                    history.push(`/camping/${option?.attributes?.title}`);
                  }}
                >
                  {option?.attributes?.title}
                </span>
              );
            })
          ) : (
            <span onClick={onFocusTopbarSearchInput}>
              {intl.formatMessage({
                id: emptySeo
                  ? 'RenderLocationFilter.noSearchResults'
                  : 'RenderLocationFilter.focusSeoInput',
              })}
            </span>
          )}
        </div>
      </div>

      <div className={css.otherLinks}>
        <h4>{intl.formatMessage({ id: 'TopbarDesktop.blogPage' })}</h4>
        <div className={css.filterBlock}>
          {isArrayLength(blogs) ? (
            blogs.slice(0, 3).map((option, index) => {
              return (
                <span
                  key={index}
                  className={css.otherLink}
                  onClick={() => {
                    onSetGlobalFilterModal(false);
                    history.push(
                      createResourceLocatorString(
                        'SingleBlogPage',
                        routeConfiguration(),
                        {
                          id: option?.id,
                          slug: createSlug(option?.attributes?.title),
                        },
                        {}
                      )
                    ),
                      { state: { cacheData: option } };
                  }}
                >
                  {keepValidGermanChars(option?.attributes?.title?.replace(/#/g, ' '))}
                </span>
              );
            })
          ) : (
            <span onClick={onFocusTopbarSearchInput}>
              {intl.formatMessage({
                id: emptyBlogs
                  ? 'RenderLocationFilter.noSearchResults'
                  : 'RenderLocationFilter.focusBlogInput',
              })}
            </span>
          )}
        </div>
      </div>
    </div>
  );
}

export default RenderLocationFilter;
