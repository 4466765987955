import React from 'react';
import DateRangePickerWrapper from '../DateRangePicker/DateRangerPicker';
import { useMyContextFunctions } from '../../../context/ContextFunctions';
import { useMyContext } from '../../../context/StateHolder';

import css from '../Topbar.module.css';

function RenderDatesInput() {
  const { globalFilterParams } = useMyContext();
  const { onSetGlobalFilterParams } = useMyContextFunctions();

  const selectedDates = globalFilterParams?.dates;

  const handleDateRangeChange = dates => {
    onSetGlobalFilterParams({ dates });
  };

  return (
    <div className={css.availablityCalendar}>
      <DateRangePickerWrapper
        isLandingDateRange={true}
        isSearchPageFilter={false}
        selectedDates={selectedDates}
        onDateRangeChange={handleDateRangeChange}
      />
    </div>
  );
}

export default RenderDatesInput;
