import React from 'react';

const IconNextArrow = props => {
  const { className } = props;
  return (
    <svg
      className={className}
      fill="none"
      width="40"
      height="40"
      viewBox="0 0 40 40"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="20" cy="20" r="19.6875" fill="white" stroke="none" strokeWidth="0.625" />
      <path d="M17.5 13.75L23.75 20L17.5 26.25" stroke="#3e5ba6" strokeWidth="1.5" />
    </svg>
  );
};

export default IconNextArrow;
