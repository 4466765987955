import React, { useEffect, useRef, useState } from 'react';
import ReactDOM from 'react-dom';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { useIntl } from '../../util/reactIntl';
import { Button, IconClose } from '..';

import css from './FilterModal.module.css';
import { useMyContext } from '../../context/StateHolder';
import { useMyContextFunctions } from '../../context/ContextFunctions';

const KEY_CODE_ESCAPE = 27;

function Portal({ portalRoot, children }) {
  const elRef = useRef(document.createElement('div'));

  useEffect(() => {
    portalRoot.appendChild(elRef.current);
    return () => {
      portalRoot.removeChild(elRef.current);
    };
  }, [portalRoot]);

  return ReactDOM.createPortal(children, elRef.current);
}

function FilterModalComponent(props) {
  const {
    children,
    className,
    scrollLayerClassName,
    closeButtonMessage,
    containerClassName,
    contentClassName,
    lightCloseButton,
    isClosedClassName,
    usePortal,
    id,
    onClose,
    onManageDisableScrolling,
  } = props;

  let { isOpen } = props;

  useEffect(() => {
    onManageDisableScrolling(id, isOpen);

    // Scroll to top logic
    if (isOpen) {
      // If the modal itself is the scroll container
      if (refDiv.current) {
        refDiv.current.scrollTop = 0;
      }
      // If the whole window should scroll to the top
      else {
        window.scrollTo(0, 0);
      }
    }

    window.document.body.addEventListener('keyup', handleBodyKeyUp);
    vhRef.current = window.innerHeight * 0.01;
    document.documentElement.style.setProperty('--vh', `${vhRef.current}px`);
    window.addEventListener('resize', handleResize);
    setPortalRoot(document.getElementById('portal-root'));

    return () => {
      window.document.body.removeEventListener('keyup', handleBodyKeyUp);
      window.removeEventListener('resize', handleResize);
      onManageDisableScrolling(id, false);
    };
  }, [id, isOpen, onManageDisableScrolling]);

  const { openGlobalFilterModal, closeGlobalModalForcefully } = useMyContext();
  const { onSetGlobalFilterModal } = useMyContextFunctions();

  if (openGlobalFilterModal) {
    isOpen = openGlobalFilterModal;
  }

  // if(closeGlobalModalForcefully){
  //   isOpen = false;
  // }

  const intl = useIntl();
  const [portalRoot, setPortalRoot] = useState(null);
  const refDiv = useRef(null);
  const vhRef = useRef(null);

  const handleBodyKeyUp = event => {
    if (event.keyCode === KEY_CODE_ESCAPE && isOpen) {
      handleClose(event);
    }
  };

  const handleClose = event => {
    onManageDisableScrolling(id, false);
    onClose(event);
    onSetGlobalFilterModal(false);
  };

  const handleResize = () => {
    vhRef.current = window.innerHeight * 0.01;
    document.documentElement.style.setProperty('--vh', `${vhRef.current}px`);
  };

  useEffect(() => {
    onManageDisableScrolling(id, isOpen);
    window.document.body.addEventListener('keyup', handleBodyKeyUp);

    vhRef.current = window.innerHeight * 0.01;
    document.documentElement.style.setProperty('--vh', `${vhRef.current}px`);
    window.addEventListener('resize', handleResize);

    setPortalRoot(document.getElementById('portal-root'));

    return () => {
      window.document.body.removeEventListener('keyup', handleBodyKeyUp);
      window.removeEventListener('resize', handleResize);
      onManageDisableScrolling(id, false);
    };
  }, [id, isOpen, onManageDisableScrolling]);

  const closeModalMessage = intl.formatMessage({ id: 'Modal.closeModal' });
  const closeButtonClasses = classNames(css.close, {
    [css.closeLight]: lightCloseButton,
  });
  const closeBtn = isOpen ? (
    <Button onClick={handleClose} rootClassName={closeButtonClasses} title={closeModalMessage}>
      <IconClose rootClassName={css.closeIcon} />
    </Button>
  ) : null;

  const isOpenClass = usePortal ? css.isOpenInPortal : css.isOpenInPlace;
  const modalClass = isOpen ? isOpenClass : isClosedClassName;
  const classes = classNames(modalClass, className);
  const scrollLayerClasses = scrollLayerClassName || css.scrollLayer;
  const containerClasses = containerClassName || css.container;

  return !usePortal ? (
    <div className={classes}>
      <div className={scrollLayerClasses}>
        <div className={containerClasses}>
          {closeBtn}
          <div className={classNames(contentClassName || css.content)}>{children}</div>
        </div>
      </div>
    </div>
  ) : portalRoot ? (
    <Portal portalRoot={portalRoot}>
      <div className={classes}>
        <div className={scrollLayerClasses}>
          <div className={classNames(containerClasses, css.focusedDiv)} ref={refDiv} tabIndex="-1">
            {closeBtn}
            <div className={classNames(contentClassName || css.content)}>{children}</div>
          </div>
        </div>
      </div>
    </Portal>
  ) : null;
}

FilterModalComponent.defaultProps = {
  children: null,
  className: null,
  scrollLayerClassName: null,
  closeButtonMessage: null,
  containerClassName: null,
  contentClassName: null,
  lightCloseButton: false,
  isClosedClassName: css.isClosed,
  isOpen: false,
  onClose: null,
  usePortal: false,
};

FilterModalComponent.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  scrollLayerClassName: PropTypes.string,
  closeButtonMessage: PropTypes.node,
  containerClassName: PropTypes.string,
  contentClassName: PropTypes.string,
  lightCloseButton: PropTypes.bool,
  id: PropTypes.string.isRequired,
  isClosedClassName: PropTypes.string,
  isOpen: PropTypes.bool,
  onClose: PropTypes.func.isRequired,
  usePortal: PropTypes.bool,
  onManageDisableScrolling: PropTypes.func.isRequired,
};

export default FilterModalComponent;
