import React from 'react';
import {
  ExternalLink,
  Heading,
  IconSocialMediaFacebook,
  IconSocialMediaInstagram,
  LinkedLogo,
  NamedLink,
} from '../../components';
import { FormattedMessage } from '../../util/reactIntl';
import classNames from 'classnames';
import { useIntl } from '../../util/reactIntl';
import IconSocialMediaTiktok from '../../components/IconSocialMediaTiktok/IconSocialMediaTiktok';

import css from './FooterContainer.module.css';
import { locations } from '../CategoryPage/CategoryPage';
import { useHistory, useLocation } from 'react-router-dom/cjs/react-router-dom.min';
import { createResourceLocatorString } from '../../util/routes';
import routeConfiguration from '../../routing/routeConfiguration';
import { createSlugLowerCase } from '../../util/genericHelpers';

const renderSocialIcons = (
  <div className={css.socialLinks}>
    <ExternalLink href="https://www.facebook.com/InfoYouandaView" className={css.navLink}>
      <IconSocialMediaFacebook />
    </ExternalLink>
    <ExternalLink href="https://www.instagram.com/you.and.a.view" className={css.navLink}>
      <IconSocialMediaInstagram />
    </ExternalLink>
    <ExternalLink href="https://www.tiktok.com/@you.and.a.view" className={css.navLink}>
      <IconSocialMediaTiktok />
    </ExternalLink>
  </div>
);

const linkedLogo = <LinkedLogo className={css.logoLink} layout="desktop" />;

function FooterContainer(props) {
  const { isListingPage, showMobileNavBar } = props;
  const intl = useIntl();
  const history = useHistory();
  const location = useLocation();
  const isHomepage = location.pathname === '/';

  const footerLinks = (
    <div
      className={classNames(css.footer, {
        [css.listingPageFooter]: isListingPage,
        [css.bottomBarSpacing]: showMobileNavBar,
      })}
    >
      <div className={css.contentWidth}>
        <div className={css.leftSect}>
          {linkedLogo}
          <p>You and a View - {intl.formatMessage({ id: 'Footer.description' })}</p>
        </div>
        <div className={css.rightSect}>
          <div className={css.quickLinks}>
            <NamedLink name="TermsOfServicePage" className={css.navLink}>
              <FormattedMessage id="TopbarMobileMenu.terms" />
            </NamedLink>
            <NamedLink name="DataProtectionPage" className={css.navLink}>
              <FormattedMessage id="TopbarMobileMenu.dataProtection" />
            </NamedLink>
            <NamedLink name="ImprintPage" className={css.navLink}>
              <FormattedMessage id="TopbarMobileMenu.imprint" />
            </NamedLink>
            <NamedLink name="WithdrawalPage" className={css.navLink}>
              <FormattedMessage id="TopbarMobileMenu.withdrawal" />
            </NamedLink>
            <span className={css.copyright}>© You and a View</span>
            {renderSocialIcons}
          </div>
        </div>
      </div>
    </div>
  );

  const categoryLinks = (
    <div
      className={classNames(css.countryListWrapper, {
        [css.seoPageWrapper]: !isHomepage,
      })}
    >
      <div className={css.contentWidth}>
        <h4>
          <FormattedMessage id="FooterContainer.categoryLinksHeading" />
        </h4>
        <div className={css.countryList}>
          {locations.map(l => {
            const country = l?.country?.name;
            const state = l?.state?.name;
            const city = l?.title;

            return (
              <span
                className={css.countryName}
                role="button"
                onClick={() => {
                  const url = createResourceLocatorString(
                    'CityCategoryPage',
                    routeConfiguration(),
                    {
                      country: createSlugLowerCase(country),
                      state: createSlugLowerCase(state),
                      city: createSlugLowerCase(city),
                    },
                    {}
                  );
                  if (typeof window !== 'undefined') {
                    window.location.href = url;
                  }
                }}
              >
                {l?.title}
              </span>
            );
          })}
        </div>
      </div>
    </div>
  );

  return (
    <>
      {/* {true ? categoryLinks : null} */}
      {footerLinks}
    </>
  );
}

export default FooterContainer;
