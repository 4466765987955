import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { FormattedMessage, intlShape, injectIntl } from '../../util/reactIntl';
import { Button, IconSpinner, Modal } from '../../components';
import { useDispatch, useSelector } from 'react-redux';
import { authenticationInProgress, selectIsAuthenticated, signup } from '../../ducks/auth.duck';
import ListingClaimForm from './ListingClaimForm/ListingClaimForm';
import { claimInfoListing, claimListingSelectors } from '../../ducks/global.duck';
import { showToaster } from '../../util/genericHelpers';
import { createResourceLocatorString } from '../../util/routes';
import routeConfiguration from '../../routing/routeConfiguration';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';

import css from './ListingClaimModal.module.css';

const ListingClaimModal = props => {
  const {
    className,
    rootClassName,
    id,
    intl,
    isOpen,
    onCloseModal,
    onManageDisableScrolling,
    saveEmailError,
    currentListing,
    onResendVerificationEmail,
  } = props;

  const dispatch = useDispatch();
  const history = useHistory();

  const signupOrLoginInProgress = useSelector(authenticationInProgress);
  const isAuthenticated = useSelector(selectIsAuthenticated);

  const { claimListingInProgress, claimListingSuccess, claimListingError } = useSelector(
    claimListingSelectors
  );

  const classes = classNames(rootClassName || css.root, className);

  const billingDetailsMaybe =
    (typeof window !== 'undefined' &&
      window.localStorage.getItem('billingDetails') &&
      JSON.parse(window.localStorage.getItem('billingDetails'))) ||
    {};

  const cookiesPreferences =
    typeof window !== 'undefined' && window.localStorage.getItem('react-hook-consent');

  const onClaimListing = async () => {
    try {
      const response = await dispatch(claimInfoListing({ listingId: currentListing?.id?.uuid }));
      onResendVerificationEmail();
      claimListingSuccess && showToaster('success', 'You have successfully claimed this listing');
      history.push(createResourceLocatorString('ManageListingsPage', routeConfiguration(), {}, {}));
    } catch (error) {
      claimListingError &&
        showToaster(
          'error',
          'Something went wrong while claiming this listing. Please contact support.'
        );
    }
  };

  const handleSubmitSignup = async values => {
    if (cookiesPreferences) {
      Object.assign(values, { cookiesPreferences: JSON.parse(cookiesPreferences) });
    }
    const { firstName, lastName, ...rest } = values;
    const params = { firstName: firstName.trim(), lastName: lastName.trim(), ...rest };
    const authResponse = await dispatch(signup(params));
    onClaimListing();
  };

  return (
    <Modal
      id={id}
      containerClassName={classes}
      contentClassName={css.modalContent}
      isOpen={isOpen}
      onClose={onCloseModal}
      onManageDisableScrolling={onManageDisableScrolling}
      usePortal
      closeButtonMessage={intl.formatMessage({ id: 'ListingClaimModal.later' })}
    >
      <p className={css.modalTitle}>
        <FormattedMessage id="ListingClaimModal.title" />
      </p>
      <p className={css.modalMessage}>
        <FormattedMessage
          id="ListingClaimModal.description"
          values={{
            lineBreak: <br />,
            importantText: <b>{intl.formatMessage({ id: 'ListingClaimModal.important' })}</b>,
          }}
        />
      </p>
      {signupOrLoginInProgress || claimListingInProgress ? (
        <div className={css.claimInProgress}>
          <IconSpinner />
          <h4>
            <FormattedMessage id="ListingClaimModal.claimInProgress" />
          </h4>
        </div>
      ) : isAuthenticated ? (
        <div className={css.claimListingButton}>
          <Button type="button" onClick={onClaimListing}>
            <FormattedMessage id="ListingClaimModal.title" />
          </Button>
        </div>
      ) : (
        <ListingClaimForm
          initialValues={{ ...billingDetailsMaybe }}
          saveEmailError={saveEmailError}
          onSubmit={handleSubmitSignup}
          claimUserInProgress={claimListingInProgress}
        />
      )}
    </Modal>
  );
};

const { string } = PropTypes;

ListingClaimModal.defaultProps = {
  className: null,
  rootClassName: null,
};

ListingClaimModal.propTypes = {
  className: string,
  rootClassName: string,
  intl: intlShape.isRequired,
};

export default injectIntl(ListingClaimModal);
