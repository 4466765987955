import React from 'react';

/**
 * Helper function for using the EventBus.
 *
 * The EventBus allows components to communicate with each other without being directly linked.
 * It supports subscribing to events, unsubscribing from events, and dispatching events.
 *
 * Methods:
 * - subscribe(event, callback): Listen for an event. The callback will be executed when the event is dispatched.
 *   @param {string} event - The event name to listen for.
 *   @param {function} callback - The function to execute when the event occurs.
 *
 * - unsubscribe(event, callback): Stop listening for an event.
 *   @param {string} event - The event name to stop listening for.
 *   @param {function} callback - The callback that was originally subscribed.
 *
 * - dispatch(event, data): Trigger an event, executing all callbacks subscribed to it.
 *   @param {string} event - The event name to dispatch.
 *   @param {*} data - Optional data to pass to the callbacks.
 *
 * Example usage:
 *
 * // Define a callback function
 * function onFocused(data) {
 *   console.log('Input focused', data);
 * }
 *
 * // Subscribe to the 'focusInput' event
 * EventBus.subscribe('focusInput', onFocused);
 *
 * // Dispatch the 'focusInput' event, optionally passing data
 * EventBus.dispatch('focusInput', { details: 'additional details' });
 *
 * // Unsubscribe from the 'focusInput' event
 * EventBus.unsubscribe('focusInput', onFocused);
 */

export const EventBus = {
  events: {},
  dispatch: function(event, data) {
    if (!this.events[event]) return; // No event registered
    this.events[event].forEach(callback => callback(data));
  },
  subscribe: function(event, callback) {
    if (!this.events[event]) this.events[event] = []; // New event
    this.events[event].push(callback);
  },
  unsubscribe: function(event, callback) {
    if (this.events[event]) {
      const index = this.events[event].indexOf(callback);
      if (index > -1) this.events[event].splice(index, 1);
    }
  },
};
