import React, { useState, useEffect, useRef } from 'react';
import axios from 'axios';
import { useField } from 'react-final-form';
import mapboxgl from 'mapbox-gl';
import MapboxGeocoder from '@mapbox/mapbox-gl-geocoder';
import MapboxWorker from 'worker-loader!mapbox-gl/dist/mapbox-gl-csp-worker';
import { useIntl } from '../../util/reactIntl';

import css from './MapFormField.module.css';
import { getDefaultLocale } from '../../util/genericHelpers';

const defaultLocale = getDefaultLocale();

mapboxgl.accessToken = process.env.REACT_APP_MAPBOX_ACCESS_TOKEN;
mapboxgl.workerClass = MapboxWorker; // Wire up loaded worker to be used instead of the default

const setMapLanguage = (map, locale) => {
  const languageField = `name_${locale}`;
  map.getStyle().layers.forEach(layer => {
    if (layer.type === 'symbol' && layer.layout && 'text-field' in layer.layout) {
      map.setLayoutProperty(layer.id, 'text-field', [
        'coalesce',
        ['get', languageField],
        ['get', 'name'],
      ]);
    }
  });
};

const MapField = ({ name, locationValues }) => {
  if (typeof window === 'undefined') {
    return null;
  }
  const {
    input: { value, onChange },
  } = useField(name);

  const [map, setMap] = useState(null);
  const [error, setError] = useState(null);
  const mapContainer = useRef(null);
  const marker = useRef(null);
  const geocoder = useRef(null);
  const intl = useIntl();

  const reverseGeocode = async (lng, lat) => {
    const response = await axios.get(
      `https://api.mapbox.com/geocoding/v5/mapbox.places/${lng},${lat}.json?access_token=${mapboxgl.accessToken}`
    );
    return response.data.features[0]?.place_name;
  };

  const selectedPlace = locationValues?.selectedPlace?.origin;

  // Function to check if the latitude and longitude are valid
  const isValidLatLng = (lat, lng) => {
    return !isNaN(lat) && lat >= -90 && lat <= 90 && !isNaN(lng) && lng >= -180 && lng <= 180;
  };

  // Function to update marker visibility
  // const updateMarkerVisibility = (latitude, longitude) => {
  //   if (isValidLatLng(latitude, longitude)) {
  //     if (!marker.current) {
  //       marker.current = new mapboxgl.Marker({ draggable: true })
  //         .setLngLat([longitude, latitude])
  //         .addTo(map);
  //     } else {
  //       marker.current.setLngLat([longitude, latitude]).addTo(map);
  //     }
  //   } else {
  //     if (marker.current) {
  //       marker.current.remove();
  //       marker.current = null;
  //     }
  //   }
  // };

  useEffect(() => {
    const initializeMap = ({ setMap, mapContainer }) => {
      const latitude = selectedPlace?.lat || (isNaN(value.latitude) ? 48.4796084 : value.latitude);
      const longitude =
        selectedPlace?.lng || (isNaN(value.longitude) ? 4.2342942 : value.longitude);

      const newMap = new mapboxgl.Map({
        container: mapContainer.current,
        style: 'mapbox://styles/mapbox/streets-v11',
        center: [longitude, latitude],
        zoom: 3,
      });

      newMap.on('styledata', () => {
        setMapLanguage(newMap, defaultLocale);
      });

      const newMarker = new mapboxgl.Marker({ draggable: true });
      // .setLngLat([longitude, latitude])
      // .addTo(newMap);

      if (
        isValidLatLng(selectedPlace?.lat || value.latitude, selectedPlace?.lng || value.longitude)
      ) {
        newMarker.on('dragend', async () => {
          const lngLat = newMarker.getLngLat();
          const address = await reverseGeocode(lngLat.lng, lngLat.lat);
          onChange({
            longitude: lngLat.lng,
            latitude: lngLat.lat,
            address,
          });
        });
      }
      newMap.on('load', () => {
        setMap(newMap);
        marker.current = newMarker;
      });

      geocoder.current = new MapboxGeocoder({
        accessToken: mapboxgl.accessToken,
        mapboxgl: mapboxgl,
        language: defaultLocale,
      });

      geocoder.current.on('result', function(e) {
        marker.current.setLngLat(e.result.geometry.coordinates);
        marker.current.setDraggable(true); // Re-enable dragging
        onChange({
          longitude: e.result.geometry.coordinates[0],
          latitude: e.result.geometry.coordinates[1],
          address: e.result?.place_name,
        });
      });

      document.getElementById('geocoder').appendChild(geocoder.current.onAdd(newMap));
    };

    if (!map) {
      initializeMap({ setMap, mapContainer });
    }
  }, [map]);

  const handleChangeLatitude = e => {
    //Handle the case when the user types "-"
    if (e.target.value === '-') {
      onChange({
        longitude: value.longitude || 0,
        latitude: e.target.value,
        address: value.address,
      });
      marker.current.setLngLat([value.longitude || 0, 0]);
      map.flyTo({ center: [value.longitude || 0, 0] });
      marker.current.setDraggable(true);
      return;
    }

    //Handle the case when the user types "{number}."
    const chars = e.target.value.split('');
    if (chars[chars.length - 1] === '.') {
      const numberValue = e.target.value && Number(e.target.value);
      onChange({
        longitude: value.longitude || 0,
        latitude: e.target.value,
        address: value.address,
      });
      marker.current.setLngLat([value.longitude || 0, numberValue]);
      map.flyTo({ center: [value.longitude || 0, numberValue] });
      marker.current.setDraggable(true);
      return;
    }

    //Basic usage
    const receivedValue = e.target.value && Number(e.target.value);
    const isValid = receivedValue <= 180 && receivedValue >= -180;
    const validatedReceivedValue = isValid ? receivedValue : 0;

    if (!isValid) {
      setError(true);
    } else {
      setError(false);
    }
    onChange({
      longitude: value.longitude || 0,
      latitude: validatedReceivedValue,
      address: value.address,
    });

    marker.current.setLngLat([value.longitude || 0, validatedReceivedValue]);
    map.flyTo({ center: [value.longitude || 0, validatedReceivedValue] });
    marker.current.setDraggable(true);
  };

  const handleChangeLongitude = e => {
    //Handle the case when the user types "-"
    if (e.target.value === '-') {
      onChange({
        longitude: e.target.value,
        latitude: value.latitude || 0,
        address: value.address,
      });
      marker.current.setLngLat([0, value.latitude || 0]);
      map.flyTo({ center: [0, value.latitude || 0] });
      marker.current.setDraggable(true);
      return;
    }
    //Handle the case when the user types "{number}."
    const chars = e.target.value.split('');
    if (chars[chars.length - 1] === '.') {
      const numberValue = e.target.value && Number(e.target.value);
      onChange({
        longitude: e.target.value,
        latitude: value.latitude || 0,
        address: value.address,
      });
      marker.current.setLngLat([numberValue, value.latitude || 0]);
      map.flyTo({ center: [numberValue, value.latitude || 0] });
      marker.current.setDraggable(true);
      return;
    }
    //Basic usage
    const receivedValue = e.target.value && Number(e.target.value);
    const isValid = receivedValue <= 180 && receivedValue >= -180;
    const validatedReceivedValue = isValid ? receivedValue : 0;
    if (!isValid) {
      setError(true);
    } else {
      setError(false);
    }
    onChange({
      longitude: validatedReceivedValue,
      latitude: value.latitude || 0,
      address: value.address,
    });

    marker.current.setLngLat([validatedReceivedValue, value.latitude || 0]);
    map.flyTo({ center: [validatedReceivedValue, value.latitude || 0] });
    marker.current.setDraggable(true);
  };

  return (
    <div className={css.mapSection}>
      <div id="geocoder" className={css.geocoder}></div>
      <div>
        {value.address || locationValues?.selectedPlace?.address ? (
          <p className={css.info2}>
            {intl.formatMessage({ id: 'MapFormField.address' })}{' '}
            {locationValues?.selectedPlace?.address || value?.address}
          </p>
        ) : null}
      </div>
      <div className={css.mapContainer} ref={el => (mapContainer.current = el)} />
      <div className={css.formRow}>
        <div className={css.formFld}>
          <label className={css.inputLabel}>
            {' '}
            {intl.formatMessage({ id: 'MapFormField.Latitude' })}
          </label>
          <input
            type="text"
            value={selectedPlace?.lat || value?.latitude}
            onChange={handleChangeLatitude}
            className={css.latLngInput}
          />
        </div>
        <div className={css.formFld}>
          <label className={css.inputLabel}>
            {intl.formatMessage({ id: 'MapFormField.Longitude' })}
          </label>
          <input
            type="text"
            value={selectedPlace?.lng || value?.longitude}
            onChange={handleChangeLongitude}
            className={css.latLngInput}
          />
        </div>
      </div>
      {error ? (
        <p className={css.error}>{intl.formatMessage({ id: 'MapFormField.error' })}</p>
      ) : null}
    </div>
  );
};

export default MapField;
