import React from 'react';
import { func, object, shape, string } from 'prop-types';
import { Field } from 'react-final-form';
import loadable from '@loadable/component';
import { ValidationError } from '../../components';
import { useMyContextFunctions } from '../../context/ContextFunctions';

// Load LocationAutocompleteInputImpl dynamically
const LocationAutocompleteInputImpl = loadable(() =>
  import(/* webpackChunkName: "LocationAutocompleteInputImpl" */ './LocationAutocompleteInputImpl')
);

// Convert to functional component
const LocationAutocompleteInput = ({
  rootClassName,
  labelClassName,
  hideErrorMessage,
  onOpenFilterModal = () => {},
  input,
  label,
  meta,
  valueFromForm,
  ...otherProps
}) => {
  const value = typeof valueFromForm !== 'undefined' ? valueFromForm : input.value;

  const locationAutocompleteProps = {
    label,
    meta,
    ...otherProps,
    input: { ...input, value },
    onOpenFilterModal,
  };

  const labelInfo = label ? (
    <label className={labelClassName} htmlFor={input.name}>
      {label}
    </label>
  ) : null;

  return (
    <div className={rootClassName}>
      {labelInfo}
      <LocationAutocompleteInputImpl {...locationAutocompleteProps} />
      {hideErrorMessage ? null : <ValidationError fieldMeta={meta} />}
    </div>
  );
};

// Default props
LocationAutocompleteInput.defaultProps = {
  rootClassName: null,
  labelClassName: null,
  type: null,
  label: null,
};

// Prop types
LocationAutocompleteInput.propTypes = {
  rootClassName: string,
  labelClassName: string,
  input: shape({
    onChange: func.isRequired,
    name: string.isRequired,
  }).isRequired,
  label: string,
  meta: object.isRequired,
};

export default LocationAutocompleteInputImpl;

// Export Field wrapper
export const FieldLocationAutocompleteInput = props => {
  return <Field component={LocationAutocompleteInput} {...props} />;
};
