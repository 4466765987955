import { createIntl, createIntlCache } from 'react-intl';
import IntlMessageFormat from 'intl-messageformat';
import englishMessages from '../translations/en.json';
import germanMessages from '../translations/de.json';
import frenchMessages from '../translations/fr.json';
import spanishMessages from '../translations/es.json';
import italianMessages from '../translations/it.json';
import dutchMessages from '../translations/nl.json';
import portugalMessages from '../translations/pt.json';
import { getDefaultLocale } from './genericHelpers';

const language = getDefaultLocale();

let messages = englishMessages;

switch (language) {
  case 'en':
    messages = englishMessages;
    break;

  case 'de':
    messages = germanMessages;
    break;

  case 'fr':
    messages = frenchMessages;
    break;

  case 'es':
    messages = spanishMessages;
    break;

  case 'it':
    messages = italianMessages;
    break;

  case 'nl':
    messages = dutchMessages;
    break;
  case 'pt':
    messages = portugalMessages;
    break;
}

const cache = createIntlCache();
const intl = createIntl({ locale: language, messages }, cache);
const formattedMessage = id => new IntlMessageFormat(intl.formatMessage({ id })).format({});

export const travelTypesOptions = [
  {
    key: 'active-vacation',
    label: formattedMessage('TravelTypes.ActiveVacation'),
  },
  {
    key: 'family-vacation',
    label: formattedMessage('TravelTypes.FamilyVacation'),
  },
  {
    key: 'seeking-relaxation',
    label: formattedMessage('TravelTypes.SeekingRelaxation'),
  },
];

export const arrivalDaysOptions = [
  {
    key: 'Monday',
    label: formattedMessage('ArrivalDays.Monday'),
    index: 1,
  },
  {
    key: 'Tuesday',
    label: formattedMessage('ArrivalDays.Tuesday'),
    index: 2,
  },
  {
    key: 'Wednesday',
    label: formattedMessage('ArrivalDays.Wednesday'),
    index: 3,
  },
  {
    key: 'Thursday',
    label: formattedMessage('ArrivalDays.Thursday'),
    index: 4,
  },
  {
    key: 'Friday',
    label: formattedMessage('ArrivalDays.Friday'),
    index: 5,
  },
  {
    key: 'Saturday',
    label: formattedMessage('ArrivalDays.Saturday'),
    index: 6,
  },
  {
    key: 'Sunday',
    label: formattedMessage('ArrivalDays.Sunday'),
    index: 0,
  },
];

export const countryOptions = [
  { key: 'Austria', label: formattedMessage('country.Austria') },
  { key: 'Belgium', label: formattedMessage('country.Belgium') },
  { key: 'Bulgaria', label: formattedMessage('country.Bulgaria') },
  { key: 'Croatia', label: formattedMessage('country.Croatia') },
  { key: 'Republic of Cyprus', label: formattedMessage('country.Republic of Cyprus') },
  { key: 'Czech Republic', label: formattedMessage('country.Czech Republic') },
  { key: 'Denmark', label: formattedMessage('country.Denmark') },
  { key: 'Estonia', label: formattedMessage('country.Estonia') },
  { key: 'Finland', label: formattedMessage('country.Finland') },
  { key: 'France', label: formattedMessage('country.France') },
  { key: 'Germany', label: formattedMessage('country.Germany') },
  { key: 'Greece', label: formattedMessage('country.Greece') },
  { key: 'Hungary', label: formattedMessage('country.Hungary') },
  { key: 'Ireland', label: formattedMessage('country.Ireland') },
  { key: 'Italy', label: formattedMessage('country.Italy') },
  { key: 'Latvia', label: formattedMessage('country.Latvia') },
  { key: 'Lithuania', label: formattedMessage('country.Lithuania') },
  { key: 'Luxembourg', label: formattedMessage('country.Luxembourg') },
  { key: 'Malta', label: formattedMessage('country.Malta') },
  { key: 'Netherlands', label: formattedMessage('country.Netherlands') },
  { key: 'Poland', label: formattedMessage('country.Poland') },
  { key: 'Portugal', label: formattedMessage('country.Portugal') },
  { key: 'Romania', label: formattedMessage('country.Romania') },
  { key: 'Slovakia', label: formattedMessage('country.Slovakia') },
  { key: 'Slovenia', label: formattedMessage('country.Slovenia') },
  { key: 'Spain', label: formattedMessage('country.Spain') },
  { key: 'Sweden', label: formattedMessage('country.Sweden') },
  { key: 'Switzerland', label: formattedMessage('country.Switzerland') },
];

export const bookingLeadTimeOptions = [
  {
    key: '24h-before',
    duration: 24,
    label: formattedMessage('BookingLeadTimeOptions.24h'),
  },
  {
    key: '48h-before',
    duration: 48,
    label: formattedMessage('BookingLeadTimeOptions.48h'),
  },
  {
    key: 'spontaneous',
    duration: 0,
    label: formattedMessage('BookingLeadTimeOptions.spontaneous'),
  },
];

const generateHourOptions = (startHour, endHour, messageNamespace) => {
  const options = [];
  for (let i = startHour; i <= endHour; i++) {
    options.push({
      key: `until-${i}`,
      label: formattedMessage(`${messageNamespace}.bis${i}Uhr`),
    });
  }
  return options;
};

export const arrivalHourOptions = generateHourOptions(10, 24, 'arrivalHours');
export const departureHourOptions = generateHourOptions(10, 24, 'departureHour');

export const extrasOptions = [
  {
    key: 'sink',
    label: formattedMessage('Extras.Sink'),
    icon: 'https://dih6yo2fd8n78.cloudfront.net/listing-icons/06+EQUPMENT/3+SINK_s.png',
  },
  {
    key: 'sauna',
    label: formattedMessage('Extras.Sauna'),
    icon: 'https://dih6yo2fd8n78.cloudfront.net/listing-icons/06+EQUPMENT/2+SAUNA_s.png',
  },
  {
    key: 'swimming-pool',
    label: formattedMessage('Extras.SwimmingPool'),
    icon: 'https://dih6yo2fd8n78.cloudfront.net/listing-icons/06+EQUPMENT/swimming+pool_s.png',
  },
  {
    key: 'washing-machine',
    label: formattedMessage('Extras.WashingMachine'),
    icon: 'https://dih6yo2fd8n78.cloudfront.net/listing-icons/06+EQUPMENT/17+WASHING+MASHINE_s.png',
  },
  {
    key: 'hot-tub',
    label: formattedMessage('Extras.HotTub'),
    icon: 'https://dih6yo2fd8n78.cloudfront.net/listing-icons/06+EQUPMENT/4+WHIRPOOL+REV+1_s.png',
  },
  {
    key: 'charging-station',
    label: formattedMessage('Extras.ChargingStation'),
    icon: 'https://dih6yo2fd8n78.cloudfront.net/listing-icons/Extras/6+POWER+FOR+VEHICLES_s.png',
  },
  {
    key: 'pool-table',
    label: formattedMessage('Extras.PoolTable'),
    icon: 'https://dih6yo2fd8n78.cloudfront.net/listing-icons/Extras/3+BILLIARD_s.png',
  },
  {
    key: 'playground',
    label: formattedMessage('Extras.Playground'),
    icon: 'https://dih6yo2fd8n78.cloudfront.net/listing-icons/Extras/1+PLAYGROUND_s.png',
  },
  {
    key: 'bike-rental',
    label: formattedMessage('Extras.BikeRental'),
    icon: 'https://dih6yo2fd8n78.cloudfront.net/listing-icons/08+HOST+SERVICE/4+BYCICLE_s.png',
  },
  {
    key: 'breakfast-service',
    label: formattedMessage('Extras.BreakfastService'),
    icon: 'https://dih6yo2fd8n78.cloudfront.net/listing-icons/08+HOST+SERVICE/2+BREAKFAST_s.png',
  },
  {
    key: 'farm-tours',
    label: formattedMessage('Extras.FarmTours'),
    icon: 'https://dih6yo2fd8n78.cloudfront.net/listing-icons/08+HOST+SERVICE/Hoftour_s.png',
  },
  {
    key: 'horse-paddock',
    label: formattedMessage('Extras.HorsePaddock'),
    icon: 'https://dih6yo2fd8n78.cloudfront.net/listing-icons/08+HOST+SERVICE/1+HORSE+RIDING_s.png',
  },
  {
    key: 'garden',
    label: formattedMessage('Extras.Garden'),
    icon: 'https://dih6yo2fd8n78.cloudfront.net/listing-icons/Extras/2+PRIVAT+GARDEN_s.png',
  },
  {
    key: 'seating-options',
    label: formattedMessage('Extras.SeatingOptions'),
    icon: 'https://dih6yo2fd8n78.cloudfront.net/listing-icons/Extras/4+GARDEN+TABLE_s.png',
  },
  {
    key: 'farm-shop',
    label: formattedMessage('Extras.FarmShop'),
    icon: 'https://dih6yo2fd8n78.cloudfront.net/listing-icons/Extras/7+SHOPPING+AVAILABLE_s.png',
  },
  {
    key: 'firewood',
    label: formattedMessage('Extras.Firewood'),
    icon: 'https://dih6yo2fd8n78.cloudfront.net/listing-icons/08+HOST+SERVICE/3+FIREWOOD_s.png',
  },
  {
    key: 'cafe',
    label: formattedMessage('Extras.Cafe'),
    icon: 'https://dih6yo2fd8n78.cloudfront.net/listing-icons/07+GASTRONOMY/2+CAFE+REV+2_s.png',
  },
  {
    key: 'restaurant',
    label: formattedMessage('Extras.Restaurant'),
    icon:
      'https://dih6yo2fd8n78.cloudfront.net/listing-icons/07+GASTRONOMY/3+RESTAURANT+REV+1_s.png',
  },
  {
    key: 'bar',
    label: formattedMessage('Extras.Bar'),
    icon: 'https://dih6yo2fd8n78.cloudfront.net/listing-icons/07+GASTRONOMY/1+BAR+REV+1.1_s.png',
  },
  {
    key: 'board-games',
    label: formattedMessage('Extras.BoardGames'),
    icon: 'https://dih6yo2fd8n78.cloudfront.net/listing-icons/Extras/Boardgames_s.png',
  },
];

const generateVehicleOptions = (maxValue, prefix, messagePrefix, startValue) => {
  const options = [];
  for (let i = startValue; i <= maxValue; i++) {
    options.push({
      key: `up-to-${i}-meter`,
      label: formattedMessage(`${messagePrefix}.upto${i}meter`),
    });
  }
  options.push({
    key: `over-${maxValue}-meter`,
    label: formattedMessage(`${messagePrefix}.over${maxValue}meter`),
  });
  return options;
};

export const vehicleLengthOptions = generateVehicleOptions(10, 'upto', 'VehicleLength', 4);
export const vehicleHeightOptions = generateVehicleOptions(4, 'upto', 'VehicleHeight', 2);

export const vehicleTypesOptions = [
  {
    key: 'car-with-roof-tent',
    label: formattedMessage('VehicleTypes.CarWithRoofTent'),
    icon: 'https://dih6yo2fd8n78.cloudfront.net/listing-icons/12+VEHICLES/1+CAR+ROOF+TENT_s.png',
  },
  {
    key: 'motorhome',
    label: formattedMessage('VehicleTypes.Motorhome'),
    icon: 'https://dih6yo2fd8n78.cloudfront.net/camper-fotor-bg-remover-202406241234.png',
  },
  {
    key: 'motorhome-over-3-1/2-tons',
    label: formattedMessage('VehicleTypes.MotorhomeOver3AndHalfTons'),
    icon: 'https://dih6yo2fd8n78.cloudfront.net/camper%2B3.5t-fotor-bg-remover-202406241246.png',
  },
  {
    key: 'caravan',
    label: formattedMessage('VehicleTypes.Caravan'),
    icon: 'https://dih6yo2fd8n78.cloudfront.net/caravan-fotor-bg-remover-202406241254.png',
  },
  {
    key: 'tent',
    label: formattedMessage('VehicleTypes.Tent'),
    icon: 'https://dih6yo2fd8n78.cloudfront.net/tent-fotor-bg-remover-20240624132.png',
  },
  {
    key: 'van-minibus',
    label: formattedMessage('VehicleTypes.VanMinibus'),
    icon: 'https://dih6yo2fd8n78.cloudfront.net/van-fotor-bg-remover-202406241310.png',
  },
];

export const hostLanguagesOptions = [
  { key: 'Dutch', label: formattedMessage('HostLanguages.Dutch') },
  { key: 'English', label: formattedMessage('HostLanguages.English') },
  { key: 'French', label: formattedMessage('HostLanguages.French') },
  { key: 'German', label: formattedMessage('HostLanguages.German') },
  { key: 'Italian', label: formattedMessage('HostLanguages.Italian') },
  { key: 'Portuguese', label: formattedMessage('HostLanguages.Portuguese') },
  { key: 'Spanish', label: formattedMessage('HostLanguages.Spanish') },
];

export const directionsOptions = [
  {
    key: 'north',
    label: formattedMessage('Directions.North'),
    icon: 'https://dih6yo2fd8n78.cloudfront.net/listing-icons/02+ALIGNMENT/NORTH+REV+1_s.png',
  },
  {
    key: 'east',
    label: formattedMessage('Directions.East'),
    icon: 'https://dih6yo2fd8n78.cloudfront.net/listing-icons/02+ALIGNMENT/EAST+REV+1_s.png',
  },
  {
    key: 'south',
    label: formattedMessage('Directions.South'),
    icon: 'https://dih6yo2fd8n78.cloudfront.net/listing-icons/02+ALIGNMENT/SOUTH+REV+1_s.png',
  },
  {
    key: 'west',
    label: formattedMessage('Directions.West'),
    icon: 'https://dih6yo2fd8n78.cloudfront.net/listing-icons/02+ALIGNMENT/WEST+REV+1_s.png',
  },
];

export const generalOptions = [
  {
    key: 'four-wheel-drive-necessary',
    label: formattedMessage('General.fourWheelDrive'),
    icon:
      'https://dih6yo2fd8n78.cloudfront.net/listing-icons/06+EQUPMENT/5+4X4+ALLWHELL+REV+1_s.png',
  },
  {
    key: 'accessible',
    label: formattedMessage('General.accessible'),
    icon: 'https://dih6yo2fd8n78.cloudfront.net/listing-icons/06+EQUPMENT/18+WHEELCHAIR_s.png',
  },
  {
    key: 'pets-allowed',
    label: formattedMessage('General.petsAllowed'),
    icon: 'https://dih6yo2fd8n78.cloudfront.net/listing-icons/06+EQUPMENT/6+ANIMAL+ALLOWED_s.png',
  },
];

export const parkingLocationOptions = [
  {
    key: 'on-the-edge-of-a-field',
    label: formattedMessage('Vicinity.OnTheEdgeOfAField'),
    icon:
      'https://dih6yo2fd8n78.cloudfront.net/listing-icons/10+SURROUNDING/2+CAMPING+ON+FIELD+EDGE_s.png',
  },
  {
    key: 'on-the-edge-of-a-forest',
    label: formattedMessage('Vicinity.OnTheEdgeOfAForest'),
    icon: 'https://dih6yo2fd8n78.cloudfront.net/listing-icons/10+SURROUNDING/waldrand_s.png',
  },
  {
    key: 'by-a-river',
    label: formattedMessage('Vicinity.ByARiver'),
    icon:
      'https://dih6yo2fd8n78.cloudfront.net/listing-icons/10+SURROUNDING/6+CAMPING+ON+THE+RIVER_s.png',
  },
  {
    key: 'by-a-body-of-water',
    label: formattedMessage('Vicinity.ByABodyOfWater'),
    icon:
      'https://dih6yo2fd8n78.cloudfront.net/listing-icons/10+SURROUNDING/7+CAMPING+AT+A+POND_s.png',
  },
  {
    key: 'by-a-lake',
    label: formattedMessage('Vicinity.ByALake'),
    icon:
      'https://dih6yo2fd8n78.cloudfront.net/listing-icons/10+SURROUNDING/4+CAMPING+AT+LAKE_s.png',
  },
  {
    key: 'by-a-pond',
    label: formattedMessage('Vicinity.ByAPond'),
    icon:
      'https://dih6yo2fd8n78.cloudfront.net/listing-icons/10+SURROUNDING/7+CAMPING+AT+A+POND_s.png',
  },
  {
    key: 'on-a-field',
    label: formattedMessage('Vicinity.OnAField'),
    icon:
      'https://dih6yo2fd8n78.cloudfront.net/listing-icons/10+SURROUNDING/8+CAMPING+IN+A+FIELD_s.png',
  },
  {
    key: 'on-a-private-road',
    label: formattedMessage('Vicinity.OnAPrivateRoad'),
    icon:
      'https://dih6yo2fd8n78.cloudfront.net/listing-icons/10+SURROUNDING/1+CAMPING+AT+PRIVAT+WAY_s.png',
  },
  {
    key: 'on-a-pasture',
    label: formattedMessage('Vicinity.OnAPasture'),
    icon: 'https://dih6yo2fd8n78.cloudfront.net/listing-icons/10+SURROUNDING/Weide_s.png',
  },
  {
    key: 'in-a-courtyard',
    label: formattedMessage('Vicinity.InACourtyard'),
    icon:
      'https://dih6yo2fd8n78.cloudfront.net/listing-icons/10+SURROUNDING/3+CAMPING+IN+HALF+TIMBERED+HOUSE+PATIO_s.png',
  },
  {
    key: 'in-a-forest-area',
    label: formattedMessage('Vicinity.InAForestArea'),
    icon:
      'https://dih6yo2fd8n78.cloudfront.net/listing-icons/10+SURROUNDING/5+CAMPING+AT+THE+FOREST_s.png',
  },
  {
    key: 'by-the-sea',
    label: formattedMessage('Vicinity.ByTheSea'),
    icon: 'https://dih6yo2fd8n78.cloudfront.net/Camping-pitch-by-the-sea+(4).png',
  },
  {
    key: 'on-the-farm',
    label: formattedMessage('Vicinity.OnTheFarm'),
    icon: 'https://dih6yo2fd8n78.cloudfront.net/Camping-pitch-on-a-farm+(3).png',
  },
  {
    key: 'in-a-garden',
    label: formattedMessage('Vicinity.InAGarden'),
    icon: 'https://dih6yo2fd8n78.cloudfront.net/Camping-pitch-in-a-garden+(2).png',
  },
  {
    key: 'on-a-street',
    label: formattedMessage('Vicinity.OnAStreet'),
    icon: 'https://dih6yo2fd8n78.cloudfront.net/Camping-pitch-on-a-road+(1).png',
  },
  {
    key: 'in-the-village',
    label: formattedMessage('Vicinity.InTheVillage'),
    icon: 'https://dih6yo2fd8n78.cloudfront.net/Camping-pitch-in-the-village+(3).png',
  },
];

export const roommateOptions = [
  {
    key: 'alpacas',
    label: formattedMessage('Roommates.Alpacas'),
    icon: 'https://dih6yo2fd8n78.cloudfront.net/listing-icons/03+ANIMAL/8+ALPACA_s.png',
  },
  {
    key: 'ducks',
    label: formattedMessage('Roommates.Ducks'),
    icon: 'https://dih6yo2fd8n78.cloudfront.net/listing-icons/03+ANIMAL/11+DUCK_s.png',
  },
  {
    key: 'donkeys',
    label: formattedMessage('Roommates.Donkeys'),
    icon: 'https://dih6yo2fd8n78.cloudfront.net/listing-icons/Extras/Donkey_s.png',
  },
  {
    key: 'chickens',
    label: formattedMessage('Roommates.Chickens'),
    icon: 'https://dih6yo2fd8n78.cloudfront.net/listing-icons/03+ANIMAL/4+CHIKEN+REV+1_s.png',
  },
  {
    key: 'dogs',
    label: formattedMessage('Roommates.Dogs'),
    icon: 'https://dih6yo2fd8n78.cloudfront.net/listing-icons/03+ANIMAL/5+DOG_s.png',
  },
  {
    key: 'cats',
    label: formattedMessage('Roommates.Cats'),
    icon: 'https://dih6yo2fd8n78.cloudfront.net/listing-icons/03+ANIMAL/6+CAT_s.png',
  },
  {
    key: 'cows',
    label: formattedMessage('Roommates.Cows'),
    icon: 'https://dih6yo2fd8n78.cloudfront.net/listing-icons/03+ANIMAL/2+COW+REV+1_s.png',
  },
  {
    key: 'peafowls',
    label: formattedMessage('Roommates.Peafowls'),
    icon: 'https://dih6yo2fd8n78.cloudfront.net/listing-icons/03+ANIMAL/10+PEACOOK_s.png',
  },
  {
    key: 'horses',
    label: formattedMessage('Roommates.Horses'),
    icon: 'https://dih6yo2fd8n78.cloudfront.net/listing-icons/03+ANIMAL/1+HORSE+REV+1_s.png',
  },
  {
    key: 'sheep',
    label: formattedMessage('Roommates.Sheep'),
    icon: 'https://dih6yo2fd8n78.cloudfront.net/listing-icons/03+ANIMAL/7+SHEEP+REV+1_s.png',
  },
  {
    key: 'pigs',
    label: formattedMessage('Roommates.Pigs'),
    icon: 'https://dih6yo2fd8n78.cloudfront.net/listing-icons/03+ANIMAL/3+PIG+REV+1_s.png',
  },
  {
    key: 'goats',
    label: formattedMessage('Roommates.Goats'),
    icon: 'https://dih6yo2fd8n78.cloudfront.net/listing-icons/03+ANIMAL/9+GOAT_s.png',
  },
];

export const activitiesOptions = [
  {
    key: 'fishing',
    label: formattedMessage('Activities.Fishing'),
    icon: 'https://dih6yo2fd8n78.cloudfront.net/listing-icons/01+ACTIVITIES/4+FISHING_s.png',
  },
  {
    key: 'boating',
    label: formattedMessage('Activities.Boating'),
    icon: 'https://dih6yo2fd8n78.cloudfront.net/listing-icons/01+ACTIVITIES/8+BOAT+REV+1_s.png',
  },
  {
    key: 'canoeing',
    label: formattedMessage('Activities.Canoeing'),
    icon: 'https://dih6yo2fd8n78.cloudfront.net/listing-icons/01+ACTIVITIES/5+CANOE+REV+1_s.png',
  },
  {
    key: 'carriage-tours',
    label: formattedMessage('Activities.CarriageTours'),
    icon:
      'https://dih6yo2fd8n78.cloudfront.net/listing-icons/01+ACTIVITIES/2+CARRIAGE+TOUR+REV+3_s.png',
  },
  {
    key: 'horseback-riding',
    label: formattedMessage('Activities.HorsebackRiding'),
    icon:
      'https://dih6yo2fd8n78.cloudfront.net/listing-icons/01+ACTIVITIES/6+HORSE+RIDING+REV+1_s.png',
  },
  {
    key: 'swimming',
    label: formattedMessage('Activities.Swimming'),
    icon: 'https://dih6yo2fd8n78.cloudfront.net/listing-icons/01+ACTIVITIES/3+SWIMMING+REV+3_s.png',
  },
  {
    key: 'petting-animals',
    label: formattedMessage('Activities.PettingAnimals'),
    icon: 'https://dih6yo2fd8n78.cloudfront.net/listing-icons/01+ACTIVITIES/Tiere+streicheln_s.png',
  },
  {
    key: 'surfing',
    label: formattedMessage('Activities.Surfing'),
    icon: 'https://dih6yo2fd8n78.cloudfront.net/listing-icons/Extras/Surfing_s.png',
  },
  {
    key: 'diving',
    label: formattedMessage('Activities.Diving'),
    icon: 'https://dih6yo2fd8n78.cloudfront.net/listing-icons/Extras/Diving_s.png',
  },
  {
    key: 'climbing',
    label: formattedMessage('Activities.Climbing'),
    icon: 'https://dih6yo2fd8n78.cloudfront.net/listing-icons/Extras/Climbing_s.png',
  },
  {
    key: 'hiking',
    label: formattedMessage('Activities.Hiking'),
    icon: 'https://dih6yo2fd8n78.cloudfront.net/listing-icons/Extras/Hiking_s.png',
  },
  {
    key: 'mountain-biking',
    label: formattedMessage('Activities.MountainBiking'),
    icon: 'https://dih6yo2fd8n78.cloudfront.net/listing-icons/Extras/Mountainbiking_s.png',
  },
  {
    key: 'cycling',
    label: formattedMessage('Activities.Cycling'),
    icon: 'https://dih6yo2fd8n78.cloudfront.net/listing-icons/Extras/Baycicle_s.png',
  },
];

export const instantBookingOptions = [
  { key: 'yes', label: formattedMessage('Booking.yes') },
  { key: 'no', label: formattedMessage('Booking.no') },
];

const generateOvernightStaysOptions = numOfNights => {
  const options = [];
  for (let i = 1; i <= numOfNights; i++) {
    const nightLabel = i === 1 ? 'night' : 'nights';
    options.push({
      key: `${i}-${nightLabel}`,
      label: formattedMessage(`overnightStays.${i}${nightLabel}`),
    });
  }
  return options;
};

export const overnightStaysOptions = generateOvernightStaysOptions(21);

export const hostSourceOptions = [
  { key: 'received-email-from-you-and-a-view', label: formattedMessage('HostSource.email') },
  { key: 'flyer', label: formattedMessage('HostSource.flyer') },
  { key: 'friends-and-acquaintances', label: formattedMessage('HostSource.friends') },
  { key: 'google-search', label: formattedMessage('HostSource.google') },
  { key: 'social-media', label: formattedMessage('HostSource.socialMedia') },
  { key: 'other', label: formattedMessage('HostSource.other') },
  { key: 'tv', label: formattedMessage('HostSource.tv') },
];

export const essentialsOptions = [
  {
    key: 'toilet',
    label: formattedMessage('Essentials.Toilet'),
    icon: 'https://dih6yo2fd8n78.cloudfront.net/listing-icons/06+EQUPMENT/16+TOILET_s.png',
  },
  {
    key: 'electricity',
    label: formattedMessage('Essentials.Electricity'),
    icon: 'https://dih6yo2fd8n78.cloudfront.net/listing-icons/06+EQUPMENT/14+POWER_s.png',
  },
  {
    key: 'shower',
    label: formattedMessage('Essentials.Shower'),
    icon: 'https://dih6yo2fd8n78.cloudfront.net/listing-icons/06+EQUPMENT/15+SHOWER_s.png',
  },
  {
    key: 'water-hookup',
    label: formattedMessage('Essentials.WaterHookup'),
    icon:
      'https://dih6yo2fd8n78.cloudfront.net/listing-icons/06+EQUPMENT/7+drinking+wateR+REV+1_s.png',
  },
  {
    key: 'wifi',
    label: formattedMessage('Essentials.WiFi'),
    icon: 'https://dih6yo2fd8n78.cloudfront.net/listing-icons/06+EQUPMENT/wifi_s.png',
  },
  {
    key: 'fire-pit',
    label: formattedMessage('Essentials.FirePit'),
    icon: 'https://dih6yo2fd8n78.cloudfront.net/listing-icons/Extras/5+FIREPLACE_s.png',
  },
  {
    key: 'mobile-reception',
    label: formattedMessage('Essentials.MobileReception'),
    icon: 'https://dih6yo2fd8n78.cloudfront.net/listing-icons/Extras/Mobile-Reception_s.png',
  },
  {
    key: 'greywater-disposal',
    label: formattedMessage('Essentials.GrayWaterDisposal'),
    icon:
      'https://dih6yo2fd8n78.cloudfront.net/listing-icons/06+EQUPMENT/1+GRAY+WATER+DISPOSAL+REV+2_s.png',
  },
  {
    key: 'daily-waste-disposal',
    label: formattedMessage('Essentials.DailyWasteDisposal'),
    icon: 'https://dih6yo2fd8n78.cloudfront.net/listing-icons/06+EQUPMENT/9+GARBAGE+DISPOSAL_s.png',
  },
];

export const categoryTranslations = {
  en: {
    'Nature campsite': 'Nature campsite',
    'Private Pitch': 'Private Pitch',
    'Camper Parking Area': 'Camper Parking Area',
    'Community Parking Area': 'Community Parking Area',
  },
  es: {
    'Nature campsite': 'Área de campamento en la naturaleza',
    'Private Pitch': 'Parcela privada',
    'Camper Parking Area': 'Área de estacionamiento para autocaravanas',
    'Community Parking Area': 'Área de estacionamiento comunitaria',
  },
  de: {
    'Nature campsite': 'Naturcampingplatz',
    'Private Pitch': 'Privater Stellplatz',
    'Camper Parking Area': 'Wohnmobilparkplatz',
    'Community Parking Area': 'Gemeinde Stellplatz',
  },
  pt: {
    'Nature campsite': 'Parque de campismo na natureza',
    'Private Pitch': 'Parcela privada',
    'Camper Parking Area': 'Área de estacionamento para campistas',
    'Community Parking Area': 'Área de estacionamento comunitária',
  },
  it: {
    'Nature campsite': 'Campeggio nella natura',
    'Private Pitch': 'Piazzola privata',
    'Camper Parking Area': 'Area parcheggio camper',
    'Community Parking Area': 'Area parcheggio comunitaria',
  },
  fr: {
    'Nature campsite': 'Camping en pleine nature',
    'Private Pitch': 'Emplacement privé',
    'Camper Parking Area': 'Zone de stationnement pour camping-cars',
    'Community Parking Area': 'Zone de stationnement communautaire',
  },
  nl: {
    'Nature campsite': 'Natuurcamping',
    'Private Pitch': 'Privé plek',
    'Camper Parking Area': 'Camper parkeerplaats',
    'Community Parking Area': 'Gemeenschappelijke parkeerplaats',
  },
};

export const topbarFilterPopularSearches = [
  {
    key: 'germany',
    label: formattedMessage('TopbarFilterPopularSearches.germany'),
    search: 'address=Germany&bounds=55.1286491%2C15.041832%2C47.270238%2C5.866315',
  },
  {
    key: 'baltic',
    label: formattedMessage('TopbarFilterPopularSearches.baltic'),
    search: 'bounds=57.9501083%2C25.48059165%2C53.97740586%2C15.37425316&mapSearch=true',
  },
  {
    key: 'north-sea',
    label: formattedMessage('TopbarFilterPopularSearches.northSea'),
    search: 'bounds=57.54659295%2C10.49338969%2C53.83522703%2C-4.39348891&mapSearch=true',
  },
  {
    key: 'bavaria',
    label: formattedMessage('TopbarFilterPopularSearches.bavaria'),
    search: 'address=Bavaria%2C%20Germany&bounds=50.564694%2C13.839619%2C47.270238%2C8.97636',
  },
  {
    key: 'lower-saxony',
    label: formattedMessage('TopbarFilterPopularSearches.lowerSaxony'),
    search:
      'address=Lower%20Saxony%2C%20Germany&bounds=53.9560855%2C11.598273%2C51.295231%2C6.5917969',
  },
  {
    key: 'mecklenburg-western-pomerania',
    label: formattedMessage('TopbarFilterPopularSearches.pomerania'),
    search:
      'address=Mecklenburg-Western%20Pomerania%2C%20Germany&bounds=54.7424682%2C14.412145%2C53.110355%2C10.593613',
  },
];

export const listingTypes = [
  {
    label: formattedMessage('ListingTypes.campsite'),
    key: 'nature-campsite',
  },
  {
    label: formattedMessage('ListingTypes.private'),
    key: 'private-pitch',
  },
  {
    label: formattedMessage('ListingTypes.camper'),
    key: 'camper-parking-area',
  },
  {
    label: formattedMessage('ListingTypes.parkingArea'),
    key: 'community-parking-area',
  },
];

export const popularListings = [
  {
    name: 'Beestermöller-Möllenkamp',
    id: '63e028e4-e40e-44bf-a03c-641267dae1f0',
  },
  {
    name: 'Wiese an der Kanu-Mühle Wesenberg',
    id: '64be282c-9d15-4b05-a088-59b9ee6afc79',
  },
  {
    name: 'Weyererhof',
    id: '6409708e-1635-4dce-b7f1-bed4ddaeb2d8',
  },
  {
    name: 'Obsthof Moll',
    id: '63e01d2e-d304-4645-b664-347d052dd0e3',
  },
  {
    name: 'Minicamping Mortels Buiten',
    id: '64a6f35c-6a07-4ee5-af26-8375aac1a246',
  },
  {
    name: 'Womo Camp Cramon am See',
    id: '64b0493c-311c-4610-8d60-d58a44ceebaa',
  },
  {
    name: 'Reisemobilpark Sagter Ems',
    id: '64b78ce1-d8ad-4ae5-8a71-d0ab8feedc86',
  },
  {
    name: 'Wohnmobilstellplatz unter den Kirschbäumen',
    id: '6497cecd-2410-4c1e-9e4e-f186d1f01072',
  },
  {
    name: 'Grüne Oase Hamburgs',
    id: '642e7f45-d55f-4aa3-b69a-15f31f1ee54b',
  },
  {
    name: 'Traumhafte Lage mit Schafen, Bach und Feuerstelle',
    id: '646d0a07-acb5-4323-b1d3-15333683873e',
  },
];

export const ICONS = {
  inAForestAreaIcon: 'https://dih6yo2fd8n78.cloudfront.net/icon2.png',
};

export const flagsSeoOptions = [
  {
    label: 'Österreich',
    translations: {
      pt: 'Áustria',
      nl: 'Oostenrijk',
      en: 'Austria',
      es: 'Austria',
      de: 'Österreich',
      it: 'Austria',
    },
    flag: 'https://dih6yo2fd8n78.cloudfront.net/flags/Austria-min_1_11zon.jpg',
    geolocation: '?address=Austria&bounds=49.020525%2C17.160761%2C46.372312%2C9.530741',
  },
  {
    label: 'Frankreich',
    translations: {
      pt: 'França',
      nl: 'Frankrijk',
      en: 'France',
      es: 'Francia',
      de: 'Frankreich',
      it: 'Francia',
    },
    flag: 'https://dih6yo2fd8n78.cloudfront.net/flags/France-min_2_11zon.jpg',
    geolocation: '?address=France&bounds=51.1504571%2C9.6598888%2C41.3108239%2C-5.2425456',
  },
  {
    label: 'Deutschland',
    translations: {
      pt: 'Alemanha',
      nl: 'Duitsland',
      en: 'Germany',
      es: 'Alemania',
      de: 'Deutschland',
      it: 'Germania',
    },
    flag: 'https://dih6yo2fd8n78.cloudfront.net/flags/Germany-min_3_11zon.jpg',
    geolocation: '?address=Germany&bounds=55.1286491%2C15.041832%2C47.270238%2C5.866315',
  },
  {
    label: 'Italien',
    translations: {
      pt: 'Itália',
      nl: 'Italië',
      en: 'Italy',
      es: 'Italia',
      de: 'Italien',
      it: 'Italia',
    },
    flag: 'https://dih6yo2fd8n78.cloudfront.net/flags/Italy-min_4_11zon.jpg',
    geolocation: '?address=Italy&bounds=47.092146%2C18.6201843%2C35.4122073%2C6.626556',
  },
  {
    label: 'Niederlande',
    translations: {
      pt: 'Países Baixos',
      nl: 'Nederland',
      en: 'Netherlands',
      es: 'Países Bajos',
      de: 'Niederlande',
      it: 'Paesi Bassi',
    },
    flag: 'https://dih6yo2fd8n78.cloudfront.net/flags/Netherlands-min_5_11zon.jpg',
    geolocation: '?address=Netherlands&bounds=53.6392176%2C7.227499%2C50.750367%2C3.3363182',
  },
  {
    label: 'Portugal',
    translations: {
      pt: 'Portugal',
      nl: 'Portugal',
      en: 'Portugal',
      es: 'Portugal',
      de: 'Portugal',
      it: 'Portogallo',
    },
    flag: 'https://dih6yo2fd8n78.cloudfront.net/flags/Portugal-min_6_11zon.jpg',
    geolocation: '?address=Portugal&bounds=42.154276%2C-6.189158%2C29.9501178%2C-31.3687699',
  },
  {
    label: 'Spanien',
    translations: {
      pt: 'Espanha',
      nl: 'Spanje',
      en: 'Spain',
      es: 'España',
      de: 'Spanien',
      it: 'Spagna',
    },
    flag: 'https://dih6yo2fd8n78.cloudfront.net/flags/Spain-min_7_11zon.jpg',
    geolocation: '?address=Spain&bounds=43.8640692%2C4.4264828%2C27.5545087%2C-18.260243',
  },
  {
    label: 'Schweiz',
    translations: {
      pt: 'Suíça',
      nl: 'Zwitserland',
      en: 'Switzerland',
      es: 'Suiza',
      de: 'Schweiz',
      it: 'Svizzera',
    },
    flag: 'https://dih6yo2fd8n78.cloudfront.net/flags/Switzerland-min_8_11zon.jpg',
    geolocation: '?address=Switzerland&bounds=47.808454%2C10.492061%2C45.817986%2C5.955907',
  },
];

export const popularSeoCountries = [
  {
    logo: 'https://dih6yo2fd8n78.cloudfront.net/Germany SEO-min.jpg',
    title: formattedMessage('TravelTypes.Deutschland'),
    search: '?address=Germany&bounds=55.151244631%2C15.041797%2C47.270114%2C5.866315',
  },
  {
    logo: 'https://dih6yo2fd8n78.cloudfront.net/Austria+SEO-min.jpg',
    title: formattedMessage('TravelTypes.Österreich'),
    search: '?address=Austria&bounds=49.020526%2C17.160773%2C46.372314%2C9.530735',
  },
  {
    logo: 'https://dih6yo2fd8n78.cloudfront.net/Switzerland SEO-min.jpg',
    title: formattedMessage('TravelTypes.Schweiz'),
    search: '?address=Switzerland&bounds=47.808454%2C10.492062%2C45.817975%2C5.95591',
  },
  {
    logo: 'https://dih6yo2fd8n78.cloudfront.net/Belgium SEO-min.jpg',
    title: formattedMessage('TravelTypes.Belgien'),
    search: '?address=Belgium&bounds=51.505124%2C6.407905%2C49.497209%2C2.520647178',
  },
  {
    logo: 'https://dih6yo2fd8n78.cloudfront.net/Netherlands SEO-min.jpg',
    title: formattedMessage('TravelTypes.Niederlande'),
    search: '?address=Netherlands&bounds=53.632459585%2C7.227485%2C50.750353%2C3.336318218',
  },
  {
    logo: 'https://dih6yo2fd8n78.cloudfront.net/Spain SEO-min.jpg',
    title: formattedMessage('TravelTypes.Spanien'),
    search: '?address=Spain&bounds=43.85927736%2C4.424428622%2C27.554508655%2C-18.255773512',
  },
  {
    logo: 'https://dih6yo2fd8n78.cloudfront.net/Italy SEO-min.jpg',
    title: formattedMessage('TravelTypes.Italien'),
    search: '?address=Italy&bounds=47.092146%2C18.615703883%2C35.412052613%2C6.626832',
  },
  {
    logo: 'https://dih6yo2fd8n78.cloudfront.net/Portugal SEO-min.jpg',
    title: formattedMessage('TravelTypes.Portugal'),
    search: '?address=Portugal&bounds=42.154227%2C-6.189178%2C29.951210822%2C-31.34030137',
  },
];
