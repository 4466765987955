import moment from 'moment';
import { getDefaultLocale, isArrayLength } from './genericHelpers';
import { useIntl } from '../util/reactIntl';
import { vehicleTypesOptions } from './fieldsOptions';

const defaultLocale = getDefaultLocale();

export const partnerImages = [
  'https://dih6yo2fd8n78.cloudfront.net/generic-icons/chip+logo-min.webp',
  'https://dih6yo2fd8n78.cloudfront.net/generic-icons/die+welt+logo-min.webp',
  'https://dih6yo2fd8n78.cloudfront.net/generic-icons/geo+logo-min.webp',
  'https://dih6yo2fd8n78.cloudfront.net/generic-icons/reise+reporter-min.webp',
  'https://dih6yo2fd8n78.cloudfront.net/generic-icons/sat+1+logo-min.webp',
  'https://dih6yo2fd8n78.cloudfront.net/generic-icons/su%CC%88ddeutsche+zeitung+logo-min.webp',
];

export const partnerLogos = [
  'https://dih6yo2fd8n78.cloudfront.net/generic-photos/freeontour.png',
  'https://dih6yo2fd8n78.cloudfront.net/generic-photos/campelot.png',
  'https://dih6yo2fd8n78.cloudfront.net/generic-photos/brucker.png',
  'https://dih6yo2fd8n78.cloudfront.net/generic-photos/landtouristik.png',
  'https://dih6yo2fd8n78.cloudfront.net/generic-photos/natureon.png',
  'https://dih6yo2fd8n78.cloudfront.net/generic-photos/tm.png',
  'https://dih6yo2fd8n78.cloudfront.net/generic-icons/Design_ohne_Titel-removebg-preview.png',
];

export const isInstantBooking = l => {
  return l?.attributes?.publicData?.instantBooking === 'yes';
};

export function generateRandomEmailPrefix(email) {
  const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
  const prefixLength = email.substring(0, email.indexOf('@')).length;
  let randomPrefix = '';
  for (let i = 0; i < prefixLength; i++) {
    randomPrefix += characters.charAt(Math.floor(Math.random() * characters.length));
  }
  return randomPrefix;
}

export const getCustomerEmailParams = t => {
  const listing = t?.listing;
  const booking = t?.booking;
  const bookingStart = moment(booking?.attributes?.start).format('Do MMMM YYYY');
  const bookingEnd = moment(booking?.attributes?.end).format('Do MMMM YYYY');
  const payinTotal = t?.attributes?.payinTotal?.amount / 100;
  const listingTitle = listing?.attributes?.title;
  const address = listing?.attributes?.publicData?.location?.address;

  return { bookingStart, bookingEnd, payinTotal, listingTitle, address };
};

export const isTransactionInstantBooking = t => {
  return t?.attributes?.protectedData?.isInstantBooking;
};

export const isUserGuest = currentUser => {
  return currentUser?.attributes?.email?.includes('test');
};

export const isListingInfoOnly = listing => {
  return !!listing?.attributes?.publicData?.isInfoOnly;
};

export const isListingPendingApproval = listing => {
  return listing?.attributes?.state === 'pendingApproval';
};

export const shouldShowStripePayoutForInfoOnly = (listing, currentUser) => {
  return (
    listing?.attributes?.state === 'pendingApproval' && !currentUser?.attributes?.stripeConnected
  );
};

export const getTranslations = listing => {
  if (listing?.id?.uuid) {
    const publicData = listing?.attributes?.publicData || {};
    const translations = publicData?.translations?.data || {};
    const fallBackValues = {
      title: listing?.attributes?.title,
      description: listing?.attributes?.description,
      houseRules: publicData?.houseRules,
      tips: publicData?.tips,
    };
    return translations[defaultLocale.toUpperCase()] || fallBackValues;
  } else {
    return null;
  }
};

/**
 * Generates an array of image URLs using the base URL and an array of image objects.
 *
 * @param {Array} images - An array of image objects.
 * @param {string} url - The base URL for the images.
 * @returns {Array} An array of image URLs.
 * @throws {TypeError} If the `images` parameter is not an array.
 */
export const getImagesLink = (images, url) => {
  // Generate an array of image URLs using the base URL and the image object's URL attribute.
  const imageUrls =
    isArrayLength(images) &&
    images.map(image => {
      const imageUrl = image?.attributes?.url;
      return imageUrl;
    });

  // Return the array of image URLs.
  return imageUrls;
};

export const isInfoOnly = l => {
  return l?.attributes?.publicData?.isInfoOnly;
};

export const getBlogData = blog => {
  // Get the base URL from the configuration.
  const baseUrl = process.env.REACT_APP_STRAPI_BASE_URL;

  // Get the blog images and generate image URLs using the base URL.
  const blogImages = blog?.attributes?.images?.data || [];
  const imagesLink = blogImages?.length && getImagesLink(blogImages, baseUrl);

  // Get the blog title and description.
  const title = blog?.attributes?.title || '';
  const description = blog?.attributes?.description || '';
  const tags = blog?.attributes?.tags || '';
  const createdAt = blog?.attributes?.createdAt || '';

  // Return an object containing the blog title, description, and the first image URL (if available).
  return { title, description, tags, createdAt, image: imagesLink && imagesLink[0] };
};

export const extractAndFormatBoundsFromString = queryString => {
  // Extract the query string part from the URL
  const queryParamsPart = queryString.split('?')[1];

  // Use URLSearchParams to parse the query parameters
  const params = new URLSearchParams(queryParamsPart);
  const bounds = params.get('bounds');

  // Check if bounds parameter exists
  if (!bounds) {
    console.log('Bounds parameter not found in the URL');
    return null;
  }

  // Split the bounds string into individual coordinates
  const coordinates = bounds.split(',');

  // Ensure we have exactly four coordinates for NE and SW bounds
  if (coordinates.length !== 4) {
    console.log('Invalid bounds format');
    return null;
  }

  // Format the coordinates: NE lat, NE lng, SW lat, SW lng
  const formattedBounds = coordinates.map(coord => coord.trim()).join(',');

  return formattedBounds;
};

export const getLanguageKey = (languageOptions, label) => {
  return languageOptions.find(l => l?.label === label)?.key;
};
export const getVehicleLabelByKey = key =>
  (vehicleTypesOptions.find(option => option.key === key) || {}).label || 'Label not found';
