import React, { useState, useContext, useEffect } from 'react';

// First create the context
const MyContext = React.createContext();

// Then create a StateHolder wrapper component
// to hold the state that the components need.

const StateHolder = props => {
  const [locationPredictions, setLocationPredictions] = useState(null);
  const [openGlobalFilterModal, setGlobalFilterModal] = useState(false);
  const [filterModalContent, setFilterModalContent] = useState('location');
  const [globalFilterParams, setGlobalFilterParams] = useState(null);
  const [closeGlobalModalForcefully, setCloseGlobalModalForcefully] = useState(false);
  const [showTopbarFilter, setShowTopbarFilter] = useState(true);

  return (
    <MyContext.Provider
      value={{
        locationPredictions,
        setLocationPredictions,
        openGlobalFilterModal,
        setGlobalFilterModal,
        globalFilterParams,
        setGlobalFilterParams,
        closeGlobalModalForcefully,
        setCloseGlobalModalForcefully,
        filterModalContent,
        setFilterModalContent,
        showTopbarFilter,
        setShowTopbarFilter,
      }}
    >
      {props.children}
    </MyContext.Provider>
  );
};

export const useMyContext = () => useContext(MyContext);

export default StateHolder;
