import React, { useEffect, useState } from 'react';
import { string, func, bool } from 'prop-types';
import classNames from 'classnames';
import { intlShape, injectIntl } from '../../util/reactIntl';
import { propTypes } from '../../util/types';
import { formatMoney } from '../../util/currency';
import { ensureListing } from '../../util/data';
import { richText } from '../../util/richText';
import { createSlug } from '../../util/urlHelpers';
import { Icons } from '../../components';
import {
  calculateTotalWithMinimum,
  extractZipAndCity,
  getProductImages,
  getWishlist,
  isArrayLength,
} from '../../util/genericHelpers';
import { createResourceLocatorString } from '../../util/routes';
import routeConfiguration from '../../routing/routeConfiguration';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import { types as sdkTypes } from '../../util/sdkLoader';
import { findFlagUrlByCountryName } from 'country-flags-svg';
import ImageSlider from '../ImageSlider/ImageSlider';
import { vehicleTypesOptions } from '../../util/fieldsOptions';
import { selectCurrentUser } from '../../ducks/user.duck';
import { useDispatch, useSelector } from 'react-redux';
import { selectIsAuthenticated } from '../../ducks/auth.duck';
import { updateProfile } from '../../containers/ProfileSettingsPage/ProfileSettingsPage.duck';

import css from './LandingListingCard.module.css';
import { isInfoOnly } from '../../util/dataExtractors';

function transformToObject(obj) {
  // Create a new object to store the transformed result
  const transformedObj = {};

  // Check if obj has properties
  if (obj && Object.keys(obj).length > 0) {
    // Check if the obj has an 'address' property
    if (obj.hasOwnProperty('address')) {
      // Split the address into parts
      const addressParts = obj.address.split(', ');

      // Extract relevant parts and assign them to the transformed object
      transformedObj['place'] = obj.address;
      transformedObj['city'] = addressParts[1];
      transformedObj['country'] = addressParts[addressParts.length - 1];

      // Check if the obj has a 'building' property
      if (obj.hasOwnProperty('building')) {
        transformedObj['building'] = obj.building;
      }

      // Check if the obj has a 'zip' property
      if (obj.hasOwnProperty('zip')) {
        transformedObj['zip'] = obj.zip;
      }
    }
  }

  return transformedObj;
}

const MIN_LENGTH_FOR_LONG_WORDS = 10;
const { Money } = sdkTypes;
const isWindowDefined = typeof window !== 'undefined';
const isMobileLayout = isWindowDefined && window.innerWidth < 768;

export const LandingListingCardComponent = props => {
  const history = useHistory();
  const { className, isFavourites, rootClassName, intl, listing, isMobileSection } = props;

  const classes = classNames(
    rootClassName || css.root,
    className,
    isMobileSection ? css.mobileSection : null
  );
  const currentListing = ensureListing(listing);
  const dispatch = useDispatch();
  const id = currentListing?.id?.uuid;
  const { title = '', price, geolocation, publicData } = currentListing.attributes || {};

  const totalPriceOrderWithCommission =
    calculateTotalWithMinimum(price?.amount / 100, Number(1)) * 1;
  const priceWithCommission = totalPriceOrderWithCommission
    ? new Money(totalPriceOrderWithCommission * 100, 'EUR')
    : price;

  const slug = createSlug(title);

  const address = publicData?.location?.address || '';
  const country = publicData?.country || (address && address?.split(' ')?.pop());
  const addressDetails = extractZipAndCity(address);
  const locationDetails =
    publicData?.locationDetails || (address ? transformToObject(publicData?.location) : '');

  const images = getProductImages(currentListing.images);
  const selectedTravelTypes = isArrayLength(publicData?.vehicleTypes)
    ? vehicleTypesOptions.filter(v => publicData?.vehicleTypes.includes(v.key))
    : null;

  const currentUser = useSelector(selectCurrentUser);
  const isAuthenticated = useSelector(selectIsAuthenticated);
  const wishlist = getWishlist(currentUser);

  const onUpdateProfile = params => {
    return dispatch(updateProfile(params));
  };

  const onUpdateCurrentUser = array => {
    const params = { wishlist: Array.from(new Set(array)) };

    return onUpdateProfile({
      publicData: { ...params },
    });
  };

  const isInfoListing = isInfoOnly(listing);

  return (
    <div className={classes}>
      {isArrayLength(images) ? (
        <ImageSlider
          images={images}
          isMobile={isMobileLayout}
          imgAlt={''}
          pathParams={{ id, slug }}
          pushToListingPage={() => {
            history.push(
              createResourceLocatorString('ListingPage', routeConfiguration(), { id, slug }, {})
            );
          }}
        />
      ) : null}
      {!isInfoListing && (
        <div className={css.imgPills}>
          <span>{intl.formatMessage({ id: 'LandingListingCard.adsBanner' })}</span>
        </div>
      )}
      {isFavourites ? (
        <div
          className={css.favorite}
          onClick={e => {
            e.preventDefault();
            e.stopPropagation();
            if (isAuthenticated) {
              const index = wishlist && wishlist.findIndex(b => b == id);
              if (id) {
                if (index > -1) {
                  wishlist && wishlist.splice(index, 1);
                  const removedwishlist = Array.from(new Set(wishlist));
                  onUpdateCurrentUser(removedwishlist);
                } else {
                  wishlist.push(id);
                  onUpdateCurrentUser(Array.from(new Set(wishlist)));
                }
              }
            } else {
              history.push(createResourceLocatorString('LoginPage', routeConfiguration, {}, {}));
            }
          }}
        >
          {isArrayLength(wishlist) && wishlist.findIndex(e => e == id) > -1 ? (
            <Icons name="favorite" />
          ) : (
            <Icons name="unfavorite" />
          )}
        </div>
      ) : null}
      <div
        onClick={() => {
          history.push(
            createResourceLocatorString('ListingPage', routeConfiguration(), { id, slug }, {})
          );
        }}
        className={css.info}
      >
        <div className={css.mainInfo}>
          <div className={css.title}>
            {richText(title, {
              longWordMinLength: MIN_LENGTH_FOR_LONG_WORDS,
              longWordClass: css.longWord,
            })}
          </div>
          <div className={css.flag}>
            <img src={findFlagUrlByCountryName(country)} alt={country} />
          </div>
        </div>
        <div className={css.priceSec}>
          {locationDetails ? (
            <p>
              {locationDetails?.city}, {locationDetails?.zip}, {locationDetails?.country}{' '}
            </p>
          ) : (
            publicData?.locationDetails?.place
          )}
          {!isInfoListing && (
            <span className={css.price}>
              {' '}
              {priceWithCommission ? formatMoney(intl, priceWithCommission) : null}
            </span>
          )}
        </div>
        {isInfoListing ? (
          <div className={css.pills}>
            <span>{intl.formatMessage({ id: 'LandingListingCard.infoOnly' })}</span>
          </div>
        ) : (
          <div className={css.amenities}>
            {isArrayLength(selectedTravelTypes)
              ? selectedTravelTypes.map((tt, i) => <img key={i} src={tt?.icon} />)
              : null}
          </div>
        )}
      </div>
    </div>
  );
};

LandingListingCardComponent.defaultProps = {
  className: null,
  rootClassName: null,
  renderSizes: null,
  setActiveListing: null,
  showAuthorInfo: true,
};

LandingListingCardComponent.propTypes = {
  className: string,
  rootClassName: string,
  intl: intlShape.isRequired,
  listing: propTypes.listing.isRequired,
  showAuthorInfo: bool,

  // Responsive image sizes hint
  renderSizes: string,

  setActiveListing: func,
};

export default injectIntl(LandingListingCardComponent);
