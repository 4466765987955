import React from 'react';
import css from './CategoryPage.module.css';
import { createResourceLocatorString } from '../../util/routes';
import routeConfiguration from '../../routing/routeConfiguration';
import { createSlugLowerCase } from '../../util/genericHelpers';
import { Icons } from '../../components';
import classNames from 'classnames';

function RenderBreadcrumbs({ history, currentLocation, isCountry, isState, isCity }) {
  const onRedirect = (path, params) => {
    history.push(createResourceLocatorString(path, routeConfiguration(), params, {}));
  };

  const getCountry = () =>
    isCountry && !isState ? currentLocation?.title : currentLocation?.country?.name;
  const getState = () =>
    isState && isCountry && !isCity ? currentLocation?.title : currentLocation?.state?.name;
  const getCity = () => currentLocation?.title;

  const country = getCountry();
  const state = getState();
  const city = getCity();

  return (
    <div className={css.breadcrumb}>
      <span
        className={classNames({
          [css.activeBreadcrumb]: !isState,
        })}
        onClick={() => onRedirect('CountryCategoryPage', { country: createSlugLowerCase(country) })}
      >
        {country}
      </span>
      {isState && (
        <>
          <Icons name="rightAngle" />
          <span
            className={classNames({
              [css.activeBreadcrumb]: !isCity,
            })}
            onClick={() =>
              onRedirect('StateCategoryPage', {
                country: createSlugLowerCase(country),
                state: createSlugLowerCase(state),
              })
            }
          >
            {state}
          </span>
        </>
      )}
      {isCity && (
        <>
          <Icons name="rightAngle" />
          <span
            className={classNames({
              [css.activeBreadcrumb]: isCity,
            })}
            onClick={() =>
              onRedirect('CityCategoryPage', {
                country: createSlugLowerCase(country),
                state: createSlugLowerCase(state),
                city: createSlugLowerCase(city),
              })
            }
          >
            {city}
          </span>
        </>
      )}
    </div>
  );
}

export default RenderBreadcrumbs;
