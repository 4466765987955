import React from 'react';
import { func } from 'prop-types';
import { requiredFieldArrayCheckbox } from '../../../util/validators';
import { FieldCheckboxGroup } from '../../../components';
import { FormattedMessage, intlShape } from '../../../util/reactIntl';

import css from './TermsAndConditions.module.css';

const TermsAndConditions = props => {
  const { intl } = props;

  const termsLink = (
    <a className={css.termsLink} target="_blank" href="/terms-of-service">
      <FormattedMessage id="AuthenticationPage.termsAndConditionsTermsLinkText" />
    </a>
  );

  const privacyLink = (
    <a className={css.privacyLink} target="_blank" href="/data-protection">
      <FormattedMessage id="AuthenticationPage.termsAndConditionsPrivacyLinkText" />
    </a>
  );

  return (
    <div className={css.root}>
      <FieldCheckboxGroup
        name="terms"
        id="terms"
        optionLabelClassName={css.finePrint}
        options={[
          {
            key: 'tos-and-privacy',
            label: intl.formatMessage(
              { id: 'AuthenticationPage.termsAndConditionsAcceptText' },
              { termsLink, privacyLink }
            ),
          },
        ]}
        validate={requiredFieldArrayCheckbox(
          intl.formatMessage({ id: 'AuthenticationPage.termsAndConditionsAcceptRequired' })
        )}
      />
    </div>
  );
};

TermsAndConditions.propTypes = {
  intl: intlShape.isRequired,
};

export default TermsAndConditions;
