import React, { useState, useEffect } from 'react';
import { bool, func, object, number, string } from 'prop-types';
import classNames from 'classnames';
import { FormattedMessage, intlShape } from '../../../util/reactIntl';
import { ACCOUNT_SETTINGS_PAGES } from '../../../routing/routeConfiguration';
import { propTypes } from '../../../util/types';
import {
  Avatar,
  InlineTextButton,
  LinkedLogo,
  Menu,
  MenuLabel,
  MenuContent,
  MenuItem,
  NamedLink,
  LanguageSwitcher,
} from '../../../components';
import TopbarSearchForm from '../TopbarSearchForm/TopbarSearchForm';
import {
  capitalizeFirstLetter,
  isArrayLength,
  sortArrayByLabel,
} from '../../../util/genericHelpers';

import css from './TopbarDesktop.module.css';
import { useMyContextFunctions } from '../../../context/ContextFunctions';

const BACKGROUND_COLOR = '#f6fde8';
const BORDER_COLOR = 'hsl(240 3% 90%)';
const BORDER_COLOR_LIGHT = 'hsl(240 3% 80%)';

const colourStyles = {
  control: (styles, { data, isDisabled, isFocused, isSelected }) => {
    return {
      ...styles,
      backgroundColor: BACKGROUND_COLOR,
      border: `solid 1px ${BORDER_COLOR}`,
      boxShadow: 'none',
      borderRadius: '100px',
      Height: 40,
      '&:hover': {
        border: isFocused ? `solid 1px ${BORDER_COLOR}` : `solid 1px ${BORDER_COLOR_LIGHT}`,
      },
    };
  },
  placeholder: base => ({
    ...base,
    position: 'relative',
    bottom: '0px',
    padding: '0',
    margin: '0',
  }),
  valueContainer: base => ({
    ...base,
    maxHeight: 40,
    padding: '0 10px',
    margin: '0',
  }),
  option: (styles, { data, isDisabled, isFocused, isSelected }) => {
    return {
      ...styles,
      backgroundColor: isDisabled ? BACKGROUND_COLOR : BACKGROUND_COLOR,
      color: '#313135',
      fontSize: '15px',
      lineHeight: '100%',
      padding: '6px 10px',
      cursor: isDisabled ? 'not-allowed' : 'default',
      '&:hover': {
        backgroundColor: '#3d6329',
        color: '#fff',
        cursor: 'pointer',
      },
    };
  },
  Input: base => ({ ...base, margin: '0px', padding: '0px', maxHeight: 45 }),
  menu: base => ({
    ...base,
    backgroundColor: BACKGROUND_COLOR,
  }),
};

const TopbarDesktop = props => {
  const {
    className,
    appConfig,
    currentUser,
    currentPage,
    rootClassName,
    currentUserHasListings,
    notificationCount,
    intl,
    isAuthenticated,
    onLogout,
    onSearchSubmit,
    initialSearchFormValues,
    seoPages,
    history,
    isGuest,
    hideSearchBar,
  } = props;

  const { onSetGlobalFilterModal } = useMyContextFunctions();

  const [mounted, setMounted] = useState(false);

  useEffect(() => {
    setMounted(true);
  }, []);

  const marketplaceName = appConfig.marketplaceName;
  const authenticatedOnClientSide = mounted && isAuthenticated;
  const isAuthenticatedOrJustHydrated = isAuthenticated || !mounted;

  const classes = classNames(rootClassName || css.root, className);

  const search = (
    <TopbarSearchForm
      className={css.searchLink}
      desktopInputRoot={css.topbarSearchWithLeftPadding}
      onSubmit={onSearchSubmit}
      initialValues={initialSearchFormValues}
      intl={intl}
      appConfig={appConfig}
      hideSearchIcon
    />
  );

  const notificationDot = notificationCount > 0 ? <div className={css.notificationDot} /> : null;

  const inboxLink = authenticatedOnClientSide ? (
    <NamedLink
      className={css.inboxLink}
      name="InboxPage"
      params={{ tab: currentUserHasListings ? 'sales' : 'orders' }}
    >
      <span className={css.inbox}>
        <FormattedMessage id="TopbarDesktop.inbox" />
        {notificationDot}
      </span>
    </NamedLink>
  ) : null;

  const currentPageClass = page => {
    const isAccountSettingsPage =
      page === 'AccountSettingsPage' && ACCOUNT_SETTINGS_PAGES.includes(currentPage);
    return currentPage === page || isAccountSettingsPage ? css.currentPage : null;
  };

  const profileMenu = authenticatedOnClientSide ? (
    <Menu onToggleFromParent={() => onSetGlobalFilterModal(false, null)}>
      <MenuLabel className={css.profileMenuLabel} isOpenClassName={css.profileMenuIsOpen}>
        <Avatar className={css.avatar} user={currentUser} disableProfileLink />
      </MenuLabel>
      <MenuContent className={css.profileMenuContent}>
        <MenuItem key="ManageListingsPage">
          <NamedLink
            className={classNames(css.yourListingsLink, currentPageClass('ManageListingsPage'))}
            name="ManageListingsPage"
          >
            <span className={css.menuItemBorder} />
            <FormattedMessage id="TopbarDesktop.yourListingsLink" />
          </NamedLink>
        </MenuItem>
        <MenuItem key="InboxPage">
          <NamedLink
            className={classNames(css.yourListingsLink, currentPageClass('InboxPage'))}
            name="InboxPage"
            params={{ tab: currentUserHasListings ? 'sales' : 'orders' }}
          >
            <span className={css.menuItemBorder} />
            <FormattedMessage id="TopbarDesktop.inbox" />
            {notificationDot}
          </NamedLink>
        </MenuItem>
        <MenuItem key="FavouritesPage">
          <NamedLink
            className={classNames(css.profileSettingsLink, currentPageClass('FavouritesPage'))}
            name="FavouritesPage"
          >
            <span className={css.menuItemBorder} />
            <FormattedMessage id="TopbarDesktop.favouritesPageLink" />
          </NamedLink>
        </MenuItem>
        <MenuItem key="ProfileSettingsPage">
          <NamedLink
            className={classNames(css.profileSettingsLink, currentPageClass('ProfileSettingsPage'))}
            name="ProfileSettingsPage"
          >
            <span className={css.menuItemBorder} />
            <FormattedMessage id="TopbarDesktop.profileSettingsLink" />
          </NamedLink>
        </MenuItem>
        <MenuItem key="AccountSettingsPage">
          <NamedLink
            className={classNames(css.yourListingsLink, currentPageClass('AccountSettingsPage'))}
            name="AccountSettingsPage"
          >
            <span className={css.menuItemBorder} />
            <FormattedMessage id="TopbarDesktop.accountSettingsLink" />
          </NamedLink>
        </MenuItem>
        <MenuItem key="logout">
          <InlineTextButton rootClassName={css.logoutButton} onClick={onLogout}>
            <span className={css.menuItemBorder} />
            <FormattedMessage id="TopbarDesktop.logout" />
          </InlineTextButton>
        </MenuItem>
      </MenuContent>
    </Menu>
  ) : null;

  const signupLink = isAuthenticatedOrJustHydrated ? null : (
    <NamedLink name="SignupPage" className={css.navLink}>
      <FormattedMessage id="TopbarDesktop.signup" />
    </NamedLink>
  );

  const becomeHost = isAuthenticatedOrJustHydrated ? null : (
    <NamedLink name="BecomeAHostPage" className={css.navLink}>
      <FormattedMessage id="TopbarDesktop.becomeHost" />
    </NamedLink>
  );

  const loginLink = isAuthenticatedOrJustHydrated ? null : (
    <NamedLink name="LoginPage" className={css.navLink}>
      <FormattedMessage id="TopbarDesktop.login" />
    </NamedLink>
  );

  const createListingLink = (
    <NamedLink className={css.createListingLink} name="NewListingPage">
      <FormattedMessage id="TopbarDesktop.createListing" />
    </NamedLink>
  );

  const blogPageLink = (
    <NamedLink className={css.navLink} name="BlogPage">
      <FormattedMessage id="TopbarDesktop.blogPage" />
    </NamedLink>
  );

  const linkedLogo = (
    <LinkedLogo
      className={css.logoLink}
      layout="desktop"
      alt={intl.formatMessage({ id: 'TopbarDesktop.logo' }, { marketplaceName })}
    />
  );

  const onCategoryChange = value => {
    history.push(`/camping/${value}`);
  };

  const seoOptions =
    isArrayLength(seoPages) &&
    seoPages?.map(s => {
      return {
        value: s?.attributes?.title,
        label: capitalizeFirstLetter(s?.attributes?.title),
      };
    });

  return (
    <nav className={classes}>
      {linkedLogo}
      {hideSearchBar ? null : <div className={css.topbarSearch}>{search}</div>}
      <div className={css.desktopLinks} onClick={() => onSetGlobalFilterModal(false, null)}>
        <LanguageSwitcher />
        {isGuest ? null : createListingLink}
        {isArrayLength(sortArrayByLabel(seoOptions)) && (
          <div className={css.seoOptions}>
            <select
              name="seoOptions"
              id="seoOptions"
              onChange={e => onCategoryChange(e.target.value)}
            >
              <option value="">{intl.formatMessage({ id: 'TopbarDesktop.selectSeoPage' })}</option>
              {sortArrayByLabel(seoOptions).map((option, i) => (
                <option key={i} value={option?.value}>
                  {option?.label}
                </option>
              ))}
            </select>
          </div>
        )}
        {blogPageLink}
        {isGuest ? null : inboxLink}
        {isGuest ? null : profileMenu}
        {becomeHost}
        {signupLink}
        {loginLink}
      </div>
    </nav>
  );
};

TopbarDesktop.defaultProps = {
  rootClassName: null,
  className: null,
  currentUser: null,
  currentPage: null,
  notificationCount: 0,
  initialSearchFormValues: {},
  appConfig: null,
};

TopbarDesktop.propTypes = {
  rootClassName: string,
  className: string,
  currentUserHasListings: bool.isRequired,
  currentUser: propTypes.currentUser,
  currentPage: string,
  isAuthenticated: bool.isRequired,
  onLogout: func.isRequired,
  notificationCount: number,
  onSearchSubmit: func.isRequired,
  initialSearchFormValues: object,
  intl: intlShape.isRequired,
  appConfig: object,
};

export default TopbarDesktop;
