import { storableError } from '../../util/errors';
import { convertUnitToSubUnit, unitDivisor } from '../../util/currency';
import {
  parseDateFromISO8601,
  getExclusiveEndDate,
  addTime,
  subtractTime,
  daysBetween,
  getStartOf,
} from '../../util/dates';
import { createImageVariantConfig } from '../../util/sdkLoader';
import { isOriginInUse, isStockInUse } from '../../util/search';
import { parse } from '../../util/urlHelpers';
import { addMarketplaceEntities } from '../../ducks/marketplaceData.duck';
import { fetchSingleSeoCategory } from '../../ducks/strapi.duck';
import { extractAndFormatBoundsFromString } from '../../util/dataExtractors';
import { searchListings } from '../SearchPage/SearchPage.duck';

// Pagination page size might need to be dynamic on responsive page layouts
// Current design has max 3 columns 12 is divisible by 2 and 3
// So, there's enough cards to fill all columns on full pagination pages
const RESULT_PAGE_SIZE = 100;

// ================ Action types ================ //

export const SEARCH_LISTINGS_REQUEST = 'app/SeoPage/SEARCH_LISTINGS_REQUEST';
export const SEARCH_LISTINGS_SUCCESS = 'app/SeoPage/SEARCH_LISTINGS_SUCCESS';
export const SEARCH_LISTINGS_ERROR = 'app/SeoPage/SEARCH_LISTINGS_ERROR';

export const SEARCH_MAP_LISTINGS_REQUEST = 'app/SeoPage/SEARCH_MAP_LISTINGS_REQUEST';
export const SEARCH_MAP_LISTINGS_SUCCESS = 'app/SeoPage/SEARCH_MAP_LISTINGS_SUCCESS';
export const SEARCH_MAP_LISTINGS_ERROR = 'app/SeoPage/SEARCH_MAP_LISTINGS_ERROR';

export const SEARCH_MAP_SET_ACTIVE_LISTING = 'app/SeoPage/SEARCH_MAP_SET_ACTIVE_LISTING';

export const FETCH_SEO_REQUEST = 'app/SeoPage/FETCH_SEO_REQUEST';
export const FETCH_SEO_SUCCESS = 'app/SeoPage/FETCH_SEO_SUCCESS';

// ================ Reducer ================ //

const initialState = {
  searchInProgress: false,
};

const listingPageReducer = (state = initialState, action = {}) => {
  const { type, payload } = action;
  switch (type) {
    case FETCH_SEO_REQUEST:
      return {
        ...state,
        searchInProgress: payload.data,
      };
    case FETCH_SEO_SUCCESS:
      return {
        ...state,
        searchInProgress: payload.data,
      };
    default:
      return state;
  }
};

export default listingPageReducer;

// ================ Action creators ================ //

export const fetchSeoRequest = response => ({
  type: FETCH_SEO_REQUEST,
  payload: { data: true },
});

export const fetchSeoSuccess = () => ({
  type: FETCH_SEO_SUCCESS,
  payload: { data: false },
});

// Assuming extractAndFormatBoundsFromString is defined elsewhere in your codebase.

export const searchSEO = (searchParams, config, seoTitle) => async (dispatch, getState, sdk) => {
  dispatch(fetchSeoRequest());

  try {
    // Fetch SEO category based on the title
    await dispatch(fetchSingleSeoCategory({ title: seoTitle }));

    // Get the category from state
    const category = getState().strapi.seoCategory;
    const url = category?.attributes?.url;

    // Extract and format bounds from the category URL if available
    const bounds = url ? extractAndFormatBoundsFromString(url) : null;

    // If bounds are available, add them to the search parameters
    if (bounds) {
      searchParams = { ...searchParams, bounds };
    }

    // Perform the listing search with the updated search parameters
    await dispatch(searchListings({ ...searchParams }, config));

    // Dispatch success action with the updated search parameters
    dispatch(fetchSeoSuccess());
  } catch (error) {
    console.error('Failed to perform SEO category:', error);
  }
};

export const setActiveListing = listingId => ({
  type: SEARCH_MAP_SET_ACTIVE_LISTING,
  payload: listingId,
});

export const loadData = (params, search, config, location) => {
  const pathname = location?.pathname;
  const seoTitle = pathname ? pathname.split('/')[2] : null;
  const queryParams = parse(search, {
    latlng: ['origin'],
    latlngBounds: ['bounds'],
  });

  // Add minStock filter with default value (1), if stock management is in use.
  // This can be overwriten with passed-in query parameters.
  const minStockMaybe = isStockInUse(config) ? { minStock: 1 } : {};
  const { page = 1, address, origin, ...rest } = queryParams;
  const originMaybe = isOriginInUse(config) && origin ? { origin } : {};

  const {
    aspectWidth = 1,
    aspectHeight = 1,
    variantPrefix = 'listing-card',
  } = config.layout.listingImage;
  const aspectRatio = aspectHeight / aspectWidth;

  return searchSEO(
    {
      ...minStockMaybe,
      ...rest,
      ...originMaybe,
      page,
      perPage: RESULT_PAGE_SIZE,
      include: ['author', 'images'],
      'fields.listing': [
        'title',
        'geolocation',
        'price',
        'publicData',
        'publicData.listingType',
        'publicData.transactionProcessAlias',
        'publicData.unitType',
        // These help rendering of 'purchase' listings,
        // when transitioning from search page to listing page
        'publicData.pickupEnabled',
        'publicData.shippingEnabled',
      ],
      'fields.user': ['profile.displayName', 'profile.abbreviatedName'],
      'fields.image': [
        'variants.scaled-small',
        'variants.scaled-medium',
        `variants.${variantPrefix}`,
        `variants.${variantPrefix}-2x`,
      ],
      ...createImageVariantConfig(`${variantPrefix}`, 400, aspectRatio),
      ...createImageVariantConfig(`${variantPrefix}-2x`, 800, aspectRatio),
      'limit.images': 1,
    },
    config,
    seoTitle
  );
};
