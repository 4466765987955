import React from 'react';
import NamedLink from '../NamedLink/NamedLink';
import Icons from '../Icons/Icons';
import { useMyContextFunctions } from '../../context/ContextFunctions';
import { TopbarFilterTypes } from '../../util/enums';

import css from './MobileBottomBar.module.css';
import classNames from 'classnames';
import { EventBus } from '../../util/eventHelpers';

function MobileBottomBar() {
  const bottomNavLinks = [
    { name: 'LandingPage', icon: 'home' },
    { name: 'SearchPage', icon: 'search' },
    { name: 'BlogPage', icon: 'blogNav' },
    { name: 'FavouritesPage', icon: 'unfavorite' },
    { name: 'ProfileSettingsPage', icon: 'profile' },
  ];
  const {
    onSetGlobalFilterModal,
    onToggleTopbarFilter,
    onSetGlobalFilterParams,
  } = useMyContextFunctions();
  return (
    <div className={css.bottomNavbar}>
      {bottomNavLinks.map((link, index) => {
        if (link?.name === 'SearchPage') {
          return (
            <span
              onClick={() => {
                onSetGlobalFilterModal(true, TopbarFilterTypes.LOCATION);
                onToggleTopbarFilter(true);
                onSetGlobalFilterParams({
                  searchKeyword: '',
                });
                EventBus.dispatch('focusInput', { showCursor: true });
              }}
              key={index}
              name={link.name}
              className={link.className}
            >
              <Icons name={link.icon} />
            </span>
          );
        } else {
          return (
            <NamedLink
              key={index}
              name={link.name}
              className={classNames(
                link.className,
                link.icon === 'unfavorite' ? css.favoriteIcon : null
              )}
            >
              <Icons name={link.icon} />
            </NamedLink>
          );
        }
      })}
    </div>
  );
}

export default MobileBottomBar;
