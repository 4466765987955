import React from 'react';
import { node, string } from 'prop-types';
import classNames from 'classnames';
import { Field } from 'react-final-form';

import css from './FieldCheckbox.module.css';

const IconCheckbox = props => {
  const { className, checkedClassName, boxClassName } = props;
  return (
    <svg
      className={className}
      width="18"
      height="18"
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        className={boxClassName || css.box}
        d="M15.25 0C16.7688 0 18 1.23122 18 2.75V15.25C18 16.7688 16.7688 18 15.25 18H2.75C1.23122 18 0 16.7688 0 15.25V2.75C0 1.23122 1.23122 0 2.75 0H15.25ZM15.25 1.5H2.75C2.05964 1.5 1.5 2.05964 1.5 2.75V15.25C1.5 15.9404 2.05964 16.5 2.75 16.5H15.25C15.9404 16.5 16.5 15.9404 16.5 15.25V2.75C16.5 2.05964 15.9404 1.5 15.25 1.5Z"
        fill="#51565c"
      />
      <path
        className={checkedClassName || css.checked}
        d="M15.25 0C16.7688 0 18 1.23122 18 2.75V15.25C18 16.7688 16.7688 18 15.25 18H2.75C1.23122 18 0 16.7688 0 15.25V2.75C0 1.23122 1.23122 0 2.75 0H15.25ZM15.25 1.5H2.75C2.05964 1.5 1.5 2.05964 1.5 2.75V15.25C1.5 15.9404 2.05964 16.5 2.75 16.5H15.25C15.9404 16.5 16.5 15.9404 16.5 15.25V2.75C16.5 2.05964 15.9404 1.5 15.25 1.5ZM7 11.4393L13.4697 4.96967C13.7626 4.67678 14.2374 4.67678 14.5303 4.96967C14.7966 5.23594 14.8208 5.6526 14.6029 5.94621L14.5303 6.03033L7.53033 13.0303C7.26406 13.2966 6.8474 13.3208 6.55379 13.1029L6.46967 13.0303L3.46967 10.0303C3.17678 9.73744 3.17678 9.26256 3.46967 8.96967C3.73594 8.7034 4.1526 8.6792 4.44621 8.89705L4.53033 8.96967L7 11.4393L13.4697 4.96967L7 11.4393Z"
        fill="#f6fde8"
      />
    </svg>
  );
};

IconCheckbox.defaultProps = { className: null, checkedClassName: null, boxClassName: null };

IconCheckbox.propTypes = { className: string, checkedClassName: string, boxClassName: string };

const FieldCheckboxComponent = props => {
  const {
    rootClassName,
    className,
    svgClassName,
    textClassName,
    id,
    label,
    useSuccessColor,
    radio,
    ...rest
  } = props;

  const classes = classNames(rootClassName || css.root, className);

  // This is a workaround for a bug in Firefox & React Final Form.
  // https://github.com/final-form/react-final-form/issues/134
  const handleOnChange = (input, event) => {
    const { onBlur, onChange } = input;
    onChange(event);
    onBlur(event);

    // If onChange has been passed as a props to FieldCheckbox
    if (rest.onChange) {
      rest.onChange(event);
    }
  };

  const successColorVariantMaybe = useSuccessColor
    ? {
        checkedClassName: css.checkedSuccess,
        boxClassName: css.boxSuccess,
      }
    : {};
  const disabledColorMaybe = rest.disabled
    ? {
        checkedClassName: css.checkedDisabled,
        boxClassName: css.boxDisabled,
      }
    : {};

  return (
    <span className={classes}>
      <Field type={radio ? 'radio' : 'checkbox'} {...rest}>
        {props => {
          const { input, disabled } = props;
          return (
            <input
              id={id}
              className={css.input}
              {...input}
              onChange={event => handleOnChange(input, event)}
              disabled={disabled}
            />
          );
        }}
      </Field>
      <label htmlFor={id} className={css.label}>
        <span className={css.checkboxWrapper}>
          <IconCheckbox
            className={svgClassName}
            {...successColorVariantMaybe}
            {...disabledColorMaybe}
          />
        </span>
        <span className={classNames(css.text, textClassName || css.textRoot)}>{label}</span>
      </label>
    </span>
  );
};

FieldCheckboxComponent.defaultProps = {
  className: null,
  rootClassName: null,
  svgClassName: null,
  textClassName: null,
  label: null,
};

FieldCheckboxComponent.propTypes = {
  className: string,
  rootClassName: string,
  svgClassName: string,
  textClassName: string,

  // Id is needed to connect the label with input.
  id: string.isRequired,
  label: node,

  // Name groups several checkboxes to an array of selected values
  name: string.isRequired,

  // Checkbox needs a value that is passed forward when user checks the checkbox
  // value: string.isRequired,
};

export default FieldCheckboxComponent;
