import React from 'react';
import FilterModalComponent from '../../FilterModal/FilterModal';
import Icons from '../../Icons/Icons';
import MakeFilterQuery from './MakeFilterQuery';
import RenderLocationFilter from './RenderLocationFilter';
import RenderDatesInput from './RenderDatesInput';
import RenderFilters from './RenderFilters';
import RenderExtraFilters from './RenderExtraFilters';
import { useMyContext } from '../../../context/StateHolder';
import IconCalendar from '../../IconCalendar/IconCalendar';
import IconVehicle from '../../IconVehicle/IconVehicle';
import classNames from 'classnames';
import { useMyContextFunctions } from '../../../context/ContextFunctions';
import { TopbarFilterTypes } from '../../../util/enums';

import css from '../Topbar.module.css';
import moment from 'moment';
import { isArrayLength } from '../../../util/genericHelpers';

const FilterButton = ({ isActive, onClick, icon, label, extraClass = '' }) => (
  <span
    key={label}
    className={classNames(css.filterNavBtn, extraClass, { [css.active]: isActive })}
    onClick={onClick}
  >
    {icon}
    {label}
  </span>
);

function TopbarFilters(props) {
  const { onManageDisableScrolling, isMobileLayout, intl } = props;

  const { openGlobalFilterModal, filterModalContent, globalFilterParams } = useMyContext();
  const { onSetGlobalFilterModal } = useMyContextFunctions();

  const renderModalContent = () => {
    switch (filterModalContent) {
      case TopbarFilterTypes.LOCATION:
        return <RenderLocationFilter />;
      case TopbarFilterTypes.DATES:
        return <RenderDatesInput />;
      case TopbarFilterTypes.FILTERS:
        return <RenderFilters isMobileLayout={isMobileLayout} />;
      case TopbarFilterTypes.EXTRA_FILTERS:
        return <RenderExtraFilters isMobileLayout={isMobileLayout} />;
      default:
        return null;
    }
  };

  const globalFilterParamsDates = globalFilterParams?.dates;
  const showDateLabel =
    globalFilterParamsDates?.endDate &&
    `${moment(globalFilterParamsDates?.startDate).format('MM.DD.YY')} - ${moment(
      globalFilterParamsDates?.endDate
    ).format('MM.DD.YY')}`;

  const getFilterCount = () => {
    let count = 0;

    if (isArrayLength(globalFilterParams?.travelTypes)) {
      count++;
    }
    if (isArrayLength(globalFilterParams?.vehicleHeight)) {
      count++;
    }
    if (isArrayLength(globalFilterParams?.vehicleLength)) {
      count++;
    }

    return count;
  };

  const getExtraFilterCount = () => {
    let count = 0;

    if (globalFilterParams?.prices?.key) {
      count++;
    }
    if (isArrayLength(globalFilterParams?.pitchCategory)) {
      count++;
    }
    if (isArrayLength(globalFilterParams?.general)) {
      count++;
    }

    return count;
  };

  const filterButtons = [
    {
      type: TopbarFilterTypes.LOCATION,
      label: intl.formatMessage({ id: 'TopbarFilters.location' }),
      icon: <Icons name="location" />,
      extraClass: css.locationIcon,
    },
    {
      type: TopbarFilterTypes.DATES,
      label: intl.formatMessage({ id: 'TopbarFilters.dates' }),
      icon: <IconCalendar />,
    },
    {
      type: TopbarFilterTypes.FILTERS,
      label:
        !isMobileLayout && getFilterCount() > 0
          ? `(${getFilterCount()})`
          : intl.formatMessage({ id: 'TopbarFilters.filters' }),
      icon: <Icons name="camping" />,
      extraClass: css.filterIcon,
    },
    {
      type: TopbarFilterTypes.EXTRA_FILTERS,
      label:
        !isMobileLayout && getExtraFilterCount() > 0
          ? `(${getExtraFilterCount()})`
          : intl.formatMessage({ id: 'TopbarFilters.extraFilters' }),
      icon: <Icons name="filter" />,
      extraClass: css.filterIcon,
    },
  ];

  return (
    <FilterModalComponent
      id="FilterModal"
      containerClassName={css.filterModal}
      isOpen={openGlobalFilterModal}
      onClose={() => onSetGlobalFilterModal(false)}
      usePortal
      onManageDisableScrolling={onManageDisableScrolling}
    >
      <div className={css.filterModalContainer}>
        <div className={css.filterNav}>
          {filterButtons.map(button => (
            <FilterButton
              key={button.type}
              isActive={filterModalContent === button.type && openGlobalFilterModal}
              onClick={() => onSetGlobalFilterModal(true, button.type)}
              icon={button.icon}
              label={button.label}
              extraClass={button.extraClass}
            />
          ))}
        </div>
        {renderModalContent()}
        <MakeFilterQuery onCloseModal={() => onSetGlobalFilterModal(false)} />
      </div>
    </FilterModalComponent>
  );
}

export default TopbarFilters;
