/**
 *  TopbarMobileMenu prints the menu content for authenticated user or
 * shows login actions for those who are not authenticated.
 */
import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { FormattedMessage } from '../../../util/reactIntl';
import { propTypes } from '../../../util/types';
import { ensureCurrentUser } from '../../../util/data';
import {
  AvatarLarge,
  Icons,
  InlineTextButton,
  LanguageSwitcher,
  LinkedLogo,
  NamedLink,
} from '../../../components';
import {
  capitalizeFirstLetter,
  isArrayLength,
  sortArrayByLabel,
} from '../../../util/genericHelpers';
import Select from 'react-select';

import css from './TopbarMobileMenu.module.css';

const colourStyles = {
  control: (styles, { data, isDisabled, isFocused, isSelected }) => {
    return {
      ...styles,
      backgroundColor: '#f6fde8',
      border: 'none',
      boxShadow: 'none',
      borderRadius: '100px',
      height: 20,
    };
  },
  valueContainer: base => ({
    ...base,
    padding: '0',
    marging: '0',
  }),
  option: (styles, { data, isDisabled, isFocused, isSelected }) => {
    return {
      ...styles,
      backgroundColor: isDisabled ? '#f6fde8' : '#f6fde8',
      color: '#51565c',
      fontSize: '20px',
      lineHeight: '100%',
      padding: '6px 10px',
      cursor: isDisabled ? 'not-allowed' : 'default',
      '&:hover': {
        backgroundColor: '#3d6329',
        color: '#fff',
        cursor: 'pointer',
      },
    };
  },
  Input: base => ({ ...base, margin: '0px', padding: '0px', maxHeight: 45 }),
  menu: base => ({
    ...base,
    backgroundColor: '#f6fde8',
  }),
};

const TopbarMobileMenu = props => {
  const { isAuthenticated, currentUser, onLogout, seoPages, notificationCount, history } = props;

  const user = ensureCurrentUser(currentUser);

  const onCategoryChange = option => {
    history.push(`/camping/${option?.value}`);
  };

  const seoOptions =
    isArrayLength(seoPages) &&
    seoPages?.map(s => {
      return {
        value: s?.attributes?.title,
        label: capitalizeFirstLetter(s?.attributes?.title),
      };
    });

  const notificationDot = notificationCount > 0 ? <div className={css.notificationDot} /> : null;
  const seoCategorySelector = (
    <Select
      placeholder="Camping Region"
      options={sortArrayByLabel(seoOptions) || []}
      onChange={onCategoryChange}
      styles={colourStyles}
      components={{
        IndicatorSeparator: () => null,
      }}
    />
  );

  const displayName = user?.attributes?.profile?.firstName;

  const renderBottomLinks = (
    <div className={css.bottomContent}>
      <LanguageSwitcher />
      <div className={css.staticPageLinks}>
        <NamedLink name="TermsOfServicePage">
          <FormattedMessage id="TopbarMobileMenu.terms" />
        </NamedLink>
        <NamedLink name="ImprintPage">
          <FormattedMessage id="TopbarMobileMenu.imprint" />
        </NamedLink>
        <NamedLink name="DataProtectionPage">
          <FormattedMessage id="TopbarMobileMenu.dataProtection" />
        </NamedLink>
      </div>
    </div>
  );

  const renderHomepageIcons = (
    <NamedLink className={classNames(css.mobileNavLink, css.homepageLink)} name="LandingPage">
      <Icons name="home" />
      <FormattedMessage id="TopbarMobileMenu.homepage" />
    </NamedLink>
  );

  if (!isAuthenticated) {
    return (
      <div className={css.root}>
        <div className={css.greetingSec}>
          <LinkedLogo className={css.logo} />
          <span className={css.greeting}>
            <FormattedMessage id="SectionHero.title" />
          </span>
        </div>
        <div className={css.content}>
          <div className={css.mobileNav}>
            {renderHomepageIcons}
            <NamedLink className={classNames(css.mobileNavLink, css.loginLink)} name="LoginPage">
              <Icons name="login" />
              <FormattedMessage id="TopbarMobileMenu.loginLinkk" />
            </NamedLink>
            <NamedLink className={css.mobileNavLink} name="SignupPage">
              <Icons name="register" />
              <FormattedMessage id="TopbarMobileMenu.signupLinkk" />
            </NamedLink>
          </div>

          <div className={css.separator}></div>
          <div className={css.mobileNav}>
            <NamedLink className={css.mobileNavLink} name="NewListingPage">
              <Icons name="plus" />
              <FormattedMessage id="TopbarMobileMenu.addPitch" />
            </NamedLink>
            <NamedLink className={css.mobileNavLink} name="BecomeAHostPage">
              <Icons name="hostIcon" />
              <FormattedMessage id="TopbarMobileMenu.becomeHost" />
            </NamedLink>
          </div>
          <div className={css.separator}></div>
          <div className={css.mobileNav}>
            <div className={classNames(css.mobileNavLink, css.campingIcon)}>
              <Icons name="camping" />
              {seoCategorySelector}
            </div>
            <NamedLink className={classNames(css.mobileNavLink, css.blogIcon)} name="BlogPage">
              <Icons name="blog" />
              <FormattedMessage id="TopbarMobileMenu.blog" />
            </NamedLink>
          </div>
          <div className={css.separator}></div>
          <div className={css.otherLinks}>
            <a className={css.otherLink} href="mailto:team@youandaview.com">
              <FormattedMessage id="TopbarMobileMenu.contact" />
            </a>
          </div>
          {renderBottomLinks}
        </div>
      </div>
    );
  }

  return (
    <div className={css.root}>
      <div className={css.greetingSec}>
        <AvatarLarge className={css.avatar} user={currentUser} />
        <span className={css.greeting}>
          <FormattedMessage id="TopbarMobileMenu.greeting" values={{ displayName }} />
        </span>
      </div>
      <div className={css.content}>
        <div className={css.mobileNav}>
          {renderHomepageIcons}
          <NamedLink className={css.mobileNavLink} name="ProfileSettingsPage">
            <Icons name="profile" />
            <FormattedMessage id="TopbarMobileMenu.profile" />
          </NamedLink>
          <NamedLink className={css.mobileNavLink} name="InboxPage" params={{ tab: 'orders' }}>
            <Icons name="calendar" />
            <FormattedMessage id="TopbarMobileMenu.myBookings" />
            {notificationDot}
          </NamedLink>
          <NamedLink className={css.mobileNavLink} name="ManageListingsPage">
            <Icons name="myListings" />
            <FormattedMessage id="TopbarMobileMenu.myListings" />
          </NamedLink>
          <NamedLink className={css.mobileNavLink} name="FavouritesPage">
            <Icons name="unfavorite" />
            <FormattedMessage id="TopbarMobileMenu.favorite" />
          </NamedLink>
          <NamedLink className={css.mobileNavLink} name="InboxPage" params={{ tab: 'sales' }}>
            <Icons name="message" />
            <FormattedMessage id="TopbarMobileMenu.inbox" />
          </NamedLink>
          <NamedLink className={css.mobileNavLink} name="ProfileSettingsPage">
            <Icons name="profileSettings" />
            <FormattedMessage id="TopbarDesktop.profileSettingsLink" />
          </NamedLink>
          <NamedLink className={css.mobileNavLink} name="AccountSettingsPage">
            <Icons name="accountSettings" />
            <FormattedMessage id="TopbarDesktop.accountSettingsLink" />
          </NamedLink>
        </div>

        <div className={css.separator}></div>
        <div className={css.mobileNav}>
          <NamedLink className={css.mobileNavLink} name="NewListingPage">
            <Icons name="plus" />
            <FormattedMessage id="TopbarMobileMenu.addPitch" />
          </NamedLink>
        </div>
        <div className={css.separator}></div>
        <div className={css.mobileNav}>
          <div className={classNames(css.mobileNavLink, css.campingIcon)}>
            <Icons name="camping" />
            {seoCategorySelector}
          </div>
          <NamedLink className={classNames(css.mobileNavLink, css.blogIcon)} name="BlogPage">
            <Icons name="blog" />
            <FormattedMessage id="TopbarMobileMenu.blog" />
          </NamedLink>
        </div>
        <div className={css.separator}></div>
        <div className={css.otherLinks}>
          <a className={css.otherLink} href="mailto:team@youandaview.com">
            <FormattedMessage id="TopbarMobileMenu.contact" />
          </a>
          <InlineTextButton className={css.otherLink} onClick={onLogout}>
            <FormattedMessage id="TopbarMobileMenu.logoutLink" />
          </InlineTextButton>
        </div>
        {renderBottomLinks}
      </div>
    </div>
  );
};

TopbarMobileMenu.defaultProps = { currentUser: null, notificationCount: 0, currentPage: null };

const { bool, func, number, string } = PropTypes;

TopbarMobileMenu.propTypes = {
  isAuthenticated: bool.isRequired,
  currentUserHasListings: bool.isRequired,
  currentUser: propTypes.currentUser,
  currentPage: string,
  notificationCount: number,
  onLogout: func.isRequired,
};

export default TopbarMobileMenu;
