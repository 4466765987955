import React, { useEffect, useState } from 'react';
import { Icons } from '../..';
import {
  vehicleHeightOptions,
  vehicleLengthOptions,
  vehicleTypesOptions,
} from '../../../util/fieldsOptions';
import { useMyContext } from '../../../context/StateHolder';
import { useMyContextFunctions } from '../../../context/ContextFunctions';
import { useIntl } from '../../../util/reactIntl';

import css from '../Topbar.module.css';
import { useLocation } from 'react-router-dom/cjs/react-router-dom.min';
import { parse } from '../../../util/urlHelpers';

const vehicleTypesMapping = {
  'car-with-roof-tent': ['car-with-roof-tent', 'van-minibus'],
  motorhome: ['motorhome', 'van-minibus'],
  'motorhome-over-3-1/2-tons': ['motorhome-over-3-1/2-tons', 'motorhome', 'van-minibus'],
  caravan: ['caravan'],
  tent: ['tent'],
  'van-minibus': ['van-minibus', 'car-with-roof-tent'],
};

// Extracting the label modification logic into a separate function for clarity and reusability
const formatLabel = label => {
  return label
    .replace('up to', '') // Remove 'up to'
    .replace('meters', 'm'); // Replace 'meters' with 'm'
};

const getSelectedValue = (parsedSearch, param) => {
  const paramValue = parsedSearch?.[param];
  return paramValue ? paramValue.split(',')[0] : null;
};

function RenderFilters(props) {
  const { isMobileLayout } = props;
  const { globalFilterParams } = useMyContext();
  const { onSetGlobalFilterParams } = useMyContextFunctions();
  const intl = useIntl();
  const location = useLocation();
  const parsedSearch = parse(location.search);

  const selectedVehicleLength = getSelectedValue(parsedSearch, 'pub_vehicleLength');
  const selectedVehicleHeight = getSelectedValue(parsedSearch, 'pub_vehicleHeight');

  const travelTypes = globalFilterParams?.travelTypes || [];
  const vehicleHeight = globalFilterParams?.vehicleHeight || [];
  const vehicleLength = globalFilterParams?.vehicleLength || [];

  const [vehicleDimensions, setVehicleDimensions] = useState({
    vehicleHeight: selectedVehicleHeight || '',
    vehicleLength: selectedVehicleLength || '',
  });

  const [showTravelTypes, setTravelTypes] = useState(!isMobileLayout);
  const [showVehicleHeight, setVehicleHeight] = useState(!isMobileLayout);
  const [showVehicleLength, setVehicleLength] = useState(!isMobileLayout);

  const [filterOptions, setFilterOptions] = useState({ travelTypes, vehicleHeight, vehicleLength });

  useEffect(() => {
    onSetGlobalFilterParams(filterOptions);
  }, [filterOptions]);

  const handleCheckboxChange = (event, optionKey) => {
    const { checked, name } = event.target;

    setFilterOptions(prevState => {
      let updatedOptions = prevState[optionKey] ? [...prevState[optionKey]] : [];

      if (checked) {
        if (name.startsWith('up-to')) {
          // If "up-to-X-meter" is selected, automatically select all larger values
          const selectedMeter = parseInt(name.split('-')[2]); // Extract the meter value

          if (optionKey === 'vehicleLength') {
            vehicleLengthOptions.forEach(option => {
              if (option.key.startsWith('up-to')) {
                const meterValue = parseInt(option.key.split('-')[2]);
                if (meterValue >= selectedMeter && !updatedOptions.includes(option.key)) {
                  updatedOptions.push(option.key);
                  updatedOptions.push('over-10-meter');
                }
              }
            });
          }
          if (optionKey === 'vehicleHeight') {
            vehicleHeightOptions.forEach(option => {
              if (option.key.startsWith('up-to')) {
                const meterValue = parseInt(option.key.split('-')[2]);
                if (meterValue >= selectedMeter && !updatedOptions.includes(option.key)) {
                  updatedOptions.push(option.key);
                  updatedOptions.push('over-4-meter');
                }
              }
            });
          }
        } else if (vehicleTypesMapping[name]) {
          for (let index = 0; index < vehicleTypesMapping[name].length; index++) {
            const element = vehicleTypesMapping[name][index];
            updatedOptions.push(element);
          }
        } else {
          // For "over" options, just add the selected option
          updatedOptions.push(name);
        }
      } else {
        // Removing an option: Remove the specific option only
        const index = updatedOptions.indexOf(name);
        if (index > -1) {
          updatedOptions.splice(index, 1);
        }
      }
      return { ...prevState, [optionKey]: updatedOptions };
    });
  };

  return (
    <div className={css.customFilters}>
      <div className={css.filterBlock}>
        <h4>
          {' '}
          {isMobileLayout && (
            <span className={css.toggleIcon} onClick={() => setTravelTypes(!showTravelTypes)}>
              <Icons name="plus" />
            </span>
          )}
          {intl.formatMessage({ id: 'RenderFilters.vehicleTypes' })}
        </h4>
        {showTravelTypes && (
          <div className={css.options}>
            {vehicleTypesOptions.map(v => (
              <div className={css.filterOption} key={v.key}>
                <input
                  type="checkbox"
                  name={v.key}
                  checked={filterOptions.travelTypes?.includes(v.key)}
                  onChange={e => handleCheckboxChange(e, 'travelTypes')}
                />
                <label htmlFor={v.key}>{v.label}</label>
              </div>
            ))}
          </div>
        )}
      </div>

      {/* Vehicle Length Block */}
      <div className={css.filterBlock}>
        <h4>
          {' '}
          {isMobileLayout && (
            <span className={css.toggleIcon} onClick={() => setVehicleLength(!showVehicleLength)}>
              <Icons name="plus" />
            </span>
          )}
          {intl.formatMessage({ id: 'RenderFilters.vehicleLength' })}
        </h4>
        {showVehicleLength && (
          <div className={css.options}>
            {vehicleLengthOptions.map(v => (
              <div className={css.filterOption} key={v.key}>
                <input
                  type="checkbox"
                  name={v.key}
                  checked={vehicleDimensions.vehicleLength === v?.key}
                  onChange={e => {
                    handleCheckboxChange(e, 'vehicleLength');
                    setVehicleDimensions({ ...vehicleDimensions, vehicleLength: v.key });
                  }}
                />
                <label htmlFor={v.key}>{formatLabel(v.label)}</label>
              </div>
            ))}
          </div>
        )}
      </div>

      {/* Vehicle Height Block */}
      <div className={css.filterBlock}>
        <h4>
          {' '}
          {isMobileLayout && (
            <span className={css.toggleIcon} onClick={() => setVehicleHeight(!showVehicleHeight)}>
              <Icons name="plus" />
            </span>
          )}
          {intl.formatMessage({ id: 'RenderFilters.vehicleHeight' })}
        </h4>
        {showVehicleHeight && (
          <div className={css.options}>
            {vehicleHeightOptions.map(v => (
              <div className={css.filterOption} key={v.key}>
                <input
                  type="checkbox"
                  name={v.key}
                  checked={vehicleDimensions.vehicleHeight === v?.key}
                  onChange={e => {
                    handleCheckboxChange(e, 'vehicleHeight');
                    setVehicleDimensions({ ...vehicleDimensions, vehicleHeight: v.key });
                  }}
                />
                <label htmlFor={v.key}>{formatLabel(v.label)}</label>
              </div>
            ))}
          </div>
        )}
      </div>
    </div>
  );
}

export default RenderFilters;
