import React, { useState, useEffect } from 'react';
import AsyncSelect from 'react-select/async';
import { isArrayLength } from '../../util/genericHelpers';

import css from './AutoSuggestion.module.css';

const AutoSuggestion = ({
  data,
  searchKeys,
  onSelectionChange,
  initialValues,
  isMulti = true,
  placeholder,
}) => {
  const [formattedData, setFormattedData] = useState([]);
  const [selectedOptions, setSelectedOptions] = useState([]);

  useEffect(() => {
    const formattedOptions = data.map(item => ({
      value: item[searchKeys[0]],
      label: searchKeys.map(key => (item[key] ? item[key].toString() : '')).join(' - '),
    }));
    setFormattedData(formattedOptions);
  }, [data, searchKeys]);

  useEffect(() => {
    if (isArrayLength(initialValues) && formattedData.length > 0) {
      const initialOptions = initialValues.map(value => {
        const option = formattedData.find(option => option.value === value.value);
        return option || value;
      });
      setSelectedOptions(initialOptions);
    }
  }, [formattedData, initialValues]);

  const loadOptions = (inputValue, callback) => {
    setTimeout(() => {
      const filteredOptions = formattedData.filter(option =>
        option.label.toLowerCase().includes(inputValue.toLowerCase())
      );
      callback(filteredOptions);
    }, 500); // Add a delay to simulate async loading (optional)
  };

  const handleChange = options => {
    setSelectedOptions(options || []);
    onSelectionChange(options);
  };
  const customStyles = {
    control: (styles, { data, isDisabled, isFocused, isSelected }) => {
      return {
        ...styles,
        backgroundColor: '#f6fde8',
        border: `none`,
        boxShadow: 'none',
        borderRadius: '6px',
        padding: '0',
        margin: '0',
        Height: '40px',
        minHeight: '40px',
        cursor: 'pointer',
        '&:hover': {
          border: `none`,
        },
      };
    },
    placeholder: styles => ({
      ...styles,
      position: 'relative',
      bottom: '0px',
      padding: '0px',
      margin: '0',
      fontSize: '16px',
    }),
    valueContainer: styles => ({
      ...styles,
      minHeight: '40px',
      padding: '0 15px ',
      margin: '0',
      fontWeight: '400',
      fontSize: '16px',
    }),
    option: (styles, { data, isDisabled, isFocused, isSelected }) => {
      return {
        ...styles,
        backgroundColor: isDisabled ? '#ddd' : '#f6fde8',
        color: isDisabled ? '#ccc' : '#313135',
        fontSize: '16px',
        fontWeight: '400',
        lineHeight: '120%',
        padding: '10px 16px',
        cursor: isDisabled ? 'not-allowed' : 'default',
        '&:hover': {
          backgroundColor: '#eee',
          color: '#000',
          cursor: 'pointer',
        },
      };
    },
    input: styles => ({
      ...styles,
      margin: '0px',
      padding: '0px',
      fontSize: '16px',
      minHeight: 'auto',
    }),
    menu: styles => ({
      ...styles,
      zIndex: 9999, // High z-index to ensure the dropdown appears above other elements
      backgroundColor: '#f6fde8',
    }),
  };

  return (
    <div className={css.predictionRoot}>
      <AsyncSelect
        isClearable
        cacheOptions
        className={css.customSelect}
        loadOptions={loadOptions}
        onChange={handleChange}
        placeholder={placeholder}
        isMulti={isMulti}
        defaultOptions={formattedData}
        value={selectedOptions}
        styles={customStyles}
        components={{
          IndicatorSeparator: () => null,
        }}
      />
    </div>
  );
};

export default AutoSuggestion;
