import React from 'react';
import { any, string } from 'prop-types';
import ReactDOMServer from 'react-dom/server';

// react-dates needs to be initialized before using any react-dates component
// https://github.com/airbnb/react-dates#initialize
// NOTE: Initializing it here will initialize it also for app.test.js
import 'react-dates/initialize';
import { HelmetProvider } from 'react-helmet-async';
import { BrowserRouter, StaticRouter } from 'react-router-dom';
import { Provider } from 'react-redux';
import loadable from '@loadable/component';
import difference from 'lodash/difference';
import mapValues from 'lodash/mapValues';
import moment from 'moment';

// Configs and store setup
import defaultConfig from './config/configDefault';
import appSettings from './config/settings';
import configureStore from './store';

// utils
import { RouteConfigurationProvider } from './context/routeConfigurationContext';
import { ConfigurationProvider } from './context/configurationContext';
import { mergeConfig } from './util/configHelpers';
import { IntlProvider } from './util/reactIntl';
import { IncludeScripts } from './util/includeScripts';

import { MaintenanceMode } from './components';
import StateHolder from './context/StateHolder';
import ContextFunctions from './context/ContextFunctions';

// routing
import routeConfiguration from './routing/routeConfiguration';
import Routes from './routing/Routes';

import { ConsentBanner, ConsentProvider } from 'react-hook-consent';
import './styles/cookieStyle.css';

// Sharetribe Web Template uses English translations as default translations.
import englishMessages from './translations/en.json';
import germanMessages from './translations/de.json';
import frenchMessages from './translations/fr.json';
import spanishMessages from './translations/es.json';
import portugueseMessages from './translations/pt.json';
import dutchMessages from './translations/nl.json';
import italianMessages from './translations/it.json';

const defaultLocale =
  (typeof window !== 'undefined' && window.localStorage.getItem('selectedLanguage')) || 'de';

// function compareJsonObjects(data1, data2) {
//   // If provided as strings, parse them. Otherwise, assume they're already objects.
//   if (typeof data1 === 'string') data1 = JSON.parse(data1);
//   if (typeof data2 === 'string') data2 = JSON.parse(data2);

//   const missingKeys = [];

//   function compareObjects(obj1, obj2, prefix = '') {
//     for (const key in obj1) {
//       if (typeof obj1[key] === 'object' && obj1[key] !== null && obj2.hasOwnProperty(key)) {
//         // Recursively compare if the key corresponds to an object
//         compareObjects(obj1[key], obj2[key], prefix + key + '.');
//       } else if (!obj2.hasOwnProperty(key)) {
//         missingKeys.push(prefix + key);
//       }
//     }
//   }

//   compareObjects(data1, data2);

//   return missingKeys;
// }

// const differences = compareJsonObjects(
//   JSON.stringify(germanMessages),
//   JSON.stringify(italianMessages)
// );
// console.log(differences);

const translations = {
  necessaryCookies: {
    en: 'Necessary',
    de: 'Erforderlich',
    es: 'Necesario',
    fr: 'Nécessaire',
    pt: 'Necessário',
    it: 'Necessario',
    nl: 'Noodzakelijk',
  },
  preferencesCookies: {
    en: 'Preferences',
    de: 'Präferenzen',
    es: 'Preferencias',
    fr: 'Préférences',
    pt: 'Preferências',
    it: 'Preferenze',
    nl: 'Voorkeuren',
  },
  statisticsCookies: {
    en: 'Statistics',
    de: 'Statistiken',
    es: 'Estadísticas',
    fr: 'Statistiques',
    pt: 'Estatísticas',
    it: 'Statistiche',
    nl: 'Statistieken',
  },
  marketingCookies: {
    en: 'Marketing',
    de: 'Marketing',
    es: 'Marketing',
    fr: 'Marketing',
    pt: 'Marketing',
    it: 'Marketing',
    nl: 'Marketing',
  },
  Approve: {
    en: 'Approve',
    de: 'Genehmigen',
    es: 'Aprobar',
    fr: 'Approuver',
    pt: 'Aprovar',
    it: 'Approvare',
    nl: 'Goedkeuren',
  },
  Decline: {
    en: 'Decline',
    de: 'Ablehnen',
    es: 'Rechazar',
    fr: 'Refuser',
    pt: 'Recusar',
    it: 'Rifiutare',
    nl: 'Weigeren',
  },
  Settings: {
    en: 'Settings',
    de: 'Einstellungen',
    es: 'Configuración',
    fr: 'Paramètres',
    pt: 'Configurações',
    it: 'Impostazioni',
    nl: 'Instellingen',
  },
  CookieMessage: {
    en:
      'We use cookies and similar third-party technologies to personalize content and advertising, optimize your experience, and continually improve ourselves. By clicking ‘Approve,’ you agree. For more information, please refer to our privacy policy.',
    de:
      'Wir verwenden Cookies und ähnliche Technologien von Drittanbietern, um Inhalte und Werbung zu personalisieren, Ihr Erlebnis zu optimieren und uns ständig zu verbessern. Durch Klicken auf "Zustimmen" erklären Sie sich damit einverstanden. Weitere Informationen finden Sie in unserer Datenschutzrichtlinie.',
    es:
      'Utilizamos cookies y tecnologías similares de terceros para personalizar contenido y publicidad, optimizar tu experiencia y mejorar continuamente. Al hacer clic en ‘Aprobar’, aceptas. Para obtener más información, consulta nuestra política de privacidad.',
    fr:
      'Nous utilisons des cookies et des technologies similaires de tiers pour personnaliser le contenu et la publicité, optimiser votre expérience et nous améliorer en permanence. En cliquant sur ‘Approuver’, vous acceptez. Pour plus d’informations, veuillez consulter notre politique de confidentialité.',
    pt:
      'Utilizamos cookies e tecnologias semelhantes de terceiros para personalizar conteúdo e publicidade, otimizar sua experiência e nos aprimorar constantemente. Ao clicar em ‘Aprovar’, você concorda. Para mais informações, consulte nossa política de privacidade.',
    it:
      'Utilizziamo cookie e tecnologie simili di terze parti per personalizzare i contenuti e la pubblicità, ottimizzare la tua esperienza e migliorarci continuamente. Cliccando su ‘Approva,’ accetti. Per ulteriori informazioni, consulta la nostra informativa sulla privacy.',
    nl:
      'We gebruiken cookies en vergelijkbare technologieën van derden om inhoud en advertenties te personaliseren, uw ervaring te optimaliseren en onszelf voortdurend te verbeteren. Door op ‘Goedkeuren’ te klikken, gaat u akkoord. Voor meer informatie verwijzen wij u naar ons privacybeleid.',
  },
  ModalContent: {
    title: {
      en: 'Consent settings',
      de: 'Einstellungen zur Einwilligung',
      es: 'Configuración de consentimiento',
      fr: 'Paramètres de consentement',
      pt: 'Configurações de consentimento',
      it: 'Impostazioni di consenso',
      nl: 'Toestemmingsinstellingen',
    },
    description: {
      en:
        'We use cookies and similar third-party technologies to personalize content and advertising, optimize your experience, and continually improve ourselves. By clicking ‘Approve,’ you agree. For more information, please refer to our privacy policy.',
      de:
        'Wir verwenden Cookies und ähnliche Technologien von Drittanbietern, um Inhalte und Werbung zu personalisieren, Ihr Erlebnis zu optimieren und uns ständig zu verbessern. Durch Klicken auf "Zustimmen" erklären Sie sich damit einverstanden. Weitere Informationen finden Sie in unserer Datenschutzrichtlinie.',
      es:
        'Utilizamos cookies y tecnologías similares de terceros para personalizar contenido y publicidad, optimizar tu experiencia y mejorar continuamente. Al hacer clic en ‘Aprobar’, aceptas. Para obtener más información, consulta nuestra política de privacidad.',
      fr:
        'Nous utilisons des cookies et des technologies similaires de tiers pour personnaliser le contenu et la publicité, optimiser votre expérience et nous améliorer en permanence. En cliquant sur ‘Approuver’, vous acceptez. Pour plus d’informations, veuillez consulter notre politique de confidentialité.',
      pt:
        'Utilizamos cookies e tecnologias semelhantes de terceiros para personalizar conteúdo e publicidade, otimizar sua experiência e nos aprimorar constantemente. Ao clicar em ‘Aprovar’, você concorda. Para mais informações, consulte nossa política de privacidade.',
      it:
        'Utilizziamo cookie e tecnologie simili di terze parti per personalizzare i contenuti e la pubblicità, ottimizzare la tua esperienza e migliorarci continuamente. Cliccando su ‘Approva,’ accetti. Per ulteriori informazioni, consulta la nostra informativa sulla privacy.',
      nl:
        'We gebruiken cookies en vergelijkbare technologieën van derden om inhoud en advertenties te personaliseren, uw ervaring te optimaliseren en onszelf voortdurend te ver',
    },
    decline: {
      label: {
        en: 'Decline',
        de: 'Ablehnen',
        es: 'Rechazar',
        fr: 'Refuser',
        pt: 'Recusar',
        it: 'Rifiutare',
        nl: 'Weigeren',
      },
    },
    approve: {
      label: {
        en: 'Approve',
        de: 'Genehmigen',
        es: 'Aprobar',
        fr: 'Approuver',
        pt: 'Aprovar',
        it: 'Approvare',
        nl: 'Goedkeuren',
      },
    },
    approveAll: {
      label: {
        en: 'Approve all',
        de: 'Alles genehmigen',
        es: 'Aprobar todo',
        fr: 'Tout approuver',
        pt: 'Aprovar tudo',
        it: 'Approva tutto',
        nl: 'Alles goedkeuren',
      },
    },
  },
};

const consentOptions = {
  services: [
    {
      id: 'necessary',
      name: translations.necessaryCookies[defaultLocale],
      mandatory: true,
    },
    {
      id: 'preferences',
      name: translations.preferencesCookies[defaultLocale],
    },
    {
      id: 'statistics',
      name: translations.statisticsCookies[defaultLocale],
    },
    {
      id: 'marketing',
      name: translations.marketingCookies[defaultLocale],
    },
  ],
  theme: 'dark',
};

const localeMessages = {
  en: englishMessages,
  de: germanMessages,
  fr: frenchMessages,
  es: spanishMessages,
  pt: portugueseMessages,
  nl: dutchMessages,
  it: italianMessages,
};

const locale = localeMessages[defaultLocale] || germanMessages;

// If you want to change the language of default (fallback) translations,
// change the imports to match the wanted locale:
//
//   1) Change the language in the config.js file!
//   2) Import correct locale rules for Moment library
//   3) Use the `messagesInLocale` import to add the correct translation file.
//   4) (optionally) To support older browsers you need add the intl-relativetimeformat npm packages
//      and take it into use in `util/polyfills.js`

// Note that there is also translations in './translations/countryCodes.js' file
// This file contains ISO 3166-1 alpha-2 country codes, country names and their translations in our default languages
// This used to collect billing address in StripePaymentAddress on CheckoutPage

// Step 2:
// If you are using a non-english locale with moment library,
// you should also import time specific formatting rules for that locale
// There are 2 ways to do it:
// - you can add your preferred locale to MomentLocaleLoader or
// - stop using MomentLocaleLoader component and directly import the locale here.
// E.g. for French:
// import 'moment/locale/fr';
// const hardCodedLocale = process.env.NODE_ENV === 'test' ? 'en' : 'fr';

// Step 3:
// The "./translations/en.json" has generic English translations
// that should work as a default translation if some translation keys are missing
// from the hosted translation.json (which can be edited in Console). The other files
// (e.g. en.json) in that directory has You and a View themed translations.
//
// If you are using a non-english locale, point `messagesInLocale` to correct <lang>.json file.
// That way the priority order would be:
//   1. hosted translation.json
//   2. <lang>.json
//   3. en.json
//
// I.e. remove "const messagesInLocale" and add import for the correct locale:
// import messagesInLocale from './translations/fr.json';
const messagesInLocale = {};

// If translation key is missing from `messagesInLocale` (e.g. fr.json),
// corresponding key will be added to messages from `defaultMessages` (en.json)
// to prevent missing translation key errors.
const addMissingTranslations = (sourceLangTranslations, targetLangTranslations) => {
  const sourceKeys = Object.keys(sourceLangTranslations);
  const targetKeys = Object.keys(targetLangTranslations);

  // if there's no translations defined for target language, return source translations
  if (targetKeys.length === 0) {
    return sourceLangTranslations;
  }
  const missingKeys = difference(sourceKeys, targetKeys);

  const addMissingTranslation = (translations, missingKey) => ({
    ...translations,
    [missingKey]: sourceLangTranslations[missingKey],
  });

  return missingKeys.reduce(addMissingTranslation, targetLangTranslations);
};

const renderConsentBanner = (
  <ConsentBanner
    settings={{
      hidden: false,
      label: translations.Settings[defaultLocale],
      modal: {
        title: translations.ModalContent.title[defaultLocale],
        description: translations.ModalContent.description[defaultLocale],
        decline: {
          label: translations.ModalContent.decline.label[defaultLocale],
        },
        approve: {
          label: translations.ModalContent.approve.label[defaultLocale],
        },
        approveAll: {
          label: translations.ModalContent.approveAll.label[defaultLocale],
        },
      },
    }}
    decline={{ show: true, label: translations.Decline[defaultLocale] }}
    approve={{ label: translations.Approve[defaultLocale] }}
  >
    <>{translations.CookieMessage[defaultLocale]}</>
  </ConsentBanner>
);

// Get default messages for a given locale.
//
// Note: Locale should not affect the tests. We ensure this by providing
//       messages with the key as the value of each message and discard the value.
//       { 'My.translationKey1': 'My.translationKey1', 'My.translationKey2': 'My.translationKey2' }
const isTestEnv = process.env.NODE_ENV === 'test';

// For customized apps, this dynamic loading of locale files is not necessary.
// It helps locale change from configDefault.js file or hosted configs, but customizers should probably
// just remove this and directly import the necessary locale on step 2.
const MomentLocaleLoader = props => {
  const { children, locale } = props;
  const isAlreadyImportedLocale =
    typeof hardCodedLocale !== 'undefined' && locale === hardCodedLocale;

  // Moment's built-in locale does not need loader
  const NoLoader = props => <>{props.children()}</>;

  // The default locale is en (en-US). Here we dynamically load one of the other common locales.
  // However, the default is to include all supported locales package from moment library.
  const MomentLocale =
    ['en', 'en-US'].includes(locale) || isAlreadyImportedLocale
      ? NoLoader
      : ['fr', 'fr-FR'].includes(locale)
      ? loadable.lib(() => import(/* webpackChunkName: "fr" */ 'moment/locale/fr'))
      : ['de', 'de-DE'].includes(locale)
      ? loadable.lib(() => import(/* webpackChunkName: "de" */ 'moment/locale/de'))
      : ['es', 'es-ES'].includes(locale)
      ? loadable.lib(() => import(/* webpackChunkName: "es" */ 'moment/locale/es'))
      : ['fi', 'fi-FI'].includes(locale)
      ? loadable.lib(() => import(/* webpackChunkName: "fi" */ 'moment/locale/fi'))
      : ['nl', 'nl-NL'].includes(locale)
      ? loadable.lib(() => import(/* webpackChunkName: "nl" */ 'moment/locale/nl'))
      : loadable.lib(() => import(/* webpackChunkName: "locales" */ 'moment/min/locales.min'));

  return (
    <MomentLocale>
      {() => {
        // Set the Moment locale globally
        // See: http://momentjs.com/docs/#/i18n/changing-locale/
        moment.locale(locale);
        return children;
      }}
    </MomentLocale>
  );
};

const Configurations = props => {
  const { appConfig, children } = props;
  const routeConfig = routeConfiguration(appConfig.layout);
  const locale = isTestEnv ? 'en' : defaultLocale || appConfig.localization.locale;

  return (
    <ConfigurationProvider value={appConfig}>
      <MomentLocaleLoader locale={locale}>
        <RouteConfigurationProvider value={routeConfig}>{children}</RouteConfigurationProvider>
      </MomentLocaleLoader>
    </ConfigurationProvider>
  );
};

const MaintenanceModeError = props => {
  const { locale, messages, helmetContext } = props;
  return (
    <IntlProvider locale={defaultLocale || locale} messages={messages} textComponent="span">
      <HelmetProvider context={helmetContext}>
        <MaintenanceMode />
      </HelmetProvider>
    </IntlProvider>
  );
};

// This displays a warning if environment variable key contains a string "SECRET"
const EnvironmentVariableWarning = props => {
  const suspiciousEnvKey = props.suspiciousEnvKey;
  // https://github.com/sharetribe/flex-integration-api-examples#warning-usage-with-your-web-app--website
  const containsINTEG = str => str.toUpperCase().includes('INTEG');
  return (
    <div
      style={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: '100vh',
      }}
    >
      <div style={{ width: '600px' }}>
        <p>
          Are you sure you want to reveal to the public web an environment variable called:{' '}
          <b>{suspiciousEnvKey}</b>
        </p>
        <p>
          All the environment variables that start with <i>REACT_APP_</i> prefix will be part of the
          published React app that's running on a browser. Those variables are, therefore, visible
          to anyone on the web. Secrets should only be used on a secure environment like the server.
        </p>
        {containsINTEG(suspiciousEnvKey) ? (
          <p>
            {'Note: '}
            <span style={{ color: 'red' }}>
              Do not use Integration API directly from the web app.
            </span>
          </p>
        ) : null}
      </div>
    </div>
  );
};

export const ClientApp = props => {
  const { store, hostedTranslations = {}, hostedConfig = {} } = props;
  const appConfig = mergeConfig(hostedConfig, defaultConfig);

  // Show warning on the localhost:3000, if the environment variable key contains "SECRET"
  if (appSettings.dev) {
    const envVars = process.env || {};
    const envVarKeys = Object.keys(envVars);
    const containsSECRET = str => str.toUpperCase().includes('SECRET');
    const suspiciousSECRETKey = envVarKeys.find(
      key => key.startsWith('REACT_APP_') && containsSECRET(key)
    );

    if (suspiciousSECRETKey) {
      return <EnvironmentVariableWarning suspiciousEnvKey={suspiciousSECRETKey} />;
    }
  }

  // Show MaintenanceMode if the mandatory configurations are not available
  if (!appConfig.hasMandatoryConfigurations) {
    return (
      <MaintenanceModeError
        locale={defaultLocale || appConfig.localization.locale}
        messages={{ ...locale, ...hostedTranslations }}
      />
    );
  }

  // Marketplace color and branding image comes from configs
  // If set, we need to create CSS Property and set it to DOM (documentElement is selected here)
  // This provides marketplace color for everything under <html> tag (including modals/portals)
  // Note: This is also set on Page component to provide server-side rendering.
  const elem = window.document.documentElement;
  if (appConfig.branding.marketplaceColor) {
    elem.style.setProperty('--marketplaceColor', appConfig.branding.marketplaceColor);
    elem.style.setProperty('--marketplaceColorDark', appConfig.branding.marketplaceColorDark);
    elem.style.setProperty('--marketplaceColorLight', appConfig.branding.marketplaceColorLight);
  }
  // This gives good input for debugging issues on live environments, but with test it's not needed.
  const logLoadDataCalls = appSettings?.env !== 'test';

  return (
    <Configurations appConfig={appConfig}>
      <IntlProvider
        locale={defaultLocale || appConfig.localization.locale}
        messages={{ ...locale }}
        textComponent="span"
      >
        <Provider store={store}>
          <HelmetProvider>
            <IncludeScripts config={appConfig} />
            <BrowserRouter>
              <StateHolder>
                <ContextFunctions>
                  <ConsentProvider options={consentOptions}>
                    <Routes logLoadDataCalls={logLoadDataCalls} />
                    {renderConsentBanner}
                  </ConsentProvider>
                </ContextFunctions>
              </StateHolder>
            </BrowserRouter>
          </HelmetProvider>
        </Provider>
      </IntlProvider>
    </Configurations>
  );
};

ClientApp.propTypes = { store: any.isRequired };

export const ServerApp = props => {
  const { url, context, helmetContext, store, hostedTranslations = {}, hostedConfig = {} } = props;
  const appConfig = mergeConfig(hostedConfig, defaultConfig);
  HelmetProvider.canUseDOM = false;

  // Show MaintenanceMode if the mandatory configurations are not available
  if (!appConfig.hasMandatoryConfigurations) {
    return (
      <MaintenanceModeError
        locale={defaultLocale || appConfig.localization.locale}
        messages={{ ...locale, ...hostedTranslations }}
        helmetContext={helmetContext}
      />
    );
  }

  return (
    <Configurations appConfig={appConfig}>
      <IntlProvider
        locale={defaultLocale || appConfig.localization.locale}
        messages={{ ...locale }}
        textComponent="span"
      >
        <Provider store={store}>
          <HelmetProvider context={helmetContext}>
            <IncludeScripts config={appConfig} />
            <StaticRouter location={url} context={context}>
              <ConsentProvider options={consentOptions}>
                <StateHolder>
                  <ContextFunctions>
                    <Routes />
                    {renderConsentBanner}
                  </ContextFunctions>
                </StateHolder>
              </ConsentProvider>
            </StaticRouter>
          </HelmetProvider>
        </Provider>
      </IntlProvider>
    </Configurations>
  );
};

ServerApp.propTypes = { url: string.isRequired, context: any.isRequired, store: any.isRequired };

/**
 * Render the given route.
 *
 * @param {String} url Path to render
 * @param {Object} serverContext Server rendering context from react-router
 *
 * @returns {Object} Object with keys:
 *  - {String} body: Rendered application body of the given route
 *  - {Object} head: Application head metadata from react-helmet
 */
export const renderApp = (
  url,
  serverContext,
  preloadedState,
  hostedTranslations,
  hostedConfig,
  collectChunks
) => {
  // Don't pass an SDK instance since we're only rendering the
  // component tree with the preloaded store state and components
  // shouldn't do any SDK calls in the (server) rendering lifecycle.
  const store = configureStore(preloadedState);

  const helmetContext = {};

  // When rendering the app on server, we wrap the app with webExtractor.collectChunks
  // This is needed to figure out correct chunks/scripts to be included to server-rendered page.
  // https://loadable-components.com/docs/server-side-rendering/#3-setup-chunkextractor-server-side
  const WithChunks = collectChunks(
    <ServerApp
      url={url}
      context={serverContext}
      helmetContext={helmetContext}
      store={store}
      hostedTranslations={hostedTranslations}
      hostedConfig={hostedConfig}
    />
  );
  const body = ReactDOMServer.renderToString(WithChunks);
  const { helmet: head } = helmetContext;
  return { head, body };
};
