import React from 'react';
import { node, string } from 'prop-types';
import classNames from 'classnames';
import { IconSearch, Icons, MobileBottomBar, NamedLink } from '../../../components';
import LayoutComposer from '../LayoutComposer';

import css from './LayoutSingleColumn.module.css';

// Commonly used layout
const LayoutSingleColumn = props => {
  const {
    className,
    rootClassName,
    mainColumnClassName,
    isListingPage,
    children,
    showMobileNavBar = false,
    topbar: topbarContent,
    footer: footerContent,
    ...rest
  } = props;

  const classes = classNames(rootClassName || css.root, className, {
    [css.listingPageFooter]: isListingPage,
  });
  const layoutAreas = `
    topbar
    main
    footer
  `;

  return (
    <LayoutComposer areas={layoutAreas} className={classes} {...rest}>
      {layoutProps => {
        const { Topbar, Main, Footer } = layoutProps;
        return (
          <>
            <Topbar as="header" className={css.topbar}>
              {topbarContent}
            </Topbar>
            <Main as="main" className={classNames(css.main, mainColumnClassName)}>
              {children}
              {showMobileNavBar ? <MobileBottomBar /> : null}
            </Main>
            <Footer className={css.footer}>{footerContent}</Footer>
          </>
        );
      }}
    </LayoutComposer>
  );
};

LayoutSingleColumn.displayName = 'LayoutSingleColumn';

LayoutSingleColumn.defaultProps = {
  className: null,
  rootClassName: null,
  footer: null,
};

LayoutSingleColumn.propTypes = {
  className: string,
  rootClassName: string,
  children: node.isRequired,
  topbar: node.isRequired,
  footer: node,
};

export default LayoutSingleColumn;
