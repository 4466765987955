import { default as unified } from 'unified';
import remarkParse from 'remark-parse';
import remark2rehype from 'remark-rehype';
import rehypeSanitize from 'rehype-sanitize';
import rehypeReact from 'rehype-react';
import React, { createElement } from 'react';

export const convertMarkdownToReact = (markdownContent, shouldSplit = false) => {
  const splittedMarkdown = markdownContent
    .split(' ')
    .slice(0, 25)
    .join(' ');
  const truncatedContent = shouldSplit ? splittedMarkdown + '...' : markdownContent;
  const result = unified()
    .use(remarkParse)
    .use(remark2rehype)
    .use(rehypeSanitize)
    .use(rehypeReact, { createElement })
    .processSync(truncatedContent);
  return result.result;
};
