import React, { useState } from 'react';
import { locations } from './CategoryPage';
import { AutoSuggestion, Button } from '../../components';
import { createResourceLocatorString } from '../../util/routes';
import routeConfiguration from '../../routing/routeConfiguration';
import { getRedirectParams } from './categoryHelpers';

import css from './CategoryPage.module.css';

function SectionHero({ history, intl, translations, renderBreadcrumbs }) {
  const [selectedLocation, setSelectedLocation] = useState(null);

  const { country, state, city, page } = getRedirectParams(selectedLocation, locations);

  return (
    <div className={css.categoryDetailPage}>
      {renderBreadcrumbs}
      <h2>{translations?.title}</h2>
      <p>
        {intl.formatMessage(
          { id: 'CategoryPage.SectionHero.title' },
          { location: translations?.title }
        )}
      </p>
      <div className={css.categorySearch}>
        <AutoSuggestion
          data={locations}
          isMulti={false}
          searchKeys={['title']}
          placeholder={intl.formatMessage({ id: 'CategoryPage.SectionHero.searchDestination' })}
          onSelectionChange={selectedOptions => setSelectedLocation(selectedOptions)}
        />
        <Button
          className={css.searchBtn}
          type="button"
          onClick={() => {
            history.push(
              createResourceLocatorString(page, routeConfiguration(), { country, state, city }, {})
            );
          }}
        >
          {intl.formatMessage({ id: 'CategoryPage.SectionHero.searchKey' })}
        </Button>
      </div>
    </div>
  );
}

export default SectionHero;
