import React, { useState } from 'react';
import { arrayOf, bool, func, object, string } from 'prop-types';
import { compose } from 'redux';
import classNames from 'classnames';
import { injectIntl, intlShape } from '../../../util/reactIntl';
import { propTypes } from '../../../util/types';
import { formatMoney } from '../../../util/currency';
import { ensureListing } from '../../../util/data';
import { findFlagUrlByCountryName } from 'country-flags-svg';
import { types as sdkTypes } from '../../../util/sdkLoader';
import {
  calculateTotalWithMinimum,
  extractZipAndCity,
  getProductImages,
  isArrayLength,
} from '../../../util/genericHelpers';
import { vehicleTypesOptions } from '../../../util/fieldsOptions';
import ImageSlider from '../../../components/ImageSlider/ImageSlider';
import { createSlug } from '../../../util/urlHelpers';
import { draftSlug } from '../../../routing/routeConfiguration';
import { useHistory } from 'react-router-dom/cjs/react-router-dom';

import css from './SearchMapInfoCard.module.css';
import { isInfoOnly } from '../../../util/dataExtractors';

const { Money } = sdkTypes;
const isWindowDefined = typeof window !== 'undefined';
const isMobileLayout = isWindowDefined && window.innerWidth < 768;

// ListingCard is the listing info without overlayview or carousel controls
const ListingCard = props => {
  const { className, clickHandler, intl, isInCarousel, listing, urlToListing, config } = props;
  const history = useHistory();
  const { title, price, publicData, geolocation } = listing.attributes || {};

  const totalPriceOrderWithCommission =
    calculateTotalWithMinimum(price?.amount / 100, Number(1)) * 1;
  const priceWithCommission = totalPriceOrderWithCommission
    ? new Money(totalPriceOrderWithCommission * 100, 'EUR')
    : price;

  const formattedPrice =
    price && price.currency === config.currency
      ? formatMoney(intl, priceWithCommission)
      : price?.currency
      ? price.currency
      : null;

  const firstImage = listing.images && listing.images.length > 0 ? listing.images[0] : null;

  const {
    aspectWidth = 1,
    aspectHeight = 1,
    variantPrefix = 'listing-card',
  } = config.layout.listingImage;
  const variants = firstImage
    ? Object.keys(firstImage?.attributes?.variants).filter(k => k.startsWith(variantPrefix))
    : [];

  // listing card anchor needs sometimes inherited border radius.
  const classes = classNames(
    css.anchor,
    css.borderRadiusInheritTop,
    { [css.borderRadiusInheritBottom]: !isInCarousel },
    className
  );

  const address = publicData?.location?.address || '';
  const country = publicData?.country || (address && address?.split(' ')?.pop());
  const addressDetails = extractZipAndCity(address);
  const locationDetails = publicData?.locationDetails;

  const selectedTravelTypes = isArrayLength(publicData?.vehicleTypes)
    ? vehicleTypesOptions.filter(v => publicData?.vehicleTypes.includes(v.key))
    : null;
  const images = getProductImages(listing?.images);
  const id = listing?.id?.uuid;
  const slug = createSlug(title || draftSlug);
  const isInfoListing = isInfoOnly(listing);

  return (
    <a alt={title} className={classes}>
      <div
        className={classNames(css.card, css.borderRadiusInheritTop, {
          [css.borderRadiusInheritBottom]: !isInCarousel,
        })}
      >
        {isArrayLength(images) ? (
          <ImageSlider
            images={images}
            isMobile={isMobileLayout}
            showArrows
            imgAlt={''}
            pathParams={{ id, slug }}
            pushToListingPage={() => clickHandler(listing)}
          />
        ) : null}
        {!isInfoListing && (
          <div className={css.imgPills}>
            <span>{intl.formatMessage({ id: 'LandingListingCard.adsBanner' })}</span>
          </div>
        )}
        <div
          onClick={() => clickHandler(listing)}
          className={classNames(css.info, { [css.borderRadiusInheritBottom]: !isInCarousel })}
        >
          <div className={css.mainInfo}>
            <div className={css.title}>{title}</div>
            <div className={css.flag}>
              <img width={30} src={findFlagUrlByCountryName(country)} alt={country} />
            </div>
          </div>
          <div className={css.priceSec}>
            {locationDetails?.city ? (
              <p>
                {locationDetails?.city}, {locationDetails?.zip}, {locationDetails?.country}{' '}
              </p>
            ) : (
              locationDetails?.place
            )}
            <span className={css.price}>{formattedPrice}</span>
          </div>
          <div className={css.amenities}>
            {isInfoListing ? (
              <div className={css.pills}>
                <span>{intl.formatMessage({ id: 'LandingListingCard.infoOnly' })}</span>
              </div>
            ) : (
              <div className={css.amenities}>
                {isArrayLength(selectedTravelTypes)
                  ? selectedTravelTypes.map((tt, i) => <img key={i} src={tt?.icon} />)
                  : null}
              </div>
            )}
          </div>{' '}
        </div>
      </div>
    </a>
  );
};

ListingCard.defaultProps = {
  className: null,
};

ListingCard.propTypes = {
  className: string,
  listing: propTypes.listing.isRequired,
  clickHandler: func.isRequired,
  intl: intlShape.isRequired,
  isInCarousel: bool.isRequired,
};

const SearchMapInfoCard = props => {
  const [currentListingIndex, setCurrentListingIndex] = useState(0);
  const {
    className,
    rootClassName,
    intl,
    listings,
    createURLToListing,
    onListingInfoCardClicked,
    config,
  } = props;

  const currentListing = ensureListing(listings[currentListingIndex]);
  const hasCarousel = listings.length > 1;

  const classes = classNames(rootClassName || css.root, className);
  const caretClass = classNames(css.caret, { [css.caretWithCarousel]: hasCarousel });

  return (
    <div className={classes}>
      <div className={css.caretShadow} />
      <ListingCard
        clickHandler={onListingInfoCardClicked}
        urlToListing={createURLToListing(currentListing)}
        listing={currentListing}
        intl={intl}
        isInCarousel={hasCarousel}
        config={config}
      />
      {hasCarousel ? (
        <div className={classNames(css.paginationInfo, css.borderRadiusInheritBottom)}>
          <button
            className={css.paginationPrev}
            onClick={e => {
              e.preventDefault();
              e.stopPropagation();
              setCurrentListingIndex(
                prevListingIndex => (prevListingIndex + listings.length - 1) % listings.length
              );
            }}
          />
          <div className={css.paginationPage}>
            {`${currentListingIndex + 1}/${listings.length}`}
          </div>
          <button
            className={css.paginationNext}
            onClick={e => {
              e.preventDefault();
              e.stopPropagation();
              setCurrentListingIndex(
                prevListingIndex => (prevListingIndex + listings.length + 1) % listings.length
              );
            }}
          />
        </div>
      ) : null}
      <div className={caretClass} />
    </div>
  );
};

SearchMapInfoCard.defaultProps = {
  className: null,
  rootClassName: null,
};

SearchMapInfoCard.propTypes = {
  className: string,
  rootClassName: string,
  listings: arrayOf(propTypes.listing).isRequired,
  onListingInfoCardClicked: func.isRequired,
  createURLToListing: func.isRequired,
  config: object.isRequired,

  // from injectIntl
  intl: intlShape.isRequired,
};

export default compose(injectIntl)(SearchMapInfoCard);
