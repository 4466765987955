import React from 'react';
import { FormattedMessage, injectIntl } from '../../util/reactIntl';
import { createResourceLocatorString } from '../../util/routes';
import { isMainSearchTypeKeywords } from '../../util/search';

import { Heading, Page, LayoutSingleColumn, IconSpinner } from '../../components';

import TopbarContainer from '../../containers/TopbarContainer/TopbarContainer';
import FooterContainer from '../../containers/FooterContainer/FooterContainer';

import SearchForm from './SearchForm/SearchForm';
import img from '../../assets/waldrand_s.png';
import { withRouter } from 'react-router-dom/cjs/react-router-dom.min';
import { withViewport } from '../../util/uiHelpers';

import css from './NotFoundPage.module.css';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { isScrollingDisabled } from '../../ducks/ui.duck';
import routeConfiguration, { transformedPathnames } from '../../routing/routeConfiguration';
import { useConfiguration } from '../../context/configurationContext';
import { LoadingPage } from '../ListingPage/ListingPage.shared';

const NotFoundPageComponent = ({ scrollingDisabled, history, location, intl }) => {
  const config = useConfiguration();
  const title = intl.formatMessage({
    id: 'NotFoundPage.title',
  });

  const pathname = location.pathname && location.pathname.split('/')[1];

  const foundLocale = transformedPathnames.find(item => item.key === pathname)?.lang;

  if (foundLocale) {
    if (typeof window !== 'undefined') {
      window.localStorage.setItem('selectedLanguage', foundLocale);
      window.document.cookie = `selectedLanguage=${foundLocale};path=/;max-age=31536000`; // 1 year expiry
      // Reload the page
      window.location.reload();
    }
    return (
      <LoadingPage
        topbar={<TopbarContainer hideSearchBar />}
        scrollingDisabled={scrollingDisabled}
        intl={intl}
      />
    );
  }

  const handleSearchSubmit = values => {
    const { keywords, location } = values;
    const { search, selectedPlace } = location || {};
    const { origin, bounds } = selectedPlace || {};
    const searchParams = keywords ? { keywords } : { address: search, origin, bounds };
    history.push(createResourceLocatorString('SearchPage', routeConfiguration(), {}, searchParams));
  };

  return (
    <Page title={title} scrollingDisabled={scrollingDisabled}>
      <LayoutSingleColumn
        showMobileNavBar
        topbar={<TopbarContainer hideSearchBar />}
        footer={<FooterContainer showMobileNavBar />}
      >
        <div className={css.root}>
          <div className={css.content}>
            <img src={img} alt="img" />
            <div className={css.notFoundContent}>
              <div className={css.number}>404</div>
              <Heading as="h1" rootClassName={css.heading}>
                <FormattedMessage id="NotFoundPage.heading" />
              </Heading>
              <p className={css.description}>
                <FormattedMessage
                  id="NotFoundPage.description"
                  values={{ marketplaceName: config.marketplaceName }}
                />
              </p>
              <SearchForm
                className={css.searchForm}
                isKeywordSearch={isMainSearchTypeKeywords(config)}
                onSubmit={handleSearchSubmit}
              />
            </div>
          </div>
        </div>
      </LayoutSingleColumn>
    </Page>
  );
};

const mapStateToProps = state => {
  return {
    scrollingDisabled: isScrollingDisabled(state),
  };
};

const NotFoundPage = compose(
  withRouter,
  connect(mapStateToProps, null),
  withViewport,
  injectIntl
)(NotFoundPageComponent);

export default NotFoundPage;
