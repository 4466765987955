import { fetchHomepageSections, fetchSeoCategory, fetchSeoPages } from '../util/api';
import { getDefaultLocale, isArrayLength } from '../util/genericHelpers';

export const FETCH_CATEGORIESS_SUCCESS = 'app/strapi/FETCH_CATEGORIESS_SUCCESS';
export const SAVE_HOMEPAGE_SECTIONS = 'app/strapi/SAVE_HOMEPAGE_SECTIONS';
export const FETCH_SINGLE_CATEGORIESS_SUCCESS = 'app/strapi/FETCH_SINGLE_CATEGORIESS_SUCCESS';

const defaultLocale = getDefaultLocale();

// ================ Reducer ================ //

const initialState = {
  seoCategories: [],
  homepageSections: [],
  seoCategory: null,
};

export default function reducer(state = initialState, action = {}) {
  const { type, payload } = action;
  switch (type) {
    case FETCH_CATEGORIESS_SUCCESS:
      return {
        ...state,
        seoCategories: payload,
      };
    case SAVE_HOMEPAGE_SECTIONS:
      return {
        ...state,
        homepageSections: payload,
      };
    case FETCH_SINGLE_CATEGORIESS_SUCCESS:
      return {
        ...state,
        seoCategory: payload,
      };
    default:
      return state;
  }
}

// ================ Selectors ================ //

export const fetchSeoPagesSelector = state => {
  return state.strapi.seoCategories;
};

// ================ Action creators ================ //

export const fetchSingleSeoCategorySuccess = payload => ({
  type: FETCH_SINGLE_CATEGORIESS_SUCCESS,
  payload,
});

export const fetchSeoCategoriesSuccess = payload => ({
  type: FETCH_CATEGORIESS_SUCCESS,
  payload,
});

export const saveHomepageSections = payload => ({
  type: SAVE_HOMEPAGE_SECTIONS,
  payload,
});

// ================ Thunks ================ //

export const fetchSingleSeoCategory = params => async (dispatch, getState, sdk) => {
  try {
    const { category } = (await fetchSeoCategory({ defaultLocale, ...params })) || {};
    if (isArrayLength(category) && category[0]) {
      dispatch(fetchSingleSeoCategorySuccess(category[0]));
    }
  } catch (error) {
    console.log(error, 'Error while fetching seo category');
  }
};

export const fetchSeoCategories = () => async (dispatch, getState, sdk) => {
  try {
    const { categories = {} } = (await fetchSeoPages({ defaultLocale })) || {};
    const filteredCategories = isArrayLength(categories)
      ? categories.filter((v, i, a) => a?.findIndex(v2 => v2?.id === v?.id) === i)
      : [];

    dispatch(fetchSeoCategoriesSuccess(filteredCategories));
  } catch (error) {
    console.log(error, 'Error while fetching seo categories');
  }
};

export const fetchHomePageSections = () => async (dispatch, getState, sdk) => {
  try {
    const response = (await fetchHomepageSections({ defaultLocale })) || {};

    const { section1 = {}, section2 = {}, section3 = {}, section4 = {} } =
      response?.sections?.attributes || {};

    const sections = [section1, section2, section3, section4];

    dispatch(saveHomepageSections(sections));
  } catch (error) {
    console.log(error, 'Error while fetching seo categories');
  }
};
