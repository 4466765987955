import React from 'react';
import Slider from 'react-slick';
import '../../styles/slick/slick-theme.css';
import '../../styles/slick/slick.css';
import css from './CategoryPage.module.css';
import { IconNextArrow, IconPrevArrow, LandingListingCard } from '../../components';
import { isArrayLength } from '../../util/genericHelpers';

function RenderNextArrow(props) {
  const { className, onClick } = props;
  return (
    <div className={className} onClick={onClick}>
      <IconNextArrow className={css.icon} />
    </div>
  );
}

function RenderPrevArrow(props) {
  const { className, onClick } = props;
  return (
    <div className={className} onClick={onClick}>
      <IconPrevArrow className={css.icon} />
    </div>
  );
}

function PetFriendly(props) {
  const { listings, currentLocation, viewport } = props;
  const isMobileLayout = viewport.width && viewport.width > 0 && viewport.width < 1024;
  const sliderSettings = {
    dots: isMobileLayout ? true : false,
    arrows: isMobileLayout ? false : true,
    nextArrow: <RenderNextArrow />,
    prevArrow: <RenderPrevArrow />,
    infinite: true,
    autoplay: false,
    autoplaySpeed: 2000,
    speed: 500,
    slidesToShow: 4, // Display 4 items on desktop
    slidesToScroll: 1, // Scroll 1 item at a time
    variableWidth: false,
    focusOnSelect: false,
    centerMode: false,
    responsive: [
      {
        breakpoint: 1500, // Adjust settings for smaller viewports
        settings: {
          slidesToShow: 4,
        },
      },
      {
        breakpoint: 1300, // Adjust settings for smaller viewports
        settings: {
          slidesToShow: 3,
        },
      },
      {
        breakpoint: 992, // Adjust settings for smaller viewports
        settings: {
          slidesToShow: 3,
        },
      },
      {
        breakpoint: 767, // Adjust settings for smaller viewports
        settings: {
          slidesToShow: 2,
        },
      },
      {
        breakpoint: 480, // Adjust settings for smaller viewports
        settings: {
          slidesToShow: 1,
        },
      },
    ],
  };
  return (
    <div className={css.popularCategorySec}>
      <h2>Pet friendly</h2>
      <Slider {...sliderSettings}>
        {isArrayLength(listings)
          ? listings.map(l => (
              <LandingListingCard
                className={css.listingCard}
                key={l?.id?.uuid}
                listing={l}
                setActiveListing={() => {}}
              />
            ))
          : null}
      </Slider>
    </div>
  );
}

export default PetFriendly;
