import React, { useContext, useEffect } from 'react';
import { useMyContext } from './StateHolder';
import { useLocation } from 'react-router-dom/cjs/react-router-dom.min.js';
import { TopbarFilterTypes } from '../util/enums';

// First create the context to hold shared functions
const MyContextFunctions = React.createContext();

// Then create a ContextFunctions wrapper component
// to hold the shared functions that the components need.

const ContextFunctions = props => {
  // Bring stateholders from another context
  const {
    setLocationPredictions,
    setGlobalFilterModal,
    globalFilterParams,
    setGlobalFilterParams,
    setCloseGlobalModalForcefully,
    setFilterModalContent,
    setShowTopbarFilter,
    openGlobalFilterModal,
  } = useMyContext();

  const onSavePredictionsGlobally = predictions => {
    setLocationPredictions(predictions);
  };

  const onSetGlobalFilterModal = (boolean, type = TopbarFilterTypes.LOCATION) => {
    setGlobalFilterModal(boolean);
    setFilterModalContent(type);
    // setShowTopbarFilter(boolean);
  };

  const onSetGlobalFilterParams = params => {
    setGlobalFilterParams({ ...globalFilterParams, ...params });
  };

  const onResetGlobalFilter = searchTitle => {
    setGlobalFilterParams(null);
    if (typeof window !== 'undefined') {
      window.location.href = searchTitle;
    }
  };

  const onCloseGlobalModalForcefully = () => {
    setCloseGlobalModalForcefully(true);
  };

  const onToggleTopbarFilter = () => {
    setShowTopbarFilter(true);
  };

  return (
    <MyContextFunctions.Provider
      value={{
        onSavePredictionsGlobally,
        onSetGlobalFilterModal,
        onSetGlobalFilterParams,
        onCloseGlobalModalForcefully,
        onResetGlobalFilter,
        onToggleTopbarFilter,
      }}
    >
      {props.children}
    </MyContextFunctions.Provider>
  );
};

export const useMyContextFunctions = () => useContext(MyContextFunctions);

export default ContextFunctions;
