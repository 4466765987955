import React from 'react';
import { useMyContextFunctions } from '../../context/ContextFunctions';

function MenuChildren(props) {
  const { className, onClick, onBlur, children } = props;
  const { onCloseGlobalModalForcefully } = useMyContextFunctions();

  return (
    <button
      className={className}
      onClick={e => {
        onClick(e);
        onCloseGlobalModalForcefully(false);
      }}
      onBlur={onBlur}
    >
      {children}
    </button>
  );
}

export default MenuChildren;
