import React from 'react';
import Slider from 'react-slick';
import '../../styles/slick/slick-theme.css';
import '../../styles/slick/slick.css';
import { IconNextArrow, IconPrevArrow, LandingListingCard } from '../../components';
import { isArrayLength } from '../../util/genericHelpers';
import { searchTitle } from '../../routing/routeConfiguration';
import { popularSeoCountries } from '../../util/fieldsOptions';

import css from './CategoryPage.module.css';

// Arrow Components
const RenderNextArrow = ({ className, onClick }) => (
  <div className={className} onClick={onClick}>
    <IconNextArrow className={css.icon} />
  </div>
);

const RenderPrevArrow = ({ className, onClick }) => (
  <div className={className} onClick={onClick}>
    <IconPrevArrow className={css.icon} />
  </div>
);

// Slider Settings
const getSliderSettings = isMobileLayout => ({
  dots: isMobileLayout,
  arrows: !isMobileLayout,
  nextArrow: <RenderNextArrow />,
  prevArrow: <RenderPrevArrow />,
  infinite: true,
  autoplay: false,
  autoplaySpeed: 2000,
  speed: 500,
  slidesToShow: 4,
  slidesToScroll: 1,
  variableWidth: false,
  focusOnSelect: false,
  centerMode: false,
  responsive: [
    {
      breakpoint: 1500,
      settings: {
        slidesToShow: 4,
      },
    },
    {
      breakpoint: 1300,
      settings: {
        slidesToShow: 3,
      },
    },
    {
      breakpoint: 992,
      settings: {
        slidesToShow: 3,
      },
    },
    {
      breakpoint: 767,
      settings: {
        slidesToShow: 2,
      },
    },
    {
      breakpoint: 480,
      settings: {
        slidesToShow: 1,
      },
    },
  ],
});

// Helper Function for Navigation
const navigateToBounds = async locationBounds => {
  if (locationBounds && typeof window !== 'undefined') {
    try {
      const bounds = `${locationBounds.ne.lat}%2C${locationBounds.ne.lng}%2C${locationBounds.sw.lat}%2C${locationBounds.sw.lng}`;
      window.location.href = `/${searchTitle}?bounds=${bounds}&pub_isInfoOnly=false`;
    } catch (error) {
      console.error('Error navigating to bounds:', error);
    }
  } else {
    console.error('Failed to get bounds');
  }
};

// PopularCategory Component
const PopularCategory = ({
  listings,
  currentLocation,
  renderMap,
  viewport,
  intl,
  locationBounds,
}) => {
  const isMobileLayout = viewport.width && viewport.width > 0 && viewport.width < 1024;
  const sliderSettings = getSliderSettings(isMobileLayout);

  return (
    <div className={css.popularCategorySec}>
      <h2>
        {intl.formatMessage(
          {
            id: 'PopularCategory.title',
          },
          {
            location: currentLocation?.title,
            listingsLength: listings?.length || 0,
          }
        )}
      </h2>
      <div className={css.popularListingSec}>
        <div className={css.popularListings}>
          {isArrayLength(listings) &&
            listings.map(listing => (
              <LandingListingCard
                className={css.listingCard}
                key={listing?.id?.uuid}
                listing={listing}
                setActiveListing={() => {}}
              />
            ))}
        </div>
        <div className={css.showMoreSec}>
          <button
            className={css.showBtn}
            type="button"
            onClick={() => navigateToBounds(locationBounds)}
          >
            {intl.formatMessage(
              {
                id: 'PopularCategory.showAll',
              },
              {
                location: currentLocation?.title,
                listingsLength: listings?.length || 0,
              }
            )}
          </button>
        </div>
      </div>

      {locationBounds && renderMap}

      <h2>{intl.formatMessage({ id: 'PopularCategory.exploreByCountry' })}</h2>
      <Slider className={css.campingListing} {...sliderSettings}>
        {popularSeoCountries.map((country, index) => (
          <div
            className={css.sliderItem}
            key={index}
            onClick={() => {
              if (typeof window !== 'undefined') {
                window.location.href = `/${searchTitle}${country.search}`;
              }
            }}
          >
            <img src={country.logo} alt={country.title} />
            <h2>{country.title}</h2>
          </div>
        ))}
      </Slider>
    </div>
  );
};

export default PopularCategory;
