import { fetchBlog } from '../../util/api';
import { searchListings } from '../SearchPage/SearchPage.duck';
import { createImageVariantConfig } from '../../util/sdkLoader';
import { getDefaultLocale, isArrayLength } from '../../util/genericHelpers';
// ================ Action types ================ //

export const SEARCH_BLOGS_REQUEST = 'app/SingleBlogPage/SEARCH_BLOGS_REQUEST';
export const SEARCH_BLOGS_SUCCESS = 'app/SingleBlogPage/SEARCH_BLOGS_SUCCESS';
export const SEARCH_BLOGS_ERROR = 'app/SingleBlogPage/SEARCH_BLOGS_ERROR';

// ================ Reducer ================ //

const initialState = {
  searchInProgress: false,
  blogData: [],
  searchBlogsError: null,
};

const blogsPageReducers = (state = initialState, action = {}) => {
  const { type, payload } = action;
  switch (type) {
    case SEARCH_BLOGS_REQUEST:
      return {
        ...state,
        searchInProgress: true,
      };
    case SEARCH_BLOGS_SUCCESS:
      return {
        ...state,
        searchInProgress: false,
        blogData: payload?.data || [],
      };
    case SEARCH_BLOGS_ERROR:
      return { ...state, searchInProgress: false, searchBlogsError: true };
    default:
      return state;
  }
};

export default blogsPageReducers;

// ================ Action creators ================ //

export const searchBlogsRequest = () => ({
  type: SEARCH_BLOGS_REQUEST,
});

export const searchBlogsSuccess = response => ({
  type: SEARCH_BLOGS_SUCCESS,
  payload: { data: response },
});

export const searchBlogsError = () => ({
  type: SEARCH_BLOGS_ERROR,
});

export const queryListingsByKeyword = keywords => async (dispatch, getState, sdk) => {
  const response =
    keywords &&
    (await sdk.listings.query({
      keywords,
    }));

  const responseLength = isArrayLength(response?.data?.data) && response?.data?.data.length;

  return responseLength && response?.data?.data[responseLength - 1]?.id?.uuid;
};

export const fetchSingleBlog = (params, config) => async (dispatch, getState, sdk) => {
  dispatch(searchBlogsRequest());
  const defaultLocale = getDefaultLocale();
  try {
    const response = await fetchBlog({ ...params, defaultLocale });
    await dispatch(
      searchListings(
        {
          page: 1,
          perPage: 20,
          pub_isInfoOnly: false,
          include: ['author', 'images'],
          'fields.listing': [
            'title',
            'geolocation',
            'price',
            'publicData',
            'publicData.listingType',
            'publicData.transactionProcessAlias',
            'publicData.unitType',
            // These help rendering of 'purchase' listings,
            // when transitioning from search page to listing page
            'publicData.pickupEnabled',
            'publicData.shippingEnabled',
          ],
          'fields.user': ['profile.displayName', 'profile.abbreviatedName'],
          'fields.image': [
            'variants.scaled-small',
            'variants.scaled-medium',
            `variants.listing-card`,
            `variants.listing-card-2x`,
          ],
          ...createImageVariantConfig(`listing-card`, 400, 1),
          ...createImageVariantConfig(`listing-card-2x`, 800, 1),
          'limit.images': 5,
        },
        config
      )
    ),
      dispatch(searchBlogsSuccess(response?.blog));
  } catch (error) {
    dispatch(searchBlogsError());
  }
};

export const loadData = (params, search, config) => {
  const { id = 1 } = params;
  return fetchSingleBlog({ id }, config);
};
