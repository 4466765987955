import React, { useState } from 'react';
import { useIntl } from '../../util/reactIntl';
import { Icons } from '../../components';
import classNames from 'classnames';
import css from './CategoryPage.module.css';
import { isArrayLength } from '../../util/genericHelpers';

function FAQSection(props) {
  const intl = useIntl();
  const { currentLocation } = props;

  const faqs = currentLocation?.FAQs || [];

  const [activeIndex, setActiveIndex] = useState(null);

  const handleAccordionClick = index => {
    setActiveIndex(index === activeIndex ? null : index);
  };

  return (
    <div className={css.faqSec}>
      <div className={css.contentWidth}>
        <h2>{intl.formatMessage({ id: 'BecomeAHostPage.FAQSection.title' })}</h2>
        <div className={css.faqCardSec}>
          {isArrayLength(faqs)
            ? faqs.map((p, i) => (
                <div
                  key={i}
                  className={classNames(css.faqCard, {
                    [css.activeFAQ]: activeIndex === i,
                  })}
                  onClick={() => handleAccordionClick(i)}
                >
                  <div className={css.faqTitle}>
                    <span className={css.faqHeading}>{p.question}</span>
                    <span className={css.faqIcon}>
                      {activeIndex === i ? <Icons name="minus" /> : <Icons name="plus" />}
                    </span>
                  </div>
                  {activeIndex === i && (
                    <div className={css.faqDescription} key={i}>
                      <div className={css.faqContent}>
                        <p>{p.answer}</p>
                      </div>
                    </div>
                  )}
                </div>
              ))
            : null}
        </div>
      </div>
    </div>
  );
}

export default FAQSection;
