import { createEvent } from '../util/api';

// ================ Action types ================ //

export const EVENT_CREATE_REQUEST = 'app/mixPanel/EVENT_CREATE_REQUEST';
export const EVENT_CREATE_SUCCESS = 'app/mixPanel/EVENT_CREATE_SUCCESS';
export const EVENT_CREATE_ERROR = 'app/mixPanel/EVENT_CREATE_ERROR';

// ================ Reducer ================ //

const initialState = {
  eventCreateInProgress: false,
  eventCreateError: null,
};

export default function reducer(state = initialState, action = {}) {
  const { type, payload } = action;
  switch (type) {
    case EVENT_CREATE_REQUEST:
      return {
        ...state,
        eventCreateInProgress: true,
        eventCreateError: null,
      };
    case EVENT_CREATE_SUCCESS:
      return { ...state, eventCreateInProgress: false, eventCreateError: null };
    case EVENT_CREATE_ERROR:
      return { ...state, eventCreateInProgress: false, eventCreateError: true };
    default:
      return state;
  }
}

// ================ Selectors ================ //

// ================ Action creators ================ //

export const createEventRequest = () => ({ type: EVENT_CREATE_REQUEST });
export const createEventSuccess = () => ({ type: EVENT_CREATE_SUCCESS });
export const createEventError = () => ({ type: EVENT_CREATE_ERROR });

// ================ Thunks ================ //

export const addCustomEventToMP = params => async (dispatch, getState, sdk) => {
  dispatch(createEventRequest());

  try {
    const event = await createEvent({ ...params });
    dispatch(createEventSuccess());
  } catch (error) {
    dispatch(createEventError());
  }
};
