import React, { useState } from 'react';
import { DateRangePicker } from 'react-dates';
import classNames from 'classnames';

import css from './DateRangerPicker.module.css';
import moment from 'moment';

const DateRangePickerWrapper = props => {
  const { isLandingDateRange, onDateRangeChange, selectedDates } = props;

  const isWindowDefined = typeof window !== 'undefined';
  const isMobileLayout = isWindowDefined && window.innerWidth < 768;

  const [dateRange, setDateRange] = useState(selectedDates || {});
  const [focusedInput, setFocusedInput] = useState('startDate');

  const handleDateChange = ({ startDate, endDate }) => {
    setDateRange({ startDate, endDate });
    onDateRangeChange({ startDate, endDate });
  };

  const today = new Date();
  const isOutsideRange = day => moment(day).isBefore(today);

  return (
    <div
      className={classNames({
        [css.landingDateRange]: isLandingDateRange,
      })}
    >
      <DateRangePicker
        startDate={dateRange ? dateRange.startDate : null}
        endDate={dateRange ? dateRange.endDate : null}
        onDatesChange={handleDateChange}
        focusedInput={focusedInput}
        onFocusChange={setFocusedInput}
        isOutsideRange={isOutsideRange}
        showClearDates
        readOnly
        numberOfMonths={isMobileLayout ? 1 : 2}
        keepOpenOnDateSelect
        startDateId="start_date"
        endDateId="end_date"
        hideKeyboardShortcutsPanel
      />
    </div>
  );
};

export default DateRangePickerWrapper;
