import { storableError } from '../../util/errors';
import { convertUnitToSubUnit, unitDivisor } from '../../util/currency';
import {
  parseDateFromISO8601,
  getExclusiveEndDate,
  addTime,
  subtractTime,
  daysBetween,
  getStartOf,
} from '../../util/dates';
import { createImageVariantConfig } from '../../util/sdkLoader';
import { isOriginInUse, isStockInUse } from '../../util/search';
import { parse } from '../../util/urlHelpers';
import { addMarketplaceEntities } from '../../ducks/marketplaceData.duck';

export const landingPageListings = [
  '63e028e4-e40e-44bf-a03c-641267dae1f0',
  '64be282c-9d15-4b05-a088-59b9ee6afc79',
  '6409708e-1635-4dce-b7f1-bed4ddaeb2d8',
  '63e01d2e-d304-4645-b664-347d052dd0e3',
  '64a6f35c-6a07-4ee5-af26-8375aac1a246',
  '64b0493c-311c-4610-8d60-d58a44ceebaa',
  '64b78ce1-d8ad-4ae5-8a71-d0ab8feedc86',
  '63dff126-24a2-406c-bac8-0ed8a45f0d4e',
  '63dfea2b-55ce-413a-a239-29f50f65a340',
  '64252b08-5fdc-4ad4-a826-e7968ff3097b',
  '64914341-8b2d-4078-8701-9eb3ace6e434',
  '6497cecd-2410-4c1e-9e4e-f186d1f01072',
  '64aa4a7c-a59d-4330-8176-21f635d99483',
  '63e0301e-af2e-4247-88c9-e33eec25c00e',
  '63e02cd2-33b7-418c-91d1-f0bc0c8d9809',
  '63dfec38-8d21-405b-a9b0-d0e5e471c7dc',
  '65279bf6-8d0e-402d-8aae-5aaa03e4cfcb',
  '642e7f45-d55f-4aa3-b69a-15f31f1ee54b',
  '646d0a07-acb5-4323-b1d3-15333683873e',
  '63dfdc8d-b690-44ec-9a03-04d7c5076691',

  '65006da3-821c-4bf3-9870-43bbfb122adf',
  '646b1b12-b693-41a9-a213-15897aedfae7',
  '64ca57bb-66ea-49ce-b7e8-853ab241191f',
  '642e80c1-d255-4736-a7fc-467b0df85a52',
  '659d61b3-8f28-4434-8140-3bb51097010d',
  '64ff419a-3e85-409a-950a-66e0a63bc98c',
  '64ad4c26-b6b5-461e-824b-286cf265e357',
  '642d8895-3061-48d0-898c-1883f9b8d5a9',
  '64c02d17-8dc0-4600-a0c4-a776037120cd',
  '643aa43c-dcd2-45e5-a581-df32a0224769',
  '64cd1bd5-7efe-48c7-ab01-f7dc2e8c47f3',
  '64f8acda-e949-4e23-87b3-eb482dd6dde1',
  '649c986c-d732-44f5-91a0-954b8ae45e33',
  '654b25f8-9ac3-435f-a50e-97bf72e77222',
  '64a6d57a-48c4-41f0-8c35-4cfede0efcea',
  '64d1cfd3-0c68-450c-904a-8eb1be983a78',
  '64a2b4b9-90a2-4314-a30f-434c13809968',
  '650c32dc-8811-4d57-9d41-df0f0d3492d7',
  '64085862-513b-41c7-a417-40dca258e7b4',
  '64bb1014-c2b2-46cc-8f1e-8c81930b8993',
  '64be3b82-84e5-4d06-92af-46b0e6c8c074',
  '646b6027-a3b4-4b06-97ac-a36679af8448',
  '64b14d66-ce5f-4506-87a7-740e2d2e20f4',
  '63dfe300-7942-4c4b-87d6-b9ada1b325e1',
  '6436b793-5f7c-4417-b123-a1eba3bc282c',
  '64ad9705-a3d1-4cb8-ad27-aa94d3a471f4',
  '64ccff66-0b22-4bfe-b7fd-21d0be412252',
  '652ed73d-4c1a-43c8-ac3e-3cd03b211a89',
  '64cd0159-ab53-4bca-af3e-fac70d334dd4',
  '63df7cda-0797-4fbe-81cd-a39048a9d1c2',
  '63dfd2ac-5c91-45a8-8713-5d1bf41c12b6',
  '64776103-8074-412d-91cd-334e75a7090b',
  '63dfd7d3-766a-4a35-bb1a-1b963a77ffdc',
  '63dfd43e-782c-48c5-85cc-27b52c7abcab',
  '63dff538-b360-4d22-9d90-b2d80be84ba9',
  '64ff7094-d7db-4a23-8045-eac183186437',
  '63dfceaf-d1e4-4b5b-9dfb-979b3ceabdc4',
  '63e011c4-3188-4300-81d8-1cf32500884a',
  '63e02e78-68fe-4ff0-80ec-09c3f88da5e7',
  '64caa048-2964-4072-ab62-038fceb33414',
];

// Pagination page size might need to be dynamic on responsive page layouts
// Current design has max 3 columns 12 is divisible by 2 and 3
// So, there's enough cards to fill all columns on full pagination pages
const RESULT_PAGE_SIZE = 100;

// ================ Action types ================ //

export const SEARCH_LISTINGS_REQUEST = 'app/LandingPage/SEARCH_LISTINGS_REQUEST';
export const SEARCH_LISTINGS_SUCCESS = 'app/LandingPage/SEARCH_LISTINGS_SUCCESS';
export const SEARCH_LISTINGS_ERROR = 'app/LandingPage/SEARCH_LISTINGS_ERROR';

export const SEARCH_MAP_LISTINGS_REQUEST = 'app/LandingPage/SEARCH_MAP_LISTINGS_REQUEST';
export const SEARCH_MAP_LISTINGS_SUCCESS = 'app/LandingPage/SEARCH_MAP_LISTINGS_SUCCESS';
export const SEARCH_MAP_LISTINGS_ERROR = 'app/LandingPage/SEARCH_MAP_LISTINGS_ERROR';

export const SEARCH_MAP_SET_ACTIVE_LISTING = 'app/LandingPage/SEARCH_MAP_SET_ACTIVE_LISTING';

// ================ Reducer ================ //

const initialState = {
  pagination: null,
  searchParams: null,
  searchInProgress: false,
  searchListingsError: null,
  currentPageResultIds: [],
};

const resultIds = data => data.data.map(l => l.id);

const listingPageReducer = (state = initialState, action = {}) => {
  const { type, payload } = action;
  switch (type) {
    case SEARCH_LISTINGS_REQUEST:
      return {
        ...state,
        searchParams: payload.searchParams,
        searchInProgress: true,
        searchMapListingIds: [],
        searchListingsError: null,
      };
    case SEARCH_LISTINGS_SUCCESS:
      return {
        ...state,
        currentPageResultIds: resultIds(payload.data),
        pagination: payload.data.meta,
        searchInProgress: false,
      };
    case SEARCH_LISTINGS_ERROR:
      // eslint-disable-next-line no-console
      console.error(payload);
      return { ...state, searchInProgress: false, searchListingsError: payload };

    case SEARCH_MAP_SET_ACTIVE_LISTING:
      return {
        ...state,
        activeListingId: payload,
      };
    default:
      return state;
  }
};

export default listingPageReducer;

// ================ Action creators ================ //

export const searchListingsRequest = searchParams => ({
  type: SEARCH_LISTINGS_REQUEST,
  payload: { searchParams },
});

export const searchListingsSuccess = response => ({
  type: SEARCH_LISTINGS_SUCCESS,
  payload: { data: response.data },
});

export const searchListingsError = e => ({
  type: SEARCH_LISTINGS_ERROR,
  error: true,
  payload: e,
});

export const searchListings = (searchParams, config) => async (dispatch, getState, sdk) => {
  dispatch(searchListingsRequest(searchParams));

  // SearchPage can enforce listing query to only those listings with valid listingType
  // NOTE: this only works if you have set 'enum' type search schema to listing's public data fields
  //       - listingType
  //       Same setup could be expanded to 2 other extended data fields:
  //       - transactionProcessAlias
  //       - unitType
  //       ...and then turned enforceValidListingType config to true in configListing.js
  // Read More:
  // https://www.sharetribe.com/docs/how-to/manage-search-schemas-with-flex-cli/#adding-listing-search-schemas
  const searchValidListingTypes = listingTypes => {
    return config.listing.enforceValidListingType
      ? {
          pub_listingType: listingTypes.map(l => l.listingType),
          // pub_transactionProcessAlias: listingTypes.map(l => l.transactionType.alias),
          // pub_unitType: listingTypes.map(l => l.transactionType.unitType),
        }
      : {};
  };

  const priceSearchParams = priceParam => {
    const inSubunits = value => convertUnitToSubUnit(value, unitDivisor(config.currency));
    const values = priceParam ? priceParam.split(',') : [];
    return priceParam && values.length === 2
      ? {
          price: [inSubunits(values[0]), inSubunits(values[1]) + 1].join(','),
        }
      : {};
  };

  const datesSearchParams = datesParam => {
    const searchTZ = 'Etc/UTC';
    const datesFilter = config.search.defaultFilters.find(f => f.key === 'dates');
    const values = datesParam ? datesParam.split(',') : [];
    const hasValues = datesFilter && datesParam && values.length === 2;
    const { dateRangeMode, availability } = datesFilter || {};
    const isNightlyMode = dateRangeMode === 'night';
    const isEntireRangeAvailable = availability === 'time-full';

    // SearchPage need to use a single time zone but listings can have different time zones
    // We need to expand/prolong the time window (start & end) to cover other time zones too.
    //
    // NOTE: you might want to consider changing UI so that
    //   1) location is always asked first before date range
    //   2) use some 3rd party service to convert location to time zone (IANA tz name)
    //   3) Make exact dates filtering against that specific time zone
    //   This setup would be better for dates filter,
    //   but it enforces a UX where location is always asked first and therefore configurability
    const getProlongedStart = date => subtractTime(date, 14, 'hours', searchTZ);
    const getProlongedEnd = date => addTime(date, 12, 'hours', searchTZ);

    const startDate = hasValues ? parseDateFromISO8601(values[0], searchTZ) : null;
    const endRaw = hasValues ? parseDateFromISO8601(values[1], searchTZ) : null;
    const endDate =
      hasValues && isNightlyMode
        ? endRaw
        : hasValues
        ? getExclusiveEndDate(endRaw, searchTZ)
        : null;

    const today = getStartOf(new Date(), 'day', searchTZ);
    const possibleStartDate = subtractTime(today, 14, 'hours', searchTZ);
    const hasValidDates =
      hasValues &&
      startDate.getTime() >= possibleStartDate.getTime() &&
      startDate.getTime() <= endDate.getTime();

    const dayCount = isEntireRangeAvailable ? daysBetween(startDate, endDate) : 1;
    const day = 1440;
    const hour = 60;
    // When entire range is required to be available, we count minutes of included date range,
    // but there's a need to subtract one hour due to possibility of daylight saving time.
    // If partial range is needed, then we just make sure that the shortest time unit supported
    // is available within the range.
    // You might want to customize this to match with your time units (e.g. day: 1440 - 60)
    const minDuration = isEntireRangeAvailable ? dayCount * day - hour : hour;
    return hasValidDates
      ? {
          start: getProlongedStart(startDate),
          end: getProlongedEnd(endDate),
          // Availability can be time-full or time-partial.
          // However, due to prolonged time window, we need to use time-partial.
          availability: 'time-partial',
          // minDuration uses minutes
          minDuration,
        }
      : {};
  };

  const { perPage, price, dates, sort, ...rest } = searchParams;
  const priceMaybe = priceSearchParams(price);
  const datesMaybe = datesSearchParams(dates);
  const sortMaybe = sort === config.search.sortConfig.relevanceKey ? {} : { sort };

  const params = {
    ...rest,
    ...priceMaybe,
    ...datesMaybe,
    ...sortMaybe,
    ...searchValidListingTypes(config.listing.listingTypes),
    perPage,
    pub_isInfoOnly: false,
  };

  if (process.env.REACT_APP_ENV === 'production') {
    Object.assign(params, { ids: landingPageListings });
  }

  return sdk.listings
    .query(params)
    .then(response => {
      const listingFields = config?.listing?.listingFields;
      const sanitizeConfig = { listingFields };

      dispatch(addMarketplaceEntities(response, sanitizeConfig));
      dispatch(searchListingsSuccess(response));
      return response;
    })
    .catch(e => {
      dispatch(searchListingsError(storableError(e)));
      throw e;
    });
};

export const setActiveListing = listingId => ({
  type: SEARCH_MAP_SET_ACTIVE_LISTING,
  payload: listingId,
});

export const sendNewsLetter = params => async (dispatch, getState, sdk) => {};

export const loadData = (params, search, config) => {
  const queryParams = parse(search, {
    latlng: ['origin'],
    latlngBounds: ['bounds'],
  });

  // Add minStock filter with default value (1), if stock management is in use.
  // This can be overwriten with passed-in query parameters.
  const minStockMaybe = isStockInUse(config) ? { minStock: 1 } : {};
  const { page = 1, address, origin, ...rest } = queryParams;
  const originMaybe = isOriginInUse(config) && origin ? { origin } : {};

  const {
    aspectWidth = 1,
    aspectHeight = 1,
    variantPrefix = 'listing-card',
  } = config.layout.listingImage;
  const aspectRatio = aspectHeight / aspectWidth;

  return searchListings(
    {
      ...minStockMaybe,
      ...rest,
      ...originMaybe,
      page,
      perPage: RESULT_PAGE_SIZE,
      include: ['author', 'images'],
      'fields.listing': [
        'title',
        'geolocation',
        'price',
        'publicData',
        'publicData.listingType',
        'publicData.transactionProcessAlias',
        'publicData.unitType',
        // These help rendering of 'purchase' listings,
        // when transitioning from search page to listing page
        'publicData.pickupEnabled',
        'publicData.shippingEnabled',
      ],
      'fields.user': ['profile.displayName', 'profile.abbreviatedName'],
      'fields.image': [
        'variants.scaled-small',
        'variants.scaled-medium',
        `variants.${variantPrefix}`,
        `variants.${variantPrefix}-2x`,
      ],
      ...createImageVariantConfig(`${variantPrefix}`, 400, aspectRatio),
      ...createImageVariantConfig(`${variantPrefix}-2x`, 800, aspectRatio),
      'limit.images': 5,
    },
    config
  );
};
