import React from 'react';
import { Menu, MenuLabel, MenuContent, MenuItem } from '../../components';
import { useDispatch, useSelector } from 'react-redux';
import { updateProfile } from '../../containers/ProfileSettingsPage/ProfileSettingsPage.duck';
import { useConfiguration } from '../../context/configurationContext';
import { selectIsAuthenticated } from '../../ducks/auth.duck';

import css from './LanguageSwitcher.module.css';
import { useMyContextFunctions } from '../../context/ContextFunctions';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import { getDefaultLocale } from '../../util/genericHelpers';

const defaultLocale = getDefaultLocale();

const getLocalOptions = config => {
  return [
    { key: 'en', label: 'LanguageSwither.en', flag: config.flags.enFlag },
    { key: 'de', label: 'LanguageSwither.de', flag: config.flags.deFlag },
    { key: 'fr', label: 'LanguageSwither.fr', flag: config.flags.frFlag },
    { key: 'es', label: 'LanguageSwither.es', flag: config.flags.esFlag },
    { key: 'it', label: 'LanguageSwither.it', flag: config.flags.itFlag },
    { key: 'nl', label: 'LanguageSwither.nl', flag: config.flags.nlFlag },
    { key: 'pt', label: 'LanguageSwither.pt', flag: config.flags.ptFlag },
  ];
};

const getFlagOptions = config => {
  return {
    en: config.flags.enFlag,
    de: config.flags.deFlag,
    fr: config.flags.frFlag,
    es: config.flags.esFlag,
    pt: config.flags.ptFlag,
    nl: config.flags.nlFlag,
    it: config.flags.itFlag,
  };
};

const LanguageSwitcher = props => {
  const config = useConfiguration();
  const dispatch = useDispatch();
  const history = useHistory();
  const { onSetGlobalFilterModal } = useMyContextFunctions();
  const isAuthenticated = useSelector(selectIsAuthenticated);
  const localeOptions = getLocalOptions(config);
  const flagLabels = getFlagOptions(config);

  const flagLabel = flagLabels[defaultLocale] || config.flags.deFlag;

  const handleLanguageSwitch = async lang => {
    if (isAuthenticated) {
      await dispatch(
        updateProfile({
          protectedData: {
            language: lang,
          },
        })
      );
    }
    if (typeof window !== 'undefined') {
      window.localStorage.setItem('selectedLanguage', lang);
      window.document.cookie = `selectedLanguage=${lang};path=/;max-age=31536000`; // 1 year expiry

      // Use history to push to the root path
      history.push('/');

      // Reload the page
      window.location.reload();
    }
  };

  return (
    <div className={css.langSwitchWrapper}>
      <Menu
        onToggleFromParent={() => {
          onSetGlobalFilterModal(false, null);
        }}
      >
        <MenuLabel className={css.profileMenuLabel} isOpenClassName={css.profileMenuIsOpen}>
          {<img src={flagLabel} className={css.icon} />}
        </MenuLabel>
        <MenuContent className={css.profileMenuContent}>
          {localeOptions.map((l, i) => (
            <MenuItem key={i}>
              <div className={css.labelWrapper} onClick={() => handleLanguageSwitch(l.key)}>
                <img src={l.flag} className={css.icon} alt={l?.label} />
              </div>
            </MenuItem>
          ))}
        </MenuContent>
      </Menu>
    </div>
  );
};

export default LanguageSwitcher;
