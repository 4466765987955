import React from 'react';
import PropTypes from 'prop-types';

import css from './IconArrowTop.module.css';

const IconArrowTop = props => {
  const { className } = props;
  return (
    <svg
      className={className}
      width="24"
      height="24"
      viewBox="0 0 20 20"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      fill="#000000"
    >
      <g id="SVGRepo_bgCarrier" strokeWidth="0"></g>
      <g id="SVGRepo_tracerCarrier" strokeLinecap="round" strokeLinejoin="round"></g>
      <g id="SVGRepo_iconCarrier">
        {' '}
        <g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
          {' '}
          <g
            id="Dribbble-Light-Preview"
            transform="translate(-220.000000, -6599.000000)"
            fill="#000000"
          >
            {' '}
            <g id="icons" transform="translate(56.000000, 160.000000)">
              {' '}
              <path
                d="M182.009365,6439 L170.877817,6439 C170.338646,6439 169.995536,6439.44285 169.995536,6439.98167 L169.995536,6440.00967 C169.995536,6440.55948 170.326642,6440.99934 170.876817,6440.99934 L179.428543,6440.99934 C179.874685,6440.99934 180.096756,6441.54316 179.781655,6441.85705 L164.289717,6457.31393 C163.903594,6457.69881 163.903594,6458.3256 164.288717,6458.71047 L164.288717,6458.71147 C164.67484,6459.09634 165.30004,6459.09634 165.684162,6458.71047 L181.150092,6443.2156 C181.465192,6442.90071 181.999362,6443.12363 181.999362,6443.56849 L181.999362,6452.02069 C181.999362,6452.56451 182.450506,6452.99536 182.99568,6452.99536 L183.023689,6452.99536 C183.568863,6452.99536 184,6452.56451 184,6452.02069 L184,6441.01033 C184,6439.9057 183.114718,6439 182.009365,6439"
                id="arrow_right_up-[#358]"
              >
                {' '}
              </path>{' '}
            </g>{' '}
          </g>{' '}
        </g>{' '}
      </g>
    </svg>
  );
};

IconArrowTop.defaultProps = { className: null };

const { string } = PropTypes;

IconArrowTop.propTypes = { className: string };

export default IconArrowTop;
